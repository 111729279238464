import { gql } from '@apollo/client';

const SAVE_OTC_CHECKLIST = gql`
  mutation SAVE_OTC_CHECKLIST($input: SaveOtcChecklistMutationInput!) {
    saveOtcChecklist(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_OTC_CHECKLIST;
