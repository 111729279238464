import { gql } from '@apollo/client';

const RESTAURANTS = gql`
  query Restaurants(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [RestaurantSort]
    $filter: [RestaurantFilter]
  ) {
    viewer {
      id
      restaurantConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            restaurantId
            label
            description
            primaryLocation {
              id
              storeNumber
              address {
                id
                state {
                  id
                  abbreviation
                }
              }
            }
            locationConnection {
              edges {
                node {
                  id
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RESTAURANTS;
