import { gql } from '@apollo/client';

const LOCATIONS = gql`
  query Locations(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $sort: [LocationSort]
    $filter: [LocationFilter]
    $fieldGroup: Int
    $fieldGroupOnly: Int
    $specificFields: [String]
  ) {
    viewer {
      id
      locationConnection(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
        search: $search
        sort: $sort
        specificFields: $specificFields
        fieldGroup: $fieldGroup
        fieldGroupOnly: $fieldGroupOnly
      ) {
        totalCount
        permissions
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            tierId
            locationId
            label
            storeTypeId
            active
            phone {
              phone
            }
            email
            opened
            storeNumber
            closed
            online
            printerPassword
            timezone {
              label
            }
            brand {
              id
              brandId
            }
            address {
              city
              zip
              address
              state {
                stateId
                label
                abbreviation
              }
            }
            menu {
              label
            }
            workWeekStartId
          }
        }
      }
    }
  }
`;

export default LOCATIONS;
