const basePath = '/spark';

const paths = {
  ABANDONED_CARTS: `${basePath}/orders/abandoned-carts`,
  ACTION_LOG_LOCATIONS: `${basePath}/action-log/locations`,
  ACTION_LOG_LOCATION_ITEMS: `${basePath}/action-log/location-items`,
  ACTION_LOG_QUOTE: `${basePath}/action-log/quote`,
  ACTION_LOG_QUOTECATERING: `${basePath}/action-log/quotecatering`,
  ACTION_LOG_LOCATION_INVENTORYITEM: `${basePath}/action-log/location-inventoryitem`,
  BASE: `${basePath}/`,
  CATERING_ORDER: `${basePath}/catering-orders/:quoteId/:currentTab`,
  CATERING_ORDERS: `${basePath}/catering-orders`,
  COREOPS_INVENTORY: `${basePath}/core-ops/inventory`,
  COREOPS_REPORTS: `${basePath}/core-ops/reports`,
  COREOPS_REPORTS_COST_OF_GOODS: `${basePath}/core-ops/reports/cost-of-goods`,
  COREOPS_OPEN_TO_CLOSE: `${basePath}/core-ops/open-to-close`,
  COREOPS_MONTHLY_FINANCIALS: `${basePath}/core-ops/monthly-financials`,
  COREOPS_PROFIT_AND_LOSS_SUMMARY: `${basePath}/core-ops/profit-and-loss-summary`,
  COREOPS_REPORTS_PURCHASE_LOG_INVOICES: `${basePath}/core-ops/reports/purchase-log-invoices`,
  COREOPS_REPORTS_PURCHASE_LOG_PRODUCTS: `${basePath}/core-ops/reports/purchase-log-products`,
  COREOPS_REPORTS_WEEK_IN_LIFE: `${basePath}/core-ops/reports/week-in-life`,
  COREOPS_REPORTS_INVENTORY_COUNT: `${basePath}/core-ops/reports/inventory-count`,
  COREOPS_INVENTORY_LOCATION: `${basePath}/core-ops/inventory/locations/:locationId`,
  COREOPS_INVENTORY_LOCATION_STORAGE_AREAS: `${basePath}/core-ops/inventory/locations/:locationId/storage-areas`,
  COREOPS_INVENTORY_LOCATION_STORAGE_AREAS_STORAGE_ID: `${basePath}/core-ops/inventory/locations/:locationId/storage-areas/:locationStorageId`,
  COREOPS_INVENTORY_LOCATION_INVOICE_JOURNAL: `${basePath}/core-ops/inventory/locations/:locationId/invoice-journal`,
  COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY: `${basePath}/core-ops/inventory/locations/:locationId/invoice-summary/:invoiceId`,
  COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_ID: `${basePath}/core-ops/inventory/locations/:locationId/simple-invoice/:invoiceId`,
  COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER_NEW: `${basePath}/core-ops/inventory/locations/:locationId/inventory-transfer/new`,
  COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER_ID: `${basePath}/core-ops/inventory/locations/:locationId/inventory-transfer/:transferId/details`,
  COREOPS_INVENTORY_LOCATION_INVENTORY_TRANSFER: `${basePath}/core-ops/inventory/locations/:locationId/inventory-transfer`,
  COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_NEW: `${basePath}/core-ops/inventory/locations/:locationId/simple-invoice/new`,
  COREOPS_INVENTORY_LOCATION_TASKLIST: `${basePath}/core-ops/inventory/locations/:locationId/task-list`,
  COREOPS_INVENTORY_LOCATION_STORAGE_AREAS_SUMMARY: `${basePath}/core-ops/inventory/locations/:locationId/storage-areas/summary`,
  DEMO_PAGE: `${basePath}/demo-page`,
  FORGOT_PASSWORD: `${basePath}/forgot-password`,
  HELP: `${basePath}/help`,
  INVENTORY_INGREDIENTS: `${basePath}/inventory/ingredients`,
  ITEM_INFO: `${basePath}/system-settings/menu-tree/itemInfo/:itemId`,
  LABOR: `${basePath}/labor`,
  LABOR_EMPLOYEES: `${basePath}/labor/employees`,
  LABOR_SCHEDULE: `${basePath}/labor/schedule`,
  LABOR_SETTINGS: `${basePath}/labor/settings`,
  LABOR_TIMESHIFT: `${basePath}/labor/timeshift`,
  LOCATIONS_RESTAURANTS_NEW: `${basePath}/locations/restaurants/new`,
  LOCATIONS_RESTAURANTS: `${basePath}/locations/restaurants`,
  LOCATIONS_RESTAURANT: `${basePath}/locations/restaurants/:restaurantId`,
  LOCATION: `${basePath}/locations/:locationId/:currentTab?`,
  LOCATIONS: `${basePath}/locations`,
  LOCATIONS_BULK_EDIT: `${basePath}/locations/bulk-edit`,
  LOCATION_NEW: `${basePath}/locations/new/`,
  LOGIN: `${basePath}/login`,
  LOYALTY_CUSTOMER: `${basePath}/loyalty/customers/:customerId/:currentTab?`,
  LOYALTY_CUSTOMERS: `${basePath}/loyalty/customers`,
  LOYALTY_EVENTS: `${basePath}/loyalty/events`,
  LOYALTY_REWARDS_MYSTERY_REWARDS: `${basePath}/loyalty/rewards/mystery-rewards`,
  LOYALTY_REWARDS_ACHIEVEMENTS: `${basePath}/loyalty/rewards/achievements`,
  LOYALTY_SETTINGS: `${basePath}/loyalty/settings`,
  LOYALTY_SUBMISSIONS: `${basePath}/loyalty/submissions`,
  NEW_CATERING_ORDER: `${basePath}/catering-orders/new-catering-order/:currentTab`,
  ORDERS_ALL: `${basePath}/orders/all-orders`,
  ORDERS_DISPUTES: `${basePath}/orders/disputes`,
  ORDERS_DISPUTES_ID: `${basePath}/orders/disputes/:orderId`,
  ORDERS_ID: `${basePath}/orders/:orderId`,
  REPORTS: `${basePath}/reports`,
  SETTINGS_ACCESS_MANAGEMENT: `${basePath}/system-settings/access-management`,
  SETTINGS_ACCESS_MANAGEMENT_USERGROUP_ID: `${basePath}/system-settings/access-management/:usergroupId/:currentTab?`,
  SETTINGS_CATEGORY: `${basePath}/system-settings/menu-tree/menus/:menuId/categories`,
  SETTINGS_CATEGORY_ID: `${basePath}/system-settings/menu-tree/menus/:menuId/categories/:categoryId`,
  SETTINGS_CHOICES: `${basePath}/system-settings/choices`,
  SETTINGS_CHOICES_CREATE: `${basePath}/system-settings/choices/create`,
  SETTINGS_CHOICES_ID: `${basePath}/system-settings/choices/:choiceId`,
  SETTINGS_CHOICES_MULTIPLE_EDIT: `${basePath}/system-settings/choices/multiple-edit/:multipleId`,
  SETTINGS_DISCOUNTS: `${basePath}/system-settings/discounts`,
  SETTINGS_DISCOUNTS_ID: `${basePath}/system-settings/discounts/:discountId/details`,
  SETTINGS_DISCOUNTS_NEW: `${basePath}/system-settings/discounts/new`,
  SETTINGS_EQUIPMENT: `${basePath}/system-settings/equipment`,
  SETTINGS_HIERARCHY: `${basePath}/system-settings/hierarchy`,
  SETTINGS_INGREDIENTS: `${basePath}/system-settings/ingredients`,
  SETTINGS_INGREDIENTS_ID: `${basePath}/system-settings/ingredients/:ingredientId`,
  SETTINGS_ITEM: `${basePath}/system-settings/menu-tree/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId/items`,
  SETTINGS_ITEM_ID: `${basePath}/system-settings/menu-tree/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId/items/:itemId`,
  SETTINGS_ITEM_TYPE: `${basePath}/system-settings/item-type`,
  SETTINGS_LOYALTY: `${basePath}/system-settings/loyalty`,
  SETTINGS_MENU: `${basePath}/system-settings/menu-tree/menus`,
  SETTINGS_MENU_ID: `${basePath}/system-settings/menu-tree/menus/:menuId`,
  SETTINGS_MENU_TREE: `${basePath}/system-settings/menu-tree`,
  SETTINGS_MINIMUM_VAGES: `${basePath}/system-settings/minimum-vages`,
  SETTINGS_OVERTIME: `${basePath}/system-settings/overtime`,
  SETTINGS_RECIPES: `${basePath}/system-settings/recipes`,
  SETTINGS_INVENTORY_SETTINGS: `${basePath}/system-settings/storage`,
  SETTINGS_INVENTORY_SETTINGS_CATEGORY_SETTINGS: `${basePath}/system-settings/inventory-settings/category-settings`,
  SETTINGS_INVENTORY_SETTINGS_MEASURE_SETTINGS: `${basePath}/system-settings/inventory-settings/measure-settings`,
  SETTINGS_INVENTORY_SETTINGS_STORAGE_AREAS: `${basePath}/system-settings/inventory-settings/storage-areas`,
  SETTINGS_INVENTORY_SETTINGS_VENDORS: `${basePath}/system-settings/storage/vendors`,
  SETTINGS_SUBCATEGORY: `${basePath}/system-settings/menu-tree/menus/:menuId/categories/:categoryId/subcategories`,
  SETTINGS_SUBCATEGORY_ID: `${basePath}/system-settings/menu-tree/menus/:menuId/categories/:categoryId/subcategories/:subcategoryId`,
  SETTINGS_TIERS: `${basePath}/system-settings/tiers`,
  COREOPS_INVENTORY: `${basePath}/core-ops/inventory`,
  COREOPS_INVENTORY_LOCATION: `${basePath}/core-ops/inventory/locations/:locationId`,
  COREOPS_INVENTORY_LOCATION_TASKLIST: `${basePath}/core-ops/inventory/locations/:locationId/task-list`,
  COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_NEW: `${basePath}/core-ops/inventory/locations/:locationId/simple-invoice/new`,
  COREOPS_INVENTORY_LOCATION_SIMPLE_INVOICE_ID: `${basePath}/core-ops/inventory/locations/:locationId/simple-invoice/:invoiceId`,
  COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY: `${basePath}/core-ops/inventory/locations/:locationId/invoice-summary/:invoiceId`,
  INVENTORY_INGREDIENTS: `${basePath}/inventory/ingredients`,
  PRODUCTION_FORECASTER_LOCATIONS: `${basePath}/inventory/production-forecaster/locations`,
  PRODUCTION_FORECASTER: `${basePath}/inventory/production-forecaster/:locationId`,
  WASTE_LOG: `${basePath}/inventory/locations/:locationId/waste-log`,
  SETTINGS_VENDOR: `${basePath}/system-settings/vendors/:vendorId/:currentTab?`,
  SETTINGS_VENDORS: `${basePath}/system-settings/vendors`,
  SETTINGS_VENDOR_NEW: `${basePath}/system-settings/vendors/new`,
};

export default paths;
