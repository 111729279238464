import React, { useCallback, useState, useMemo } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { PermissionField, SelectFactory } from '../../../../../blocks';
import { LOCATIONS } from '../../../../../../apollo/queries';
import { UPDATE_RESTAURANT } from '../../../../../../apollo/mutations';
import { generateStoreAbbreviation } from '../../../../../../utils';

const EditRestaurant = ({ restaurant, primaryLocation, locations, handleCloseModal }) => {
  const { setSnackbar } = useSnackbar();

  const [selectedPrimaryLocation, setSelectedPrimaryLocation] = useState({ ...primaryLocation });
  const [selectedLocations, setSelectedLocations] = useState([...locations]);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);

  const { handleSubmit, register, control, errors } = useForm({
    defaultValues: {
      label: restaurant?.label,
      description: restaurant?.description,
      primaryLocationId: null,
      locations: [],
    },
  });

  const [updateRestaurant, { loading: updateRestaurantLoading }] = useMutation(UPDATE_RESTAURANT, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Restaurant updated!',
      });

      setDisableUpdateButton(false);
      handleCloseModal();
    },
    onError: err => {
      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });

      setDisableUpdateButton(false);
    },
  });

  const onSubmit = useCallback(
    async formData => {
      setDisableUpdateButton(true);
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Updating restaurant...',
      });

      await updateRestaurant({
        variables: {
          input: {
            ...formData,
            primaryLocationId: selectedPrimaryLocation.id,
            locationIds: selectedLocations.map(location => location.id),
            id: restaurant.id,
          },
        },
      });
    },
    [restaurant, updateRestaurant, selectedLocations, selectedPrimaryLocation],
  );

  const locationsFilter = useMemo(() => {
    if (!selectedLocations.length) {
      return null;
    }

    return {
      brand: {
        brandId: {
          nin: selectedLocations.map(location => location?.brandId),
        },
      },
    };
  }, [selectedLocations]);

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              name="label"
              label="Name"
              inputRef={register({ required: true })}
              fullWidth
              control={control}
              error={errors.label}
              placeholder={'Label'}
              helperText={errors?.label ? 'Label cannot be empty' : errors?.label?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={TextField}
              name="description"
              label="Description"
              defaultValue={restaurant?.description}
              fullWidth
              rows={2}
              multiline
              inputRef={register}
              control={control}
              placeholder={'Description'}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={SelectFactory}
              name="primaryLocation"
              label="Primary Location"
              defaultValue={selectedPrimaryLocation}
              fullWidth
              inputRef={register}
              control={control}
              placeholder={'Primary Location'}
              query={LOCATIONS}
              multiple={false}
              filter={{
                restaurantId: {
                  null: true,
                },
              }}
              structureData={data => {
                return (
                  data?.viewer?.locationConnection?.edges.map((location, index) => {
                    return {
                      id: location?.node?.id,
                      label: `${generateStoreAbbreviation(location.node).abbreviation} - ${
                        location?.node?.label
                      }`,
                      brandId: location?.node?.brand?.brandId,
                      index,
                    };
                  }) || []
                );
              }}
              onSelect={value => {
                setSelectedPrimaryLocation(value);

                const primaryLocationIsInLocations = Boolean(
                  selectedLocations.filter(location => location.id === value.id).length,
                );

                if (!primaryLocationIsInLocations) {
                  setSelectedLocations([...selectedLocations, value]);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PermissionField
              Component={SelectFactory}
              name="locations"
              label="Locations"
              defaultValue={selectedLocations}
              fullWidth
              inputRef={register}
              control={control}
              placeholder={'Locations'}
              query={LOCATIONS}
              disableCloseOnSelect={true}
              filter={locationsFilter}
              structureData={data => {
                return (
                  data?.viewer?.locationConnection?.edges.map((location, index) => {
                    return {
                      id: location?.node?.id,
                      label: `${generateStoreAbbreviation(location.node).abbreviation} - ${
                        location?.node?.label
                      }`,
                      brandId: location?.node?.brand?.brandId,
                      index,
                    };
                  }) || []
                );
              }}
              onSelect={value => {
                setSelectedLocations(value);
              }}
            />
          </Grid>

          <Grid container item xs={12} justify="flex-end" spacing={3}>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <div>
                <PermissionField createHelperText>
                  <Button type="submit" disabled={updateRestaurantLoading || disableUpdateButton}>
                    Save
                  </Button>
                </PermissionField>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditRestaurant;
