import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid, GridListTile, GridList, Button, Typography } from '@material-ui/core';
import { Email, Print } from '@material-ui/icons';
import { groupBy, isEmpty, snakeCase } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SAVE_OTC_CHECKLIST, SEND_EMAIL_ATTACHMENT } from '../../../../apollo/mutations';
import { LOCATIONS, OPEN_TO_CLOSE_CHECKLIST } from '../../../../apollo/queries';
import { SNACKBAR_STATUS } from '../../../../constants';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { pad } from '../../../../utils';
import { generateOtcChecklistPdf } from '../../../../utils/pdfUtils';
import { SelectFactory, SideBarSectionsLayout } from '../../../blocks';
import PaperMain from '../../../blocks/PaperMain';
import { formatData } from '../../helpers';
import OpenToCloseChecklist from './OpenToCloseCategory';
import OtcChecklistTask from './OtcChecklistTask';

const OpenToClose = () => {
  const { setSnackbar } = useSnackbar();

  const [location, setLocation] = useState();

  const [getOtcChecklist, { data, loading, error, refetch }] = useLazyQuery(
    OPEN_TO_CLOSE_CHECKLIST,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [saveOtcChecklist] = useMutation(SAVE_OTC_CHECKLIST, {
    onCompleted: () => {
      refetch();

      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'OTC Checklist Saved',
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
  });

  const [sendEmailAttachment] = useMutation(SEND_EMAIL_ATTACHMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'Email sent',
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
  });

  const {
    control,
    errors,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    unregister,
  } = useForm();

  const tabViewData = useMemo(() => {
    if (!isEmpty(data?.viewer?.location?.otcChecklist?.otcChecklistCategory)) {
      return [
        {
          label: 'Open To Close Checklist',
          value: data.viewer.location.otcChecklist.otcChecklistCategory[0].id,
          id: data.viewer.location.otcChecklist.otcChecklistCategory[0].id,
          sections: [
            ...data.viewer.location.otcChecklist.otcChecklistCategory.map(category => ({
              name: category.label,
              id: category.id,
            })),
            { name: 'Daily Deep Cleaning', id: 'otc-checklist-task' },
          ],
        },
      ];
    }
    return null;
  }, [data?.viewer?.location?.otcChecklist?.otcChecklistCategory]);

  const otcTaskListOptions = useMemo(
    () =>
      groupBy(
        data?.viewer?.location?.otcChecklist?.otcChecklistTask?.map(task => ({
          value: task.id,
          label: task.label,
          weekdayId: task.weekday.id,
        })) || [],
        'weekdayId',
      ),
    [data?.viewer?.location?.otcChecklist?.otcChecklistTask],
  );

  const initialTasks = useMemo(
    () =>
      data?.viewer?.location?.otcChecklist?.otcChecklistTask
        ?.filter(task => task.selected)
        ?.map(task => ({
          value: task.id,
          label: task.label,
          weekdayId: task.weekday.id,
        })),
    [data?.viewer?.location?.otcChecklist?.otcChecklistTask],
  );

  const tabs = useMemo(() => {
    if (!isEmpty(data?.viewer?.location?.otcChecklist?.otcChecklistCategory)) {
      return data.viewer.location.otcChecklist.otcChecklistCategory.map(category => (
        <GridListTile key={`checklist-${category.id}`}>
          <div id={category.id}>
            <OpenToCloseChecklist
              {...category}
              id={`otcChecklistCategory-${category.id}`}
              control={control}
              errors={errors}
              register={register}
              reset={reset}
              setValue={setValue}
              getValues={getValues}
              unregister={unregister}
            />
          </div>
        </GridListTile>
      ));
    }

    return [];
  }, [data?.viewer?.location?.otcChecklist?.otcChecklistCategory]);

  const handleOnLocationSelect = useCallback(
    value => {
      setLocation(value);

      if (value?.id) {
        getOtcChecklist({
          variables: {
            locationId: value.id,
          },
        });
      }
    },
    [getOtcChecklist],
  );

  const onSubmit = useCallback(
    formData => {
      if (!isEmpty(data?.viewer?.location?.otcChecklist?.otcChecklistCategory)) {
        const input = {
          locationId: data?.viewer?.location?.locationId,
          otcChecklistCategory: [],
          otcChecklistTask: formData.otcChecklistTask?.map(task => ({ id: task.value })),
        };

        data.viewer.location.otcChecklist.otcChecklistCategory.forEach(category =>
          input.otcChecklistCategory.push({
            id: category.id,
            note: formData[`otcChecklistCategory-${category.id}`]?.note,
            otcChecklistField: formatData({
              formData,
              label: `otcChecklistCategory-${category.id}-otcChecklistField`,
              mapFunction: ({ fieldId, value }) => ({
                id: fieldId,
                value,
              }),
            }),
          }),
        );

        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          open: true,
          text: 'Saving OTC Checklist...',
        });

        saveOtcChecklist({ variables: { input } });
      }
    },
    [data?.viewer?.location?.otcChecklist?.otcChecklistCategory],
  );

  const handleOnPrintClick = useCallback(() => {
    if (data?.viewer?.location?.otcChecklist) {
      generateOtcChecklistPdf({
        title: location.label,
        otcChecklistCategory: data.viewer.location.otcChecklist.otcChecklistCategory,
        otcChecklistTask: data.viewer.location.otcChecklist.otcChecklistTask?.filter(
          task => task.selected,
        ),
        date: data.viewer.location.otcChecklist.date,
      });
    }
  }, [data?.viewer?.location?.otcChecklist, location]);

  const handleOnSendEmailClick = useCallback(() => {
    if (data?.viewer?.location?.otcChecklist) {
      const pdf = generateOtcChecklistPdf({
        title: location.label,
        otcChecklistCategory: data.viewer.location.otcChecklist.otcChecklistCategory,
        otcChecklistTask: data.viewer.location.otcChecklist.otcChecklistTask?.filter(
          task => task.selected,
        ),
        date: data.viewer.location.otcChecklist.date,
        open: false,
      });

      setSnackbar({
        open: true,
        text: 'Sending email...',
        type: SNACKBAR_STATUS.INFO,
      });

      pdf.getBase64(result => {
        const input = {
          locationId: data.viewer.location.locationId,
          attachment: result,
          subject: `Open_To_Close_${data.viewer.location.otcChecklist.date}_${snakeCase(
            location.label,
          )}`,
          rawHtml:
            '<div>Hello,</div><div>You can find your Open To Close Checklist in the attachment</div>',
          attachmentName: `Open_To_Close_${data.viewer.location.otcChecklist.date}_${snakeCase(
            location.label,
          )}`,
          to: 'locationOwners',
        };

        sendEmailAttachment({
          variables: {
            input,
          },
        });
      });
    }
  }, [data?.viewer?.location?.otcChecklist, location]);

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Open To Close Checklist</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectFactory
            label={'Location'}
            placeholder={'Select Location'}
            query={LOCATIONS}
            renderCheckBox={false}
            multiple={false}
            defaultValue=""
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.id,
                index,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
            onSelect={handleOnLocationSelect}
          />
        </Grid>
        {!loading && location?.id && tabViewData ? (
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnPrintClick}>
                  <Print style={{ marginRight: '6px' }} />
                  Print
                </Button>
              </Grid>
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnSendEmailClick}>
                  <Email style={{ marginRight: '6px' }} />
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {loading || error ? (
          <Grid item xs={12}>
            <div>{loading ? 'Loading...' : 'Error'}</div>
          </Grid>
        ) : null}
        {!loading && location?.id && tabViewData ? (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SideBarSectionsLayout xs={3} sectionList={tabViewData[0].sections || []}>
                <Grid container style={{ padding: '0 16px' }}>
                  <Grid item xs={12}>
                    <GridList cellHeight={'auto'} cols={1}>
                      {tabs}

                      <GridListTile key={'checklist-daily-deep-cleaning'}>
                        <div id="otc-checklist-task">
                          <OtcChecklistTask
                            setValue={setValue}
                            getValues={getValues}
                            control={control}
                            initialTasks={initialTasks}
                            otcTaskListOptions={otcTaskListOptions}
                          />
                        </div>
                      </GridListTile>
                    </GridList>
                  </Grid>

                  <Grid item xs={12} container justify="flex-end">
                    <Grid item>
                      <Button type="submit">Submit</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </SideBarSectionsLayout>
            </form>
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default OpenToClose;
