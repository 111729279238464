import moment from 'moment-timezone';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ConnectionTable, SelectFactory, Elements, Permission } from '../../../../../blocks';
import { groupBy } from 'lodash';
import { orderListViewStyles } from './styles';
import { paths } from '../../../../../../constants';
import { generateStoreAbbreviation, convert } from '../../../../../../utils';
import React, { useState, useCallback } from 'react';
import {
  MenuItem,
  ListSubheader,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';
import { ROWS_PER_PAGE_OPTIONS } from '../../../../../../constants';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '../../../../../../assets/icons/SearchIcon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { compact, debounce } from 'lodash';
import { Autocomplete } from '@material-ui/lab';

const MenuItemWithTitle = uniqueMenuCategories => {
  const menuItemTitleArray = [];
  const menuItemTitleObj = groupBy(uniqueMenuCategories, 'node.menu.brand.label');
  for (const property in menuItemTitleObj) {
    const brandTitle = property;
    const brandCategories = menuItemTitleObj[property];
    menuItemTitleArray.push(<ListSubheader>{brandTitle}</ListSubheader>);
    menuItemTitleArray.push(
      brandCategories.map(menuCategory => (
        <MenuItem
          key={menuCategory.node.category.categoryId}
          value={menuCategory.node.category.categoryId}
        >
          {menuCategory.node.category.label}
        </MenuItem>
      )),
    );
  }
  return menuItemTitleArray;
};

const OrderListView = ({
  locationSelectFactoryQuery,
  sources,
  menuCategories,
  handoffs,
  query,
  stages,
  brands,
  tenders,
  getSelectedDateRangeValue,
  handleDateRangeStartChange,
  handleDateRangeEndChange,
  handleOpenDateRangeSelectionModal,
  handleCloseDateRangeSelectionModal,
  dateRangeStart,
  dateRangeEnd,
  openDateRangeSelectionModal,
  handleDateRangeSelection,
  stateList,
  cities,
  getLocationsByState,
}) => {
  const classes = orderListViewStyles();
  const [searchValue, setSearchValue] = useState();

  const search = (refetch, variables, value) => {
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
        quoteId: {
          eq: parseInt(value),
        },
      },
    });
    setSearchValue(value);
  };

  const [debouncedSearch] = useState(() =>
    debounce((refetch, variables, value) => search(refetch, variables, value), 200),
  );
  const handleSearchInput = (refetch, variables, event) => {
    setSearchValue(event.target.value);
  };
  const removeQuoteIdFilter = (refetch, variables) => {
    delete variables.filter.quoteId;
    refetch({
      ...variables,
      first: variables.first || variables.last,
      filter: {
        ...variables?.filter,
      },
    });
    document.getElementById('quoteID').value = null;
    setSearchValue(null);
  };

  const filterLocations = variables => {
    if (variables?.filter?.location?.address?.city) {
      return {
        address: {
          city: {
            eq: variables.filter.location.address.city.eq,
          },
        },
      };
    } else if (variables?.filter?.location?.address?.state) {
      return {
        address: {
          state: {
            id: {
              eq: variables.filter.location.address.state.id.eq,
            },
          },
        },
      };
    } else {
      return {};
    }
  };

  const renderBrands = useCallback(
    () =>
      brands?.viewer?.brandConnection?.edges?.map(brand => {
        return (
          <MenuItem key={brand.node.brandId} value={brand.node.brandId}>
            {brand.node.label}
          </MenuItem>
        );
      }),
    [brands],
  );

  const renderTenders = useCallback(
    () =>
      tenders?.viewer?.tenderConnection?.edges?.map(tender => {
        return (
          <MenuItem key={tender.node.tenderId} value={tender.node.tenderId}>
            {tender.node.label}
          </MenuItem>
        );
      }),
    [tenders],
  );

  return (
    <ConnectionTable
      query={query}
      onTableRowClick={row =>
        window.open(`${paths.ORDERS_ID.replace(':orderId', row.quoteId)}/generalInfo`)
      }
      searchPlaceholder={'Search by customer first/last name'}
      searchContainerStyle={{ maxWidth: '29%' }}
      initialQueryVariables={{
        first: ROWS_PER_PAGE_OPTIONS[0],
        sort: [
          {
            expected: 'DESC',
          },
        ],
        filter: {
          stageId: {
            null: false,
          },
        },
        specificFields: ['first_name', 'last_name'],
      }}
      title="Orders"
      structureTable={data =>
        data?.viewer?.quoteConnection?.edges.map(quote => {
          return {
            'origin.label': quote.node.origin?.label,
            quoteId: quote.node.quoteId,
            'category.label': quote.node.category?.label,
            'handoff.label': quote.node.handoff?.label,
            location: generateStoreAbbreviation(quote.node.location).abbreviation,
            person:
              quote?.node?.person?.firstName && quote?.node?.person?.lastName
                ? `${quote.node.person?.firstName} ${quote.node.person?.lastName}`
                : '/',
            totalPrice: `$ ${convert.centsToDollars(quote.node.totalPrice)}`,
            dueAmount: `$ ${convert.centsToDollars(quote.node.dueAmount)}`,
            expected: moment
              .tz(moment.utc(quote.node.expected), quote.node.location?.timezone?.label)
              .format('MM/DD/YYYY h:mm A z'),
            'stage.label': quote.node.stage?.label,
            deliveryPartner: quote.node.doordashId || quote.node.burqId,
            printjob: quote.node.printJob,
            payments: quote.node.paymentConnection.edges,
          };
        })
      }
      filterByDate={true}
      columns={[
        { title: 'Source', field: 'origin.label', disableSort: true },
        { title: 'Quote ID', field: 'quoteId' },
        {
          title: 'Category',
          field: 'category.label',
          disableSort: true,
        },
        {
          title: 'Handoff',
          field: 'handoff.label',
          disableSort: true,
        },
        {
          title: 'Location ID',
          field: 'location',
          disableSort: true,
          customPermissionField: 'location.id',
        },
        {
          title: 'Customer',
          field: 'person',
          disableSort: true,
          customPermissionField: 'person.__typename',
        },
        { title: 'Total', field: 'totalPrice', disableSort: true },
        { title: 'Due Amount', field: 'dueAmount', disableSort: true },
        { title: 'Order Timing', field: 'expected' },
        { title: 'Status', field: 'stage.label', disableSort: true },
        {
          title: 'Burq ID/DoorDash ID',
          field: 'deliveryPartner',
          disableSort: true,
        },
        {
          title: 'Printed',
          field: 'printjob',
          disableSort: true,
          render: printjob => {
            if (!printjob?.inQueue) {
              return 'N/A';
            }
            return printjob?.inQueue && printjob?.datePrinted ? (
              <CheckCircleOutlineIcon />
            ) : (
              <ErrorOutlineIcon />
            );
          },
        },
        {
          title: 'Tender',
          field: 'payments',
          disableSort: true,
          render: payments => {
            return payments.map(payment => <p>{payment.node.tender.label}</p>);
          },
        },
      ]}
      customActionsTop={(data, { refetch, variables }) => {
        const dateRangeValue = getSelectedDateRangeValue(variables);
        const permissions = data?.viewer?.quoteConnection?.permissions;

        return (
          <>
            <div
              className={classes.selectFactoryWrap}
              style={{ marginLeft: '0', marginBottom: '0.5rem' }}
            >
              <Permission access={permissions?.quoteId}>
                <TextField
                  name={'quoteID'}
                  id={'quoteID'}
                  variant={'outlined'}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      debouncedSearch(refetch, variables, searchValue);
                    }
                  }}
                  value={searchValue}
                  type={'number'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => debouncedSearch(refetch, variables, searchValue)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img alt="Search icon" src={SearchIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="remove"
                        onClick={() => removeQuoteIdFilter(refetch, variables)}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    ),
                  }}
                  onChange={event => handleSearchInput(refetch, variables, event)}
                  placeholder={'Search ID'}
                />
              </Permission>
              <Permission access={permissions?.expected && 7}>
                <FormControl variant="outlined" className={classes.selectDateRange}>
                  <IconButton
                    disableRipple
                    onClick={handleOpenDateRangeSelectionModal}
                    className={classes.dateRangeTextButton}
                  >
                    <TextField
                      label="Select Date Range"
                      value={dateRangeValue || 'MM/DD/YYYY to MM/DD/YYYY'}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      variant="outlined"
                    />
                  </IconButton>
                  {dateRangeValue !== 'MM/DD/YYYY to MM/DD/YYYY' && (
                    <IconButton
                      disableRipple
                      className={classes.dateRangeCloseButton}
                      onClick={() => handleDateRangeSelection({ refetch, variables }, true)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Dialog
                    open={openDateRangeSelectionModal}
                    onClose={handleCloseDateRangeSelectionModal}
                    disableEnforceFocus
                  >
                    <DialogTitle disableTypography>
                      <Typography variant="h6">Select Date Range</Typography>
                      <IconButton
                        aria-label="close"
                        className={classes.dialogCloseButton}
                        onClick={handleCloseDateRangeSelectionModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <DialogContent dividers>
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range start"
                              value={dateRangeStart}
                              onChange={handleDateRangeStartChange}
                            />
                          </Grid>
                          <Grid item>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/DD/YYYY"
                              margin="normal"
                              label="Date range end"
                              value={dateRangeEnd}
                              onChange={handleDateRangeEndChange}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDateRangeSelection({ refetch, variables })}>
                          Apply
                        </Button>
                      </DialogActions>
                    </MuiPickersUtilsProvider>
                  </Dialog>
                </FormControl>
              </Permission>
            </div>
          </>
        );
      }}
      customActionsCenter={(data, { refetch, variables }) => {
        const permissions = data?.viewer?.quoteConnection?.permissions;
        const uniqueMenuCategories = menuCategories?.viewer?.menuCategoryConnection?.edges?.reduce(
          (acc, current) => {
            const x = acc.find(
              menuCategory =>
                menuCategory.node.category.categoryId === current.node.category.categoryId,
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          [],
        );

        return (
          <>
            <div className={classes.selectFactoryWrap}>
              <Permission access={permissions?.origin?.__typename}>
                <FormControl variant="outlined">
                  <InputLabel>Source</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.originId;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            originId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Source"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sources?.viewer?.originConnection?.edges?.map(origin => (
                      <MenuItem key={origin.node.originId} value={origin.node.originId}>
                        {origin.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={permissions?.stage?.__typename && 7}>
                <FormControl variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;

                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.stageId;
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            stageId: {
                              null: false,
                            },
                          },
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            stageId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {stages?.viewer?.stageConnection?.edges?.map(stage => (
                      <MenuItem key={stage.node.stageId} value={stage.node.stageId}>
                        {stage.node.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={7}>
                {/* TODO brand permissions */}
                <FormControl variant="outlined">
                  <InputLabel>Brand</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.brand;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            brand: {
                              brandId: {
                                eq: value,
                              },
                            },
                          },
                        });
                      }
                    }}
                    label="Brands"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {renderBrands()}
                  </Select>
                </FormControl>
              </Permission>
              <Permission access={7}>
                <FormControl variant="outlined">
                  <InputLabel>Category</InputLabel>
                  <Select
                    defaultValue=""
                    onChange={event => {
                      const value = event.target.value;
                      if (value === '') {
                        const filters = variables.filter;
                        delete filters.categoryId;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            categoryId: {
                              eq: value,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {MenuItemWithTitle(uniqueMenuCategories)}
                  </Select>
                </FormControl>
              </Permission>
              <FormControl variant="outlined">
                <InputLabel>Payment</InputLabel>
                <Select
                  defaultValue=""
                  onChange={event => {
                    const value = event.target.value;
                    if (value === '') {
                      const filters = variables.filter;
                      delete filters.tenderIds;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          tenderIds: {
                            in: value,
                          },
                        },
                      });
                    }
                  }}
                  label="Payment method"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {renderTenders()}
                </Select>
              </FormControl>
            </div>
          </>
        );
      }}
      customActionsBottom={(data, { refetch, variables }) => {
        // const categoryOptions = categories?.viewer?.categoryConnection?.edges?.map(
        //   (category) => ({
        //     value: category.node.categoryId,
        //     label: category.node.label,
        //   })
        // );

        const handoffOptions = handoffs?.viewer?.handoffConnection?.edges?.map(handoff => ({
          value: handoff.node.handoffId,
          label: handoff.node.label,
        }));
        const permissions = data?.viewer?.quoteConnection?.permissions;

        return handoffOptions ? (
          <div>
            <div
              style={{
                marginBottom: '.5rem',
                marginTop: '.5rem',
                display: 'flex',
                marginLeft: '0rem',
              }}
              className={classes.selectFactoryWrap}
            >
              <div style={{ display: 'flex' }}>
                <Autocomplete
                  id="state"
                  getOptionLabel={option => option.label}
                  style={{ paddingRight: '12px' }}
                  defaultValue={{ label: 'None', value: 0 }}
                  renderInput={params => <TextField {...params} label="State" />}
                  onChange={(event, selected) => {
                    document.querySelector('#city__grid .MuiAutocomplete-clearIndicator')?.click();

                    if (selected) {
                      getLocationsByState({
                        variables: {
                          filter: {
                            address: {
                              state: {
                                id: {
                                  eq: selected.value,
                                },
                              },
                            },
                          },
                        },
                      });
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          location: {
                            ...variables.filter?.location,
                            address: {
                              state: {
                                id: {
                                  eq: selected.value,
                                },
                              },
                            },
                          },
                        },
                      });
                    } else if (variables?.filter?.location?.address?.state?.id?.eq) {
                      const filters = variables.filter;
                      delete filters.location.address;

                      getLocationsByState({
                        variables: {
                          filter: {
                            address: {
                              state: {
                                id: {
                                  eq: '',
                                },
                              },
                            },
                          },
                        },
                      });
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    }
                  }}
                  options={stateList || []}
                  fullWidth
                />
                {/* <div id="city__grid" style={{ display: 'flex' }}> */}
                <Autocomplete
                  id="city__grid"
                  getOptionLabel={option => option.label}
                  defaultValue={{ label: 'None', value: 0 }}
                  style={{ paddingRight: '12px' }}
                  renderInput={params => <TextField {...params} label="City" />}
                  onChange={(event, selected) => {
                    if (
                      (!selected || selected.value === 'None') &&
                      variables?.filter?.location?.address
                    ) {
                      const filters = variables.filter;
                      delete filters.location.address.city;
                      refetch({
                        ...variables,
                        filter: filters,
                      });
                    } else if (selected?.value) {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          location: {
                            ...variables.filter.location,
                            address: {
                              ...variables.filter.location.address,
                              city: {
                                eq: selected.value,
                              },
                            },
                          },
                        },
                      });
                    }
                  }}
                  options={cities || []}
                  disabled={!cities.length}
                  fullWidth
                />
                {/* </div> */}
                <Permission access={permissions?.location?.__typename && 7}>
                  <SelectFactory
                    label={'Location'}
                    placeholder={'Select Location'}
                    query={locationSelectFactoryQuery}
                    sort={{
                      label: 'ASC',
                    }}
                    filter={filterLocations(variables)}
                    disableCloseOnSelect={true}
                    structureData={data => {
                      return data?.viewer?.locationConnection?.edges.map((location, index) => {
                        return {
                          label: compact([location.node.label, location.node.storeNumber]).join(
                            ' - #',
                          ),
                          id: location.node.locationId,
                          index,
                        };
                      });
                    }}
                    onSelect={values => {
                      const locationIds = values.map(location => location.id);
                      if (values.length === 0) {
                        const filters = variables.filter;
                        delete filters.locationId;
                        refetch({
                          ...variables,
                          filter: filters,
                        });
                      } else {
                        refetch({
                          ...variables,
                          filter: {
                            ...variables.filter,
                            locationId: {
                              in: locationIds,
                            },
                          },
                        });
                      }
                    }}
                  />
                </Permission>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {/* <Permission access={categories?.viewer?.categoryConnection?.permissions?.__typename && 7}>
              <div style={{ marginRight: '1rem' }}>
                <Elements.ToggleButton
                  options={categoryOptions}

                  handleToggleClick={(value) => {
                    refetch({
                      ...variables,
                      filter: {
                        ...variables.filter,
                        categoryId: {
                          eq: value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </Permission> */}
              <Permission
                access={handoffs?.viewer?.handoffConnection?.permissions?.__typename && 7}
              >
                <div>
                  <Elements.ToggleButton
                    options={handoffOptions}
                    handleToggleClick={value => {
                      refetch({
                        ...variables,
                        filter: {
                          ...variables.filter,
                          handoffId: {
                            eq: value,
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Permission>
            </div>
          </div>
        ) : null;
      }}
    />
  );
};

export default OrderListView;
