import { gql } from '@apollo/client';
import { locationAddressFragment, stateFragment, timezoneFragment } from '../fragments';
import menuFragment from '../fragments/menuFragment';
import { taxGroupFragment } from '../fragments/taxGroupFragment';
import { taxRateFragment } from '../fragments/taxRateFragment';

/* IMPORTANT!!
  TODO: if location is not existing, return permissions and not just location `null`,
  then you can remove locationConnection query part and replace permissions on location/generalInfo
  to map from location.permissions and not locationConnection.permissions
*/

const FETCH_LOCATION = gql`
  query fetchLocation($id: ID!, $first: Int!) {
    viewer {
      id
      timezoneConnection(fieldGroup: 17) {
        permissions
        edges {
          node {
            ...TimezoneData
          }
        }
      }
      restaurantConnection {
        permissions
        edges {
          node {
            id
            restaurantId
            label
          }
        }
      }
      countryConnection {
        edges {
          node {
            id
            countryId
            label
            alpha2
          }
        }
      }
      menuConnection(first: $first, fieldGroup: 17) {
        permissions
        edges {
          node {
            ...MenuData
          }
        }
      }
      stateConnection(first: $first, fieldGroup: 17) {
        permissions
        edges {
          node {
            ...StateData
          }
        }
      }

      location(id: $id, fieldGroup: 17) {
        permissions
        taxgroupConnection(first: $first) {
          edges {
            node {
              ...TaxGroupData
            }
          }
        }
        brand {
          id
          brandId
          label
          description
          abbreviation
        }
        restaurant {
          id
          restaurantId
          label
        }
        restaurantId
        payrollPeriodId
        payrollPeriod {
          id
          label
        }
        legalBusinessName
        autoSubmitInvoices
        allowAutomaticInventoryitemStockToggle
        storeTypeId
        burqId
        storeType
        printerService {
          id
          label
          printerserviceId
        }
        tierId
        locationWeekdayConnection(filter: { showHoliday: { eq: true } }) {
          edges {
            node {
              id
              opened
              closed
              weekdayId
              active
              locationHours {
                id
                weekdayId
                active
                opened
                closed
              }
            }
          }
        }
        menu {
          ...MenuData
        }
        taxrateConnection(first: $first) {
          edges {
            node {
              ...TaxRateData
            }
          }
        }
        id
        locationId
        slug
        label
        timezone {
          ...TimezoneData
        }
        locationInfo
        cateringInfo
        workWeekStartId
        locationPicture
        pickupLocation
        opened
        closed
        phone {
          id
          phone
        }
        automaticTracker
        squareLocationId
        hygieneChitReminder
        receiptFooter
        email
        taxgroup {
          ...TaxGroupData
        }
        storeNumber
        printerPassword
        address {
          ...LocationAddressData
        }
        stripeAccount
      }
    }
  }
  ${menuFragment.menu}
  ${taxRateFragment.taxRate}
  ${taxGroupFragment.taxGroup}
  ${locationAddressFragment.address}
  ${stateFragment.state}
  ${timezoneFragment.timezone}
`;

export default FETCH_LOCATION;
