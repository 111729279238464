import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import logo from '../../assets/images/logo2.png';
import { isEmpty } from 'lodash';

function getCheckbox(checked) {
  return {
    table: {
      widths: [12],
      heights: [12],
      body: [
        [
          {
            border: [true, true, true, true],
            fillColor: checked ? '#000000' : '#ffffff',
            text: '',
          },
        ],
      ],
    },
  };
}

function createTableCheckInput({ checkedYes, checkedNo }) {
  return [
    getCheckbox(checkedYes),
    {
      text: 'YES',
      style: 'option',
    },
    getCheckbox(checkedNo),
    {
      text: 'NO',
      style: 'option',
    },
  ];
}

function createTableBody({ questions = [] }) {
  return questions.map((question, index) => [
    {
      style: 'note',
      stack: [
        question.label,
        isEmpty(question.answers)
          ? {
              style: 'emptyInput',
              text: '',
            }
          : question.answers.map(answer => ({
              style: 'input',
              text: answer.value,
            })),
      ],
    },
  ]);
}

function createContent({ date, fifteenPlanQuestionGroup = [], additionalQuestions = [] }) {
  return [
    {
      layout: 'customLayout',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'General Info',
              style: 'paragraphTitle',
            },
          ],
          [
            {
              text: [
                { text: 'Date:  ', style: 'note' },
                { style: 'input', text: date },
              ],
            },
          ],
        ],
      },
    },
    ...fifteenPlanQuestionGroup.map(questionGroup => ({
      layout: 'customLayout',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: questionGroup.label,
              style: 'paragraphTitle',
            },
          ],
          ...createTableBody({
            questions: questionGroup.fifteenPlanQuestions,
          }),
        ],
      },
    })),

    additionalQuestions
      ? {
          layout: { defaultBorder: false },
          style: 'contentTable',
          table: {
            widths: ['*', 'auto', 'auto', 'auto', 'auto'],
            body: additionalQuestions.map(question => [
              {
                style: 'note',
                text: [question.label],
              },
              ...createTableCheckInput({
                checkedYes: question.value,
                checkedNo: !question.value,
              }),
            ]),
          },
        }
      : '',
  ];
}

function getDocDefition({ title, date, fifteenPlanQuestionGroup, additionalQuestions }) {
  const content = createContent({ date, fifteenPlanQuestionGroup, additionalQuestions });

  return {
    header: {
      margin: 12,
      layout: 'noBorders',
      style: 'headerTable',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { image: 'logo', width: 100, height: 55 },
            {
              stack: [
                { style: 'header', text: title },
                { style: 'header', text: '15% Plan - Monthly Financials' },
              ],
            },
          ],
        ],
      },
    },
    footer: function(currentPage, pageCount) {
      return {
        style: 'footer',
        columns: [
          {
            alignment: 'center',
            text: `${currentPage.toString()} of ${pageCount}`,
          },
        ],
        margin: [12, 0, 12, 12],
      };
    },

    content: content,

    styles: {
      paragraphTitle: {
        bold: true,
        fontSize: 14,
      },
      headerTable: {
        marginBottom: 20,
      },
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      contentTable: {
        marginBottom: 10,
      },
      footer: {
        fontSize: 10,
        color: '#666666',
      },
      note: {
        fontSize: 10,
        color: '#666666',
      },
      input: {
        color: '#3B3A43',
        fontSize: 10,
      },
      option: {
        fontSize: 12,
        bold: true,
      },
      emptyInput: {
        marginBottom: 14,
      },
    },
    defaultStyle: {
      color: '#3B3A43',
    },
    pageMargins: [12, 90, 12, 24],
    images: {
      logo,
    },
  };
}

const generateFifteenPlanPdf = ({
  title = '',
  fifteenPlanQuestionGroup = [],
  date,
  additionalQuestions,
  open = true,
}) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinition = getDocDefition({
    title,
    date,
    fifteenPlanQuestionGroup,
    additionalQuestions,
  });

  pdfMake.tableLayouts = {
    customLayout: {
      hLineWidth: function(i) {
        return i === 1 ? 1 : 0;
      },
      vLineWidth: function() {
        return 0;
      },
      hLineColor: function() {
        return '#ffcb02';
      },
      paddingBottom: function() {
        return 6;
      },
    },
  };

  if (open) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    return pdfMake.createPdf(docDefinition);
  }
};

export default generateFifteenPlanPdf;
