import { gql } from '@apollo/client';

const FETCH_TENDERS = gql`
  query fetchTenders {
    viewer {
      id
      tenderConnection {
        edges {
          node {
            id
            tenderId
            label
          }
        }
      }
    }
  }
`;

export default FETCH_TENDERS;
