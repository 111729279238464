import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { INVOICE_ICON, paths } from '../../../../constants';

import CreateSimpleInvoiceIcon from '../../../../assets/icons/CreateSimpleInvoiceIcon.png';
import ViewInvoiceJournalIcon from '../../../../assets/icons/ViewInvoiceJournalIcon.png';
import simpleInvoiceIcon from '../../../../assets/icons/CoreOpsFormsIcon.png';
import usFoodsIcon from '../../../../assets/icons/UsFoodsIcon.png';
import InventoryCountIcon from '../../../../assets/icons/InventoryCount.png';

function getIconSpecifications(type) {
  switch (type) {
    case INVOICE_ICON.SIMPLE_INVOICE: {
      return {
        iconUrl: simpleInvoiceIcon,
        size: '20px',
        marginRight: '0px',
        marginLeft: '16px',
      };
    }
    case INVOICE_ICON.US_FOODS: {
      return {
        iconUrl: usFoodsIcon,
        size: '24px',
        marginRight: '0px',
        marginLeft: '16px',
      };
    }
    case INVOICE_ICON.VIEW_INVOICE_JOURNAL: {
      return {
        iconUrl: ViewInvoiceJournalIcon,
        size: '20px',
        marginRight: '8px',
        marginLeft: 0,
      };
    }
    case INVOICE_ICON.CREATE_SIMPLE_INVOICE: {
      return {
        iconUrl: CreateSimpleInvoiceIcon,
        size: '20px',
        marginRight: '8px',
        marginLeft: 0,
      };
    }
    case INVOICE_ICON.FULL_INVENTORY_COUNT: {
      return {
        iconUrl: InventoryCountIcon,
        size: '24px',
        marginRight: '0px',
        marginLeft: '16px',
      };
    }
    case INVOICE_ICON.WASTE_LOG: {
      return {
        MuiComponent: DeleteIcon,
        size: '24px',
        marginRight: '0px',
        marginLeft: '16px',
      };
    }
    case INVOICE_ICON.LOCATIONS: {
      return { MuiComponent: ArrowBack, size: '24px', marginRight: '8px', marginLeft: 0 };
    }
  }
}

export const renderIcon = type => {
  const { MuiComponent, iconUrl, marginRight, marginLeft, size } = getIconSpecifications(type);

  return (
    <div
      style={{
        width: '34px',
        height: '34px',
        borderRadius: '50%',
        background: '#ffda01',
        backgroundImage: `url(${iconUrl})`,
        backgroundPosition: 'center',
        backgroundSize: size,
        backgroundRepeat: 'no-repeat',
        marginRight,
        marginLeft,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {MuiComponent && <MuiComponent />}
    </div>
  );
};

export const BUTTON_NAME = {
  CREATE_INVOICE: 'createInvoice',
  TASK_LIST: 'taskList',
  VIEW_JOURNAL: 'viewJournal',
  LOCATIONS: 'locations',
  PRINT_LAST_COUNT: 'lastCount',
  INVENTORY_TRANSFER: 'inventoryTransfer',
  INVENTORY_TRANSFER_CREATE: 'inventoryTransferCreate',
  MORE_ACTIONS: 'moreActions',
  JUMP_TO_COUNT_REPORTS: 'inventoryCountReport',
};

function getButtonStyle(hasMarginBottom) {
  return {
    width: '100%',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '150px',
    marginBottom: hasMarginBottom ? '8px' : undefined,
  };
}

export function getButtons(buttons = [], onClickCallback = {}) {
  return (
    <div style={{ width: 'min-content' }}>
      {buttons.map(({ buttonName, link, disabled }, index) => {
        const buttonStyle = getButtonStyle(index < buttons.length - 1);

        switch (buttonName) {
          case BUTTON_NAME.CREATE_INVOICE: {
            return (
              <Link to={link || 'simple-invoice/new'}>
                <Button variant="outlined" style={buttonStyle}>
                  <div>{renderIcon(INVOICE_ICON.CREATE_SIMPLE_INVOICE)}</div>
                  <div>Create Simple Invoice</div>
                </Button>
              </Link>
            );
          }

          case BUTTON_NAME.VIEW_JOURNAL: {
            return (
              <Link to={link || 'invoice-journal'}>
                <Button variant="outlined" style={buttonStyle}>
                  {renderIcon(INVOICE_ICON.VIEW_INVOICE_JOURNAL)}
                  View Invoice Journal
                </Button>
              </Link>
            );
          }

          case BUTTON_NAME.PRINT_LAST_COUNT: {
            return (
              <Button
                variant="outlined"
                style={buttonStyle}
                onClick={e => {
                  onClickCallback[BUTTON_NAME.PRINT_LAST_COUNT](e);
                }}
              >
                {renderIcon(INVOICE_ICON.VIEW_INVOICE_JOURNAL)}
                Print Last Inventory Count
              </Button>
            );
          }
          case BUTTON_NAME.MORE_ACTIONS: {
            return (
              <Button
                variant="outlined"
                style={buttonStyle}
                onClick={e => {
                  onClickCallback[BUTTON_NAME.MORE_ACTIONS](e);
                }}
              >
                More Actions
              </Button>
            );
          }

          case BUTTON_NAME.TAKS_LIST: {
            return (
              <Link to={link || 'task-list'}>
                <Button variant="outlined" style={buttonStyle}>
                  {renderIcon(INVOICE_ICON.VIEW_INVOICE_JOURNAL)}
                  Task List
                </Button>
              </Link>
            );
          }

          case BUTTON_NAME.INVENTORY_TRANSFER: {
            return (
              <Link to={link || 'inventory-transfer'}>
                <Button variant="outlined" style={buttonStyle}>
                  {renderIcon(INVOICE_ICON.VIEW_INVOICE_JOURNAL)}
                  Inventory Transfers
                </Button>
              </Link>
            );
          }

          case BUTTON_NAME.INVENTORY_TRANSFER_CREATE: {
            return (
              <Link to={link || 'inventory-transfer/new'}>
                <Button variant="outlined" style={buttonStyle}>
                  Create Inventory Transfer Request
                </Button>
              </Link>
            );
          }

          case BUTTON_NAME.LOCATIONS: {
            return (
              <Link to={paths.COREOPS_INVENTORY}>
                <Button variant="outlined" style={buttonStyle}>
                  {renderIcon(INVOICE_ICON.LOCATIONS)}
                  Back to Locations List
                </Button>
              </Link>
            );
          }
          case BUTTON_NAME.JUMP_TO_COUNT_REPORTS: {
            return (
              <Link to={paths.COREOPS_REPORTS_INVENTORY_COUNT}>
                <Button variant="outlined" style={buttonStyle}>
                  {renderIcon(INVOICE_ICON.VIEW_INVOICE_JOURNAL)}
                  Inventory Count Report
                </Button>
              </Link>
            );
          }
        }
      })}
    </div>
  );
}
