import { gql } from '@apollo/client';

const OPEN_TO_CLOSE_CHECKLIST = gql`
  query otcChecklist($locationId: ID!) {
    viewer {
      id
      location(id: $locationId) {
        id
        locationId
        otcChecklist
      }
    }
  }
`;

export default OPEN_TO_CLOSE_CHECKLIST;
