import { gql } from '@apollo/client';

const SAVE_FIFTEEN_PLAN_PROFIT_AND_LOSS = gql`
  mutation SAVE_FIFTEEN_PLAN_PROFIT_AND_LOSS($input: SaveFifteenPlanProfitLossMutationInput!) {
    saveFifteenPlanProfitLoss(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_FIFTEEN_PLAN_PROFIT_AND_LOSS;
