import { gql } from '@apollo/client';

const FETCH_MONTHLY_FINANCIALS = gql`
  query FETCH_MONTHLY_FINANCIALS($locationId: ID!, $date: String!) {
    viewer {
      id
      location(id: $locationId) {
        id
        locationId
        fifteenPlan(fifteenPlanDate: $date)
      }
    }
  }
`;

export default FETCH_MONTHLY_FINANCIALS;
