import React, { useCallback, useMemo, useState } from 'react';
import { FETCH_PROFIT_AND_LOSS, LOCATIONS } from '../../../../apollo/queries';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, GridList, GridListTile } from '@material-ui/core';
import PaperMain from '../../../blocks/PaperMain';
import { Typography } from '@material-ui/core';
import { SelectFactory, SideBarSectionsLayout } from '../../../blocks';
import { CurrencyInputFormat, pad } from '../../../../utils';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty, omit, set, snakeCase } from 'lodash-es';
import ProfitAndLossStep from './ProfitAndLossStep';
import { calculateSteps, formatProfitLoss } from './helpers';
import { generateProfitAndLossPdf } from '../../../../utils/pdfUtils';
import {
  SAVE_FIFTEEN_PLAN_PROFIT_AND_LOSS,
  SEND_EMAIL_ATTACHMENT,
} from '../../../../apollo/mutations';
import { SNACKBAR_STATUS } from '../../../../constants';
import { Email, Print } from '@material-ui/icons';

const ProfitAndLossSummary = () => {
  const { setSnackbar } = useSnackbar();

  const [location, setLocation] = useState();
  const [date, setDate] = useState(moment());
  const [stepPaths, setStepPaths] = useState();
  const [steps, setSteps] = useState();

  const { handleSubmit, control, register, setValue } = useForm();

  const { data, loading, error } = useQuery(FETCH_PROFIT_AND_LOSS, {
    skip: !location?.id,
    variables: {
      locationId: location?.id,
      date: date.format('yyyy-MM'),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (!isEmpty(data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps)) {
        const { formatedProfitLoss, paths: newPaths } = formatProfitLoss(
          data.viewer.location.fifteenPlanProfitLoss.profitLossSteps,
        );

        setStepPaths(newPaths);

        setSteps(calculateSteps(formatedProfitLoss, newPaths));
      }
    },
  });

  const [saveFifteenPlanProfitAndLoss] = useMutation(SAVE_FIFTEEN_PLAN_PROFIT_AND_LOSS, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: '15% Plan Profit & Loss Saved',
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
  });

  const [sendEmailAttachment] = useMutation(SEND_EMAIL_ATTACHMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'Email sent',
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
  });

  const tabViewData = useMemo(() => {
    if (!isEmpty(data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps)) {
      return [
        {
          label: '15% Plan - Profit & Loss',
          value: data.viewer.location.fifteenPlanProfitLoss.profitLossSteps[0].id,
          id: `fifteenPlanProfitAndLossStep-${data.viewer.location.fifteenPlanProfitLoss.profitLossSteps[0].id}`,
          sections: [
            ...data.viewer.location.fifteenPlanProfitLoss.profitLossSteps.map(step => ({
              name: step.label,
              id: `fifteenPlanProfitAndLossStep-${step.id}`,
            })),
          ],
        },
      ];
    }
    return null;
  }, [data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps]);

  const handleFieldChange = useCallback(
    (fieldId, value) => {
      setSteps(prevSteps => {
        const updatedSteps = { ...prevSteps };

        set(updatedSteps, fieldId, value);

        return calculateSteps(updatedSteps, stepPaths);
      });
    },
    [stepPaths],
  );

  const tabs = useMemo(() => {
    if (!isEmpty(steps)) {
      return Object.entries(steps).map(([id, step], index) => (
        <GridListTile key={id} id={id}>
          <ProfitAndLossStep
            {...step}
            label={steps[id].label}
            items={omit(steps[id], ['label'])}
            handleFieldChange={handleFieldChange}
            control={control}
            register={register}
            setValue={setValue}
            id={id}
            hasDivider={index !== 0}
          />
        </GridListTile>
      ));
    }
  }, [steps]);

  const handleOnLocationSelect = useCallback(value => {
    setLocation(value);
  }, []);

  const handleOnDateChange = useCallback(newDate => {
    setDate(newDate);
  }, []);

  const handleOnPrintClick = useCallback(() => {
    if (data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps) {
      generateProfitAndLossPdf({
        title: location.label,
        date: data.viewer.location.fifteenPlanProfitLoss.date,
        profitLossSteps: data.viewer.location.fifteenPlanProfitLoss.profitLossSteps,
      });
    }
  }, [data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps, location]);

  const handleOnSendEmailClick = useCallback(() => {
    if (data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps) {
      const pdf = generateProfitAndLossPdf({
        title: location.label,
        date: data.viewer.location.fifteenPlanProfitLoss.date,
        profitLossSteps: data.viewer.location.fifteenPlanProfitLoss.profitLossSteps,
        open: false,
      });

      setSnackbar({
        open: true,
        text: 'Sending email...',
        type: SNACKBAR_STATUS.INFO,
      });

      pdf.getBase64(result => {
        const input = {
          locationId: data.viewer.location.locationId,
          attachment: result,
          subject: `15_Plan_Profit_Loss_${
            data.viewer.location.fifteenPlanProfitLoss.date
          }_${snakeCase(location.label)}`,
          rawHtml:
            '<div>Hello,</div><div>You can find your 15% Profit And Loss results in the attachment</div>',
          attachmentName: `15_Plan_Profit_Loss_${
            data.viewer.location.fifteenPlanProfitLoss.date
          }_${snakeCase(location.label)}`,
          to: 'locationOwners',
        };

        sendEmailAttachment({
          variables: {
            input,
          },
        });
      });
    }
  }, [data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps, location]);

  const onSubmit = useCallback(
    formData => {
      const input = {
        locationId: data?.viewer?.location?.locationId,
        date: data?.viewer?.location?.fifteenPlanProfitLoss?.date,
        profitLoss: [],
      };

      if (data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps) {
        data.viewer.location.fifteenPlanProfitLoss.profitLossSteps.map(step => {
          input.profitLoss.push(
            ...(formData[`fifteenPlanProfitAndLossStep-${step.id}`]
              ? Object.values(formData[`fifteenPlanProfitAndLossStep-${step.id}`]).map(
                  ({ breakEven, fieldId, editable, actual }) => ({
                    breakEven: editable ? CurrencyInputFormat(breakEven || 0) : null,
                    actual: actual,
                    editable: !!editable,
                    id: fieldId,
                    stepId: step.id,
                  }),
                )
              : []),
          );
        });

        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          open: true,
          text: 'Saving 15% Plan Profit & Loss...',
        });

        saveFifteenPlanProfitAndLoss({ variables: { input } });
      }
    },
    [data?.viewer?.location?.fifteenPlanProfitLoss?.profitLossSteps, saveFifteenPlanProfitAndLoss],
  );

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h4">15% Plan - Profit & Loss Summary</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectFactory
            label={'Location'}
            placeholder={'Select Location'}
            query={LOCATIONS}
            renderCheckBox={false}
            multiple={false}
            defaultValue=""
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.id,
                index,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
            onSelect={handleOnLocationSelect}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              views={['month', 'year']}
              style={{ margin: 0 }}
              variant="inline"
              fullWidth
              inputVariant="outlined"
              format="MM/yyyy"
              margin="normal"
              openTo="month"
              label="Month"
              autoOk={true}
              onChange={newDate => {
                handleOnDateChange(newDate);
              }}
              value={date}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        {!loading && location?.id && tabViewData && tabs ? (
          <Grid item xs={12} sm={12} md={4}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnPrintClick}>
                  <Print style={{ marginRight: '6px' }} />
                  Print
                </Button>
              </Grid>
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnSendEmailClick}>
                  <Email style={{ marginRight: '6px' }} />
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {loading || error ? (
          <Grid item xs={12}>
            <div>{loading ? 'Loading...' : 'Error'}</div>
          </Grid>
        ) : null}
        {!loading && location?.id && tabViewData && tabs ? (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SideBarSectionsLayout xs={3} sectionList={tabViewData[0].sections || []}>
                <Grid container style={{ padding: '0 16px' }}>
                  <Grid item xs={12}>
                    <GridList cellHeight={'auto'} cols={1}>
                      {tabs}
                    </GridList>
                  </Grid>

                  <Grid item xs={12} container justify="flex-end">
                    <Grid item>
                      <Button type="submit">Submit</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </SideBarSectionsLayout>
            </form>
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default ProfitAndLossSummary;
