import { gql } from '@apollo/client';

const TOGGLE_ALL_LOCATIONS_ACCESS = gql`
  mutation toggleAllLocationsAccess($usergroupId: ID!, $active: Boolean!) {
    toggleAllLocationsAccess(input: { usergroupId: $usergroupId, active: $active }) {
      usergroupId
    }
  }
`;

export default TOGGLE_ALL_LOCATIONS_ACCESS;
