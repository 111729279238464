import React, { createContext, useState, useMemo, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { CoreContext } from '../../../../CoreContext';
import { RESTAURANTS } from '../../../../apollo/queries';
import { ConnectionTable, Permission, PermissionField } from '../../../blocks';
import { useFormSubmitPermissions } from '../../../../hooks';
import { MUTATION_NAME, paths } from '.././../../../constants';
import { generateStoreAbbreviation, uglifyId, formatConnectionName } from '../../../../utils';
import { DELETE_RESTAURANT } from '../../../../apollo/mutations';
import { useSnackbar } from '../../../../hooks/useSnackbar';

const Restaurants = () => {
  const context = useContext(CoreContext);
  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [connectionVariables, setConnectionVariables] = useState({});

  const mutationPermissions = useMemo(() => context?.coreUtils?.getAppActions()[13]?.mutations, [
    context?.coreUtils,
  ]);

  const [deleteRestaurant] = useMutation(DELETE_RESTAURANT, {
    update: (cache, { data: { deleteRestaurant } }) => {
      const { dynamicConnectionName } = formatConnectionName('restaurant', connectionVariables);
      cache.modify({
        id: cache.identify(deleteRestaurant.viewer),
        fields: {
          [dynamicConnectionName]: existingRestaurantConnection => {
            return {
              ...existingRestaurantConnection,
              totalCount: existingRestaurantConnection.totalCount - 1,
              edges: [
                ...existingRestaurantConnection.edges.filter(
                  restaurant =>
                    restaurant.node.__ref !==
                    `Restaurant:${uglifyId('Restaurant', deleteRestaurant.removedRestaurantId)}`,
                ),
              ],
            };
          },
        },
      });
    },
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Restaurant deleted',
      });
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: error.message,
      });
    },
  });

  const onDeleteConfirmation = (row, vars, refetch) => {
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Deleting restaurant...',
    });

    deleteRestaurant({
      variables: {
        input: { id: row.id },
        ...vars,
      },
    });
  };

  const [permissions, setPermissions] = useState();

  const { accessToMutations } = useFormSubmitPermissions({
    mutationNames: [
      MUTATION_NAME.ToggleLocationMutationInput,
      MUTATION_NAME.CreateLocationMutationInput,
    ],
    permissions,
  });

  const createRestaurantPermission = mutationPermissions.find(
    mutationPermission => mutationPermission.label === 'createRestaurant',
  );

  return (
    <ConnectionTable
      setConnectionVariables={setConnectionVariables}
      query={RESTAURANTS}
      minSearchValueLength={1}
      title="Restaurants"
      initialQueryVariables={{
        first: 15,
        // fieldGroup: 103,
      }}
      onTableRowClick={row => history.push(`${history.location.pathname}/${row.restuarantId}`)}
      structureTable={data => {
        return data?.viewer?.restaurantConnection?.edges.map(({ node: restaurant }) => ({
          id: restaurant.id,
          restuarantId: restaurant.restaurantId,
          label: restaurant.label,
          primaryLocation: generateStoreAbbreviation(restaurant.primaryLocation)?.abbreviation,
        }));
      }}
      columns={[
        {
          title: 'ID',
          field: 'restuarantId',
        },
        { title: 'Name', field: 'label' },
        { title: 'Primary Location Store Number', field: 'primaryLocation' },
      ]}
      handleDeleteRow={onDeleteConfirmation}
      useConfirmationModalForDelete
      deleteConfirmationText="Are you sure you want to delete this restaurant?"
      /* eslint-disable-next-line no-unused-vars */
      customActionsTop={(data, query) => {
        if (!permissions) {
          setPermissions({
            ...(data?.viewer?.locationConnection?.permissions || {}),
            menuId: data?.viewer?.locationConnection?.permissions?.menu?.__typename,
            address: data?.viewer?.locationConnection?.permissions?.address?.address,
            city: data?.viewer?.locationConnection?.permissions?.address?.city,
            stateLabel: data?.viewer?.locationConnection?.permissions?.address?.state?.label,
            zip: data?.viewer?.locationConnection?.permissions?.address?.zip,
            phone: data?.viewer?.locationConnection?.permissions?.phone?.phone,
            timezoneId: data?.viewer?.locationConnection?.permissions?.timezone?.__typename,
            printerPassword: data?.viewer?.locationConnection?.permissions?.printerPassword,
          });
        }

        return (
          <Permission
            access={Math.min(
              createRestaurantPermission?.access,
              accessToMutations?.[MUTATION_NAME.CreateRestaurantMutationInput],
            )}
          >
            <div>
              <PermissionField createHelperText>
                <Link to={paths.LOCATIONS_RESTAURANTS_NEW}>
                  <Button variant="contained">New Restaurant</Button>
                </Link>
              </PermissionField>
            </div>
          </Permission>
        );
      }}
    />
  );
};

export default Restaurants;
