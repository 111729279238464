import { gql } from '@apollo/client';

export const UPDATE_COUPON = gql`
  mutation updateCoupon($input: UpdateCouponMutationInput!) {
    updateCoupon(input: $input) {
      coupon {
        id
        archived
        couponId
        code
        description
        coupontypeId
        label
        created
        isStackable
        maxUses
        usesLeft
        maxUsesPerUser
        timezoneId
        expired
        started
        finished
        excludedOnAllBoostLocations
        couponWeekdayConnection {
          edges {
            node {
              id
              weekdayId
            }
          }
        }
        couponActionConnection {
          edges {
            node {
              couponId
              couponactionId
              valueOff
              target
            }
          }
        }
        couponTriggerConnection {
          edges {
            node {
              id
              coupontrigerId
              valueOff
              valueType
              triggerGroup
            }
          }
        }
        couponItemConnection {
          edges {
            node {
              id
              item {
                id
                label
                itemId
                subcategoryItemConnection {
                  edges {
                    node {
                      id
                      subcategoryId
                      subcategory {
                        categoryId
                        menuId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        couponSubcategoryConnection {
          edges {
            node {
              id
              subcategory {
                id
                subcategoryId
                label
              }
            }
          }
        }
        couponHandoffConnection {
          edges {
            node {
              id
              handoff {
                id
                handoffId
              }
            }
          }
        }
        couponLocationConnection {
          edges {
            node {
              id
              excluded
              location {
                id
                label
                storeNumber
              }
            }
          }
        }
        couponPlatformConnection {
          edges {
            node {
              id
              platformId
            }
          }
        }
        couponBrandConnection {
          edges {
            node {
              id
              brandId
            }
          }
        }
      }
    }
  }
`;
