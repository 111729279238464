import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_MONTHLY_FINANCIALS, LOCATIONS } from '../../../../apollo/queries';
import { useForm } from 'react-hook-form';
import { Button, Grid, GridList, GridListTile } from '@material-ui/core';
import FifteenPlanQuestionGroup from './FifteenPlanQuestionGroup';
import PaperMain from '../../../blocks/PaperMain';
import { Typography } from '@material-ui/core';
import { SelectFactory, SideBarSectionsLayout } from '../../../blocks';
import { CurrencyInputFormat, pad } from '../../../../utils';
import { isEmpty, snakeCase } from 'lodash-es';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { formatData } from '../../helpers';
import moment from 'moment';
import { SAVE_FIFTEEN_PLAN, SEND_EMAIL_ATTACHMENT } from '../../../../apollo/mutations';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { SNACKBAR_STATUS } from '../../../../constants';
import { generateFifteenPlanPdf } from '../../../../utils/pdfUtils';
import { formatFifteenPlanForPdf } from './FifteenPlanQuestionGroup/helpers';
import { additionalQuestions } from './constants';
import { Email, Print } from '@material-ui/icons';

const MonthlyFinancials = () => {
  const { setSnackbar } = useSnackbar();

  const [location, setLocation] = useState();
  const [date, setDate] = useState(moment());

  const { data, loading, error, variables } = useQuery(FETCH_MONTHLY_FINANCIALS, {
    variables: {
      locationId: location?.id,
      date: date.format('yyyy-MM'),
    },
    skip: !location?.id,
    fetchPolicy: 'cache-and-network',
  });

  const [saveFifteenPlan] = useMutation(SAVE_FIFTEEN_PLAN, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'Monthly Financials saved.',
      });
    },
    onError: e => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      });
    },
    refetchQueries: [
      {
        query: FETCH_MONTHLY_FINANCIALS,
        variables,
      },
    ],
  });

  const [sendEmailAttachment] = useMutation(SEND_EMAIL_ATTACHMENT, {
    onCompleted: () => {
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        open: true,
        text: 'Email sent',
      });
    },
    onError: e =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        open: true,
        text: e.message,
      }),
  });

  const {
    control,
    errors,
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    unregister,
  } = useForm();

  const tabViewData = useMemo(() => {
    if (!isEmpty(data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup)) {
      return [
        {
          label: '15% Plan - Enter Monthly Financials',
          value: data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup[0].id,
          id: `fifteenPlanQuestionGroup-${data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup[0].id}`,
          sections: [
            ...data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup.map(group => ({
              name: group.label,
              id: `fifteenPlanQuestionGroup-${group.id}`,
            })),
          ],
        },
      ];
    }
    return null;
  }, [data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup]);

  const tabs = useMemo(() => {
    if (!isEmpty(data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup)) {
      return data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup.map((group, index) => (
        <GridListTile
          key={`fifteenPlanQuestionGroup-${group.id}`}
          id={`fifteenPlanQuestionGroup-${group.id}`}
        >
          <FifteenPlanQuestionGroup
            {...group}
            id={`fifteenPlanQuestionGroup-${group.id}`}
            control={control}
            errors={errors}
            register={register}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            unregister={unregister}
            additionalQuestions={
              data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup.length === index + 1
                ? additionalQuestions.map(question => ({
                    ...question,
                    defaultValue: [0, 1].includes(data.viewer.location.fifteenPlan[question.name])
                      ? data.viewer.location.fifteenPlan[question.name]
                      : '',
                  }))
                : undefined
            }
          />
        </GridListTile>
      ));
    }

    return [];
  }, [data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup, errors]);

  const handleOnLocationSelect = useCallback(value => {
    setLocation(value);
  }, []);

  const handleOnDateChange = useCallback(newDate => {
    setDate(newDate);
  }, []);

  const onSubmit = useCallback(
    formData => {
      if (data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup) {
        const input = {
          locationId: data.viewer.location.locationId,
          date: data.viewer.location.fifteenPlan.date,
          rentCurrent: !!formData.rentCurrent,
          taxCurrent: !!formData.taxCurrent,
          accountCurrent: !!formData.accountCurrent,
          fifteenPlanQuestion: [],
        };

        data.viewer.location.fifteenPlan.fifteenPlanQuestionGroup.map(group => {
          formatData({
            label: `fifteenPlanQuestionGroup-${group.id}-fifteenPlanQuestionItem-group`,
            formData,
            mapFunction: ({ value, questionId, fifteenPlanQuestionTypeId }) => {
              if (fifteenPlanQuestionTypeId === 1) {
                input.fifteenPlanQuestion.push({
                  value: value !== '' ? CurrencyInputFormat(value) : null,
                  id: questionId,
                  fifteenPlanQuestionTypeId,
                });
              } else if (fifteenPlanQuestionTypeId === 2) {
                input.fifteenPlanQuestion.push(
                  ...formatData({
                    formData,
                    label: `fifteenPlanQuestionGroup-${group.id}-fifteenPlanQuestionItem-item-${questionId}`,
                    mapFunction: ({ value, date, vendor, invoice }) => ({
                      id: questionId,
                      fifteenPlanQuestionTypeId,
                      value: value !== '' ? CurrencyInputFormat(value) : null,
                      date: date ? moment(date).format('yyyy-MM-DD') : null,
                      vendor,
                      invoice,
                    }),
                  }),
                );
              }
            },
          });
        });

        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          open: true,
          text: 'Saving Monthly Financials...',
        });

        saveFifteenPlan({ variables: { input } });
      }
    },
    [data?.viewer?.location?.fifteenPlan?.fifteenPlanQuestionGroup],
  );

  const handleOnPrintClick = useCallback(() => {
    if (data?.viewer?.location?.fifteenPlan) {
      generateFifteenPlanPdf(
        formatFifteenPlanForPdf({
          locationLabel: location.label,
          fifteenPlan: data.viewer.location.fifteenPlan,
        }),
      );
    }
  }, [data?.viewer?.location?.fifteenPlan, location]);

  const handleOnSendEmailClick = useCallback(() => {
    if (data?.viewer?.location?.fifteenPlan) {
      const pdf = generateFifteenPlanPdf(
        formatFifteenPlanForPdf({
          locationLabel: location.label,
          fifteenPlan: data.viewer.location.fifteenPlan,
          open: false,
        }),
      );

      setSnackbar({
        open: true,
        text: 'Sending email...',
        type: SNACKBAR_STATUS.INFO,
      });

      pdf.getBase64(result => {
        const input = {
          locationId: data.viewer.location.locationId,
          attachment: result,
          subject: `15_Plan_Monthly_Financials_${data.viewer.location.fifteenPlan.date}_${snakeCase(
            location.label,
          )}`,
          rawHtml:
            '<div>Hello,</div><div>You can find your 15% Plan - Monthly Financials results in the attachment</div>',
          attachmentName: `15_Plan_Monthly_Financials_${
            data.viewer.location.fifteenPlan.date
          }_${snakeCase(location.label)}`,
          to: 'locationOwners',
        };

        sendEmailAttachment({
          variables: {
            input,
          },
        });
      });
    }
  }, [data?.viewer?.location?.fifteenPlan, location]);

  return (
    <PaperMain>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">15% Plan - Monthly Financials</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectFactory
            label={'Location'}
            placeholder={'Select Location'}
            query={LOCATIONS}
            renderCheckBox={false}
            multiple={false}
            defaultValue=""
            structureData={data =>
              data?.viewer?.locationConnection?.edges?.map((edge, index) => ({
                value: edge.node.locationId,
                id: edge.node.id,
                index,
                label: `${edge.node?.address?.state?.abbreviation}-${pad(
                  edge.node?.storeNumber || 0,
                  4,
                  '0',
                )} - ${edge.node.label}`,
              }))
            }
            onSelect={handleOnLocationSelect}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              views={['month', 'year']}
              style={{ margin: 0 }}
              variant="inline"
              fullWidth
              inputVariant="outlined"
              format="MM/yyyy"
              margin="normal"
              openTo="month"
              label="Month"
              autoOk={true}
              onChange={newDate => {
                handleOnDateChange(newDate);
              }}
              value={date}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        {!loading && location?.id && tabViewData ? (
          <Grid item xs={12} sm={12} md={4}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnPrintClick}>
                  <Print style={{ marginRight: '6px' }} />
                  Print
                </Button>
              </Grid>
              <Grid item>
                <Button style={{ minWidth: '150px' }} onClick={handleOnSendEmailClick}>
                  <Email style={{ marginRight: '6px' }} />
                  Send Email
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {loading || error ? (
          <Grid item xs={12}>
            <div>{loading ? 'Loading...' : 'Error'}</div>
          </Grid>
        ) : null}
        {!loading && location?.id && tabViewData ? (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SideBarSectionsLayout xs={3} sectionList={tabViewData[0].sections || []}>
                <Grid container style={{ padding: '0 16px' }}>
                  <Grid item xs={12}>
                    <GridList cellHeight={'auto'} cols={1}>
                      {tabs}
                    </GridList>
                  </Grid>

                  <Grid item xs={12} container justify="flex-end">
                    <Grid item>
                      <Button type="submit">Submit</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </SideBarSectionsLayout>
            </form>
          </Grid>
        ) : null}
      </Grid>
    </PaperMain>
  );
};

export default MonthlyFinancials;
