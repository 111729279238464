import { gql } from '@apollo/client';

const FETCH_RESTAURANT = gql`
  query fetchRestaurant($id: ID!) {
    viewer {
      id
      restaurant(id: $id) {
        permission
        id
        restaurantId
        label
        description
        primaryLocation {
          id
          label
          storeNumber
          address {
            id
            state {
              id
              abbreviation
            }
          }
          brand {
            id
            label
          }
        }
        locationConnection {
          edges {
            node {
              id
              label
              storeNumber
              address {
                id
                state {
                  id
                  abbreviation
                  label
                }
              }
              brand {
                id
                brandId
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default FETCH_RESTAURANT;
