import { gql } from '@apollo/client';

const SAVE_FIFTEEN_PLAN = gql`
  mutation SAVE_FIFTEEN_PLAN($input: SaveFifteenPlanMutationInput!) {
    saveFifteenPlan(input: $input) {
      clientMutationId
    }
  }
`;

export default SAVE_FIFTEEN_PLAN;
