import { Hidden, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import US_FOODS_INVOICES from '../../../../../apollo/queries/inventoryInvoices/usFoodsInvoices';
import { INVOICE_ICON, paths } from '../../../../../constants';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import { renderIcon, getButtons, BUTTON_NAME } from '../renderers';
import moment from 'moment';
import MobileButton from '../../../../blocks/Mobile/MobileButton';
import { Modal } from '../../../../blocks';

const buttons = getButtons([
  { buttonName: BUTTON_NAME.TASK_LIST },
  { buttonName: BUTTON_NAME.LOCATIONS },
]);

const InvoiceJournal = () => {
  const { push } = useHistory();
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];
  const storeNumber = ids.split('-')[1];

  const [openModal, setOpenModal] = useState(false);

  const handleTableRowClick = useCallback(row => {
    push(
      paths.COREOPS_INVENTORY_LOCATION_INVOICE_SUMMARY.replace(':locationId', `${ids}`).replace(
        ':invoiceId',
        row.invoiceId,
      ),
    );
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <>
      <ConnectionTable
        title="Invoice Journal"
        connectionName="invoiceConnection"
        headerHidden={'xs'}
        onTableRowClick={handleTableRowClick}
        customActionsTop={() => (
          <>
            <Hidden only="xs">{buttons}</Hidden>
            <Hidden smUp>
              <MobileButton style={{ cursor: 'pointer' }} onClick={handleOpen} />
            </Hidden>
          </>
        )}
        columns={[
          {
            title: '',
            field: 'isSimple',
            render: isSimple =>
              renderIcon(isSimple ? INVOICE_ICON.SIMPLE_INVOICE : INVOICE_ICON.US_FOODS),
            enablePropagation: true,
          },
          {
            title: 'Name',
            field: 'invoiceNumber',
            enablePropagation: true,
            render: (invoiceNumber, _, row) => (
              <>
                <Hidden smUp>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ flexGrow: 1, marginRight: '16px' }}>
                      <Typography variant="body1">Invoice# {invoiceNumber}</Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <Typography variant="caption" style={{ color: '#747480' }}>
                            Submitted {moment(row.invoiceDate).format('MM/DD/YYYY z')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ArrowForwardIos style={{ fontSize: '12px', color: '#595959' }} />
                    </div>
                  </div>
                </Hidden>
                <Hidden only="xs">
                  Invoice# {invoiceNumber} {row.subtotal > 0 ? '' : ' - RETURN'}
                </Hidden>
              </>
            ),
          },
          {
            title: 'Submitted',
            enablePropagation: true,
            field: 'invoiceDate',
            hidden: 'xs',
            render: invoiceDate => moment(invoiceDate).format('MM/DD/YYYY z'),
          },
        ]}
        structureTable={data => data?.viewer?.invoiceConnection?.edges?.map(edge => edge.node)}
        initialQueryVariables={{
          filter: {
            storeNumber: { eq: +storeNumber },
            isSubmitted: { eq: true },
          },
          sort: {
            invoiceDate: 'DESC',
          },
          storeNumber: +storeNumber,
          first: 10,
        }}
        query={US_FOODS_INVOICES}
      />
      <Hidden smUp>
        <Modal open={openModal} handleClose={handleClose} title="Options" maxWidth="sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {buttons}
          </div>
        </Modal>
      </Hidden>
    </>
  );
};

export default InvoiceJournal;
