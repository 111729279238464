import { TextField, FormControl, Typography, InputLabel, Grid, Divider } from '@material-ui/core';
import React, { useMemo } from 'react';
import FormItems from '../../../../blocks/FormItems';
import ChecklistItem from './ChecklistItem';

// 1 - Bool
// 4 - textfield
// notes na kraju textarea

const OpenToCloseChecklist = ({
  id,
  label,
  note,
  description,
  otcChecklistField,
  control,
  errors,
  register,
  setValue,
  getValues,
  unregister,
}) => {
  const items = useMemo(
    () =>
      otcChecklistField?.map(item => ({
        ...item,
        fieldId: item.id,
      })),
    [otcChecklistField],
  );

  return (
    <>
      <FormItems
        title={label}
        additionalHeaderComponent={
          description ? <Typography variant="h5">{description}</Typography> : null
        }
        disableAddItem={true}
        name={`${id}-otcChecklistField`}
        items={items}
        setValue={setValue}
        getValues={getValues}
        unregister={unregister}
        control={control}
        register={register}
        errors={errors}
        itemComponent={ChecklistItem}
        enableEmpty={true}
        dividerBetweenItems={false}
        dividerAfterItems={false}
      />
      <Grid container spacing={2} key={`checkinput-${id}`} style={{ marginBottom: '14px' }}>
        <Grid item xs={12}>
          <InputLabel>Note</InputLabel>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              fullWidth
              inputRef={register()}
              name={`${id}.note`}
              id={`${id}.note`}
              rows={4}
              multiline
              defaultValue={note || ''}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '32px' }} />
    </>
  );
};

export default OpenToCloseChecklist;
