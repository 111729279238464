import { gql } from '@apollo/client';

const CREATE_HOUSE_ACCOUNT_PAYMENT = gql`
  mutation createHouseAccountDeposit(
    $houseaccountId: ID!
    $tenderId: ID!
    $amount: Int!
    $source: String
    $gatewayId: ID
    $referenceNumber: String
    $giftCardNumber: String
    $confirmPaymentIntentId: String
  ) {
    createHouseAccountDeposit(
      input: {
        houseaccountId: $houseaccountId
        tenderId: $tenderId
        amount: $amount
        source: $source
        gatewayId: $gatewayId
        referenceNumber: $referenceNumber
        giftCardNumber: $giftCardNumber
        confirmPaymentIntentId: $confirmPaymentIntentId
      }
    ) {
      depositEdge {
        node {
          id
          depositId
        }
      }
      houseaccount {
        id
        houseaccountId
        balance
        limit
        locationId
      }
      viewer {
        id
      }
      paidAmount
      requiresAction {
        paymentIntentId
        paymentIntentClientSecret
      }
    }
  }
`;

export default CREATE_HOUSE_ACCOUNT_PAYMENT;
