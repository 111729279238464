import { Button, IconButton, Hidden, Grid, Typography } from '@material-ui/core';
import { ArrowForwardIos, Print } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { INVOICE_ICON, paths, SNACKBAR_STATUS } from '../../../../../constants';
import { Modal } from '../../../../blocks';
import ConnectionTable from '../../../../blocks/ConnectionTable';
import MobileButton from '../../../../blocks/Mobile/MobileButton';
import { BUTTON_NAME, getButtons, renderIcon } from '../renderers';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PaperMain from '../../../../blocks/PaperMain';
import { useLazyQuery } from '@apollo/client';
import { FETCH_LOCATION_STORAGES } from '../../../../../apollo/queries';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { printCountSheet } from './helpers';
import { FETCH_LOCATION_COUNT_SHEET } from '../../../../../apollo/queries/locations/fetchCountSheets';

const buttons = getButtons([
  { buttonName: BUTTON_NAME.TASK_LIST },
  { buttonName: BUTTON_NAME.LOCATIONS },
]);

const StorageAreas = () => {
  let { locationId: ids } = useParams();
  const locationId = ids.split('-')[0];

  const { push } = useHistory();
  const { setSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);

  const [fetchLocationStorage, { loading }] = useLazyQuery(FETCH_LOCATION_STORAGES, {
    onCompleted: data => printCountSheet(data),
    onError: err =>
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: err.message,
        open: true,
      }),
    fetchPolicy: 'network-only',
  });

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleTableRowClick = useCallback(
    row => {
      push(
        paths.COREOPS_INVENTORY_LOCATION_STORAGE_AREAS_STORAGE_ID.replace(
          ':locationId',
          ids,
        ).replace(':locationStorageId', row.locationStorageId),
      );
    },
    [locationId],
  );

  const handlePrintCountSheet = useCallback(
    locationStorageId => {
      setSnackbar({
        type: SNACKBAR_STATUS.INFO,
        text: 'Generating Count Sheet...',
        open: true,
      });

      fetchLocationStorage({
        variables: {
          first: 1,
          filter: {
            locationId: { eq: locationId },
            locationStorageId: { eq: locationStorageId },
          },
        },
      });
    },
    [locationId],
  );

  const handleOnPrintAllCountSheetsClick = useCallback(() => {
    fetchLocationStorage({
      variables: {
        first: 2147483647,
        filter: {
          locationId: { eq: locationId },
        },
      },
    });
  }, [locationId]);

  return (
    <PaperMain>
      <ConnectionTable
        nested
        title="Storage Areas"
        subtitle="Select a Storage Area"
        showSearch={false}
        headerHidden={'xs'}
        onTableRowClick={handleTableRowClick}
        customActionsTop={() => (
          <>
            <Hidden only="xs">{buttons}</Hidden>
            <Hidden smUp>
              <MobileButton style={{ cursor: 'pointer' }} onClick={handleOpen} />
            </Hidden>
          </>
        )}
        customActionsCenter={() => (
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                disabled={loading}
                variant="outlined"
                onClick={handleOnPrintAllCountSheetsClick}
              >
                Print all Count Sheets
              </Button>
            </Grid>
          </Grid>
        )}
        customActionsBottom={data => {
          const showButton =
            data?.viewer?.locationStorageConnection?.edges.length &&
            !data?.viewer?.locationStorageConnection?.edges?.find(
              edge => edge.node.storagestatusId !== 'DONE',
            );

          return showButton ? (
            <Grid container justify="flex-end">
              <Grid item>
                <Link to={'storage-areas/summary'}>
                  <Button>Submit Inventory Count</Button>
                </Link>
              </Grid>
            </Grid>
          ) : null;
        }}
        columns={[
          {
            title: '',
            field: 'type',
            render: () => renderIcon(INVOICE_ICON.FULL_INVENTORY_COUNT), // TODO: icons
            enablePropagation: true,
          },
          {
            title: 'Name',
            field: 'customLabel',
            enablePropagation: true,
            render: (customLabel, _, row) => customLabel || row.storage?.label,
          },
          {
            title: 'Status',
            enablePropagation: true,
            field: 'storagestatusId',
            render: status => (
              <Grid container spacing={3} justify="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ color: status === 'DONE' ? '#009A5C' : '#FFA200' }}
                  >
                    <strong>{status}</strong>
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item>
                    <ArrowForwardIos style={{ fontSize: '12px', color: '#595959' }} />
                  </Grid>
                </Hidden>
              </Grid>
            ),
          },
          {
            title: 'Print',
            field: 'locationStorageId',
            cellStyle: { padding: 0 },
            render: locationStorageId => (
              <IconButton
                disabled={loading}
                onClick={() => handlePrintCountSheet(locationStorageId)}
              >
                <Print />
              </IconButton>
            ),
          },
        ]}
        structureTable={data =>
          data?.viewer?.locationStorageConnection?.edges?.map(edge => edge.node)
        }
        initialQueryVariables={{
          locationId: locationId,
          locationStorageFilter: {
            status: { eq: 1 },
            locationId: { eq: locationId },
          },
          shouldSkip: true
        }}
        query={FETCH_LOCATION_COUNT_SHEET}
      />
      <Hidden smUp>
        <Modal open={openModal} handleClose={handleClose} title="Options" maxWidth="sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {buttons}
          </div>
        </Modal>
      </Hidden>
    </PaperMain>
  );
};

export default StorageAreas;
