import { gql } from '@apollo/client';

const FETCH_PROFIT_AND_LOSS = gql`
  query FETCH_PROFIT_AND_LOSS($locationId: ID!, $date: String!) {
    viewer {
      id
      location(id: $locationId) {
        id
        locationId
        fifteenPlanProfitLoss(fifteenPlanProfitLossDate: $date)
      }
    }
  }
`;

export default FETCH_PROFIT_AND_LOSS;
