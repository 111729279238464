import { FormControl, Grid, InputLabel, Typography, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { COLOR } from '../../../../../../constants';

const ChecklistItem = ({ item, setValue, register, id, errors, control }) => {
  useEffect(() => {
    register(`${id}.fieldId`);
    setValue(`${id}.fieldId`, item.fieldId);
  }, [id, item.fieldId]);

  const handleOnCheckboxClick = useCallback(
    (value, prevValue) => {
      setValue(`${id}.value`, value !== prevValue ? value : '');
    },
    [id],
  );

  return (
    <Grid container spacing={3} key={`checkinput-${id}`} style={{ marginBottom: '14px' }}>
      <Grid item xs={12} direction="column">
        <InputLabel error={!!errors[id]}>{item.label}</InputLabel>
        <Typography variant="caption">{item.description}</Typography>
      </Grid>
      {item.otcChecklistFieldTypeId === 1 ? (
        <Grid item xs={12} justify="center">
          <FormControl variant="outlined">
            <Controller
              name={`${id}.value`}
              id={`${id}.value`}
              label={item.label}
              defaultValue={['0', '1'].includes(item.value) ? item.value : ''}
              render={({ value }) => (
                <ToggleButtonGroup value={value} id={`${id}.value`} name={`${id}.value`} exclusive>
                  <ToggleButton
                    color="primary"
                    style={
                      value === '1'
                        ? {
                            color: COLOR.SUCCESS,
                            border: `1px solid ${COLOR.SUCCESS}`,
                            background: '#38c68a33',
                            fontWeight: 'bold',
                          }
                        : undefined
                    }
                    onClick={() => handleOnCheckboxClick('1', value)}
                    value={1}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    color="error"
                    style={
                      value === '0'
                        ? {
                            color: COLOR.ERROR,
                            border: `1px solid ${COLOR.ERROR}`,
                            fontWeight: 'bold',
                            background: '#d40e3733',
                          }
                        : undefined
                    }
                    value={'0'}
                    onClick={() => handleOnCheckboxClick('0', value)}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              control={control}
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} justify="center">
          <TextField
            fullWidth
            inputRef={register()}
            name={`${id}.value`}
            id={`${id}.value`}
            defaultValue={item.value || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ChecklistItem;
