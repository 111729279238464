import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import logo from '../../assets/images/logo2.png';
import convert from '../convert';

function createContent({ date, profitLossSteps = [] }) {
  return [
    {
      layout: 'customLayout',
      style: 'table',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'General Info',
              style: 'paragraphTitle',
            },
          ],
          [
            {
              text: [
                { text: 'Date:  ', style: 'note' },
                { style: 'input', text: date },
              ],
            },
          ],
        ],
      },
    },
    ...profitLossSteps.map(({ label, profitLossFields = [] }) => [
      {
        layout: 'customLayout',
        style: 'table',
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: label,
                style: 'paragraphTitle',
              },
            ],
          ],
        },
      },
      {
        layout: 'customLayoutTable',
        style: 'table',
        table: {
          widths: [120, 100, 100, 100, 100],
          headerRows: 1,
          body: [
            [
              {},
              {
                text: 'Break-Even 15% Plan',
                style: 'headerTable',
              },
              {
                text: 'Actual 15% Plan',
                style: 'headerTable',
              },
              {
                text: 'Percentage Of Revenue',
                style: 'headerTable',
              },
              {
                text: 'Variance',
                style: 'headerTable',
              },
            ],
            ...profitLossFields?.map(field => [
              {
                text: field.label,
                style: 'headerTable',
              },
              {
                text: convert.formattedCurrency(field.breakEven * 100),
              },
              {
                text: convert.formattedCurrency(field.actual * 100),
              },
              {
                text: `${convert.centsToDollars(field.percentageOfRevenue * 100)}%`,
              },
              {
                text: convert.formattedCurrency(field.variance * 100),
              },
            ]),
          ],
        },
      },
    ]),
  ];
}

function getDocDefition({ title, date, profitLossSteps }) {
  const content = createContent({ date, profitLossSteps });

  return {
    header: {
      margin: 12,
      layout: 'noBorders',
      style: 'headerTable',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { image: 'logo', width: 100, height: 55 },
            {
              stack: [
                { style: 'header', text: title },
                { style: 'header', text: '15% Plan - Profit & Loss' },
              ],
            },
          ],
        ],
      },
    },
    footer: function(currentPage, pageCount) {
      return {
        style: 'footer',
        columns: [
          {
            alignment: 'center',
            text: `${currentPage.toString()} of ${pageCount}`,
          },
        ],
        margin: [12, 0, 12, 12],
      };
    },

    content: content,

    styles: {
      paragraphTitle: {
        bold: true,
        fontSize: 14,
      },
      headerTable: {
        bold: true,
        fontSize: 12,
      },
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      footer: {
        fontSize: 10,
        color: '#666666',
      },
      note: {
        color: '#666666',
      },
      input: {
        color: '#3B3A43',
      },
      table: {
        margin: [0, 0, 0, 24],
      },
    },
    defaultStyle: {
      color: '#3B3A43',
      fontSize: 12,
    },
    pageMargins: [12, 90, 12, 24],
    images: {
      logo,
    },
  };
}

const generateProfitAndLossPdf = ({ title = '', date, profitLossSteps, open = true }) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinition = getDocDefition({
    title,
    date,
    profitLossSteps,
  });

  pdfMake.tableLayouts = {
    customLayout: {
      hLineWidth: function(i) {
        return i === 1 ? 1 : 0;
      },
      vLineWidth: function() {
        return 0;
      },
      hLineColor: function() {
        return '#ffcb02';
      },
      paddingBottom: function() {
        return 6;
      },
    },
    customLayoutTable: {
      hLineWidth: function() {
        return 1;
      },
      vLineWidth: function() {
        return 1;
      },
      paddingBottom: function() {
        return 6;
      },
    },
  };

  if (open) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    return pdfMake.createPdf(docDefinition);
  }
};

export default generateProfitAndLossPdf;
