import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  gridList: {
    width: '100%',
    padding: '0 20px',
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  nameField: {
    marginRight: theme.spacing(4),
    marginBottom: '10px',
  },
  marginRight: {
    marginRight: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  basicInfoRadioWrap: { display: 'flex', flexDirection: 'row' },
  basicInfoRadio: { justifyContent: 'flex-start', marginTop: '11px', padding: '0 5px' },
  cancelBtn: {
    margin: '1rem',
  },
});

const CreateRestaurantStyles = makeStyles(Styles);

export default CreateRestaurantStyles;
