import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  Paper,
  FormControl,
  Grid,
  GridListTile,
  GridList,
  Button,
  TextField,
} from '@material-ui/core';

import CreateRestaurantStyles from './styles';
import { CREATE_RESTAURANT } from '../../../../../apollo/mutations';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { paths } from '../../../../../constants';
import { SelectFactory } from '../../../../blocks';
import { LOCATIONS } from '../../../../../apollo/queries';
import { generateStoreAbbreviation } from '../../../../../utils';

const CreateRestaurant = () => {
  const classes = CreateRestaurantStyles();

  const history = useHistory();
  const { setSnackbar } = useSnackbar();

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState(null);
  const [locations, setLocations] = useState([]);

  const [createRestaurant] = useMutation(CREATE_RESTAURANT, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Restaurant created!',
      });
      setDisableCreateButton(false);

      history.push(
        `${paths.LOCATIONS_RESTAURANTS}/${data?.createRestaurant.restaurant.restaurantId}`,
      );
    },
    onError: err => {
      setDisableCreateButton(false);

      setSnackbar({
        open: true,
        type: 'error',
        text: err.message || 'Oops, something went wrong...',
      });
    },
  });

  const { control, handleSubmit, errors, register } = useForm({
    defaultValues: {
      label: '',
      description: '',
      primaryLocationId: null,
      locations: [],
    },
  });

  const submit = restaurant => {
    setDisableCreateButton(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Creating restaurant...',
    });

    createRestaurant({
      variables: {
        input: {
          ...restaurant,
          primaryLocationId: primaryLocation.id,
          locationIds: locations.map(location => location.id),
        },
      },
    });
  };

  const locationsFilter = useMemo(() => {
    if (!locations.length) {
      return null;
    }

    return {
      brand: {
        brandId: {
          nin: locations.map(location => location?.brandId),
        },
      },
    };
  }, [locations]);

  return (
    <Paper>
      <form onSubmit={handleSubmit(submit)}>
        <GridList className={classes.gridList} cellHeight={'auto'} cols={1}>
          <GridListTile>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h1 className={classes.heading}>New Restaurant</h1>
              <div>
                <Link to={paths.LOCATIONS_RESTAURANTS}>
                  <Button
                    disabled={disableCreateButton}
                    variant="outlined"
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button disabled={disableCreateButton} type="submit">
                  CREATE
                </Button>
              </div>
            </div>
          </GridListTile>

          <Grid container className={`${classes.marginBottom} ${classes.marginTop}`}>
            <Grid container direction="column" item md={12}>
              <FormControl className={classes.nameField} component="fieldset">
                <Controller
                  control={control}
                  name="label"
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty },
                  ) => (
                    <TextField
                      onChange={e => onChange(e.target.value)}
                      inputRef={register({ required: true })}
                      name={name}
                      label="Name"
                      fullWidth
                      placeholder="Restaurant Name"
                      error={errors?.label}
                      helperText={errors?.label ? 'Label cannot be empty' : errors?.label?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={classes.marginBottom}>
            <Grid container direction="column" item md={12}>
              <FormControl className={classes.nameField} component="fieldset">
                <Controller
                  control={control}
                  name="description"
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty },
                  ) => (
                    <TextField
                      rows={4}
                      multiline
                      onChange={e => onChange(e.target.value)}
                      inputRef={ref}
                      fullWidth
                      placeholder="Description"
                      label="Description"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={classes.marginBottom}>
            <Grid container direction="column" item md={12}>
              <FormControl className={classes.nameField} component="fieldset">
                <Controller
                  control={control}
                  name="description"
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty },
                  ) => (
                    <SelectFactory
                      id="primaryLocation"
                      name="Primary Location"
                      label={'Primary Location'}
                      placeholder={'Primary Location'}
                      query={LOCATIONS}
                      multiple={false}
                      filter={{
                        restaurantId: {
                          null: true,
                        },
                      }}
                      error={!!errors?.primaryLocation}
                      disableCloseOnSelect={true}
                      structureData={data => {
                        return (
                          data?.viewer?.locationConnection?.edges.map((location, index) => {
                            return {
                              id: location?.node?.id,
                              label: `${generateStoreAbbreviation(location.node).abbreviation} - ${
                                location?.node?.label
                              }`,
                              brandId: location?.node?.brand?.brandId,
                              index,
                            };
                          }) || []
                        );
                      }}
                      onSelect={value => {
                        setPrimaryLocation(value);

                        const primaryLocationIsInLocations = Boolean(
                          locations.filter(location => location.id === value.id).length,
                        );

                        if (!primaryLocationIsInLocations) {
                          setLocations([...locations, value]);
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={classes.marginBottom}>
            <Grid container direction="column" item md={12}>
              <FormControl className={classes.nameField} component="fieldset">
                <Controller
                  control={control}
                  name="description"
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty },
                  ) => (
                    <SelectFactory
                      id="locations"
                      name="Locations"
                      label={'Locations'}
                      placeholder={'Locations'}
                      query={LOCATIONS}
                      error={!!errors?.locations}
                      disableCloseOnSelect={true}
                      value={locations}
                      filter={locationsFilter}
                      structureData={data => {
                        return (
                          data?.viewer?.locationConnection?.edges.map((location, index) => {
                            return {
                              id: location?.node?.id,
                              label: `${generateStoreAbbreviation(location?.node).abbreviation} - ${
                                location?.node?.label
                              }`,
                              brandId: location?.node?.brand?.brandId,
                              index,
                            };
                          }) || []
                        );
                      }}
                      onSelect={value => {
                        setLocations(value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </GridList>
      </form>
    </Paper>
  );
};

export default CreateRestaurant;
