import { gql } from '@apollo/client';
import { timezoneFragment } from '../../fragments/timezoneFragment';
import { taxGroupFragment } from '../../fragments/taxGroupFragment';
import { locationAddressFragment, stateFragment } from '../../fragments';
import menuFragment from '../../fragments/menuFragment';

const UPDATE_LOCATION_INFO = gql`
  mutation UpdateLocation($input: UpdateLocationMutationInput!, $locationId: ID!) {
    updateLocation(input: $input) {
      viewer {
        id
        location(id: $locationId) {
          brand {
            id
            brandId
            label
            description
            abbreviation
          }
          payrollPeriodId
          payrollPeriod {
            id
            label
          }
          legalBusinessName
          tierId
          id
          locationId
          slug
          label
          locationPicture
          locationInfo
          menuId
          menu {
            ...MenuData
          }
          cateringInfo
          workWeekStartId
          receiptFooter
          printerPassword
          email
          opened
          closed
          phone {
            id
            phone
          }
          timezone {
            ...TimezoneData
          }
          squareLocationId
          taxgroup {
            ...TaxGroupData
          }
          storeNumber
          address {
            ...LocationAddressData
          }
          automaticTracker
          hygieneChitReminder
        }
      }
    }
  }
  ${menuFragment.menu}
  ${taxGroupFragment.taxGroup}
  ${locationAddressFragment.address}
  ${stateFragment.state}
  ${timezoneFragment.timezone}
`;

export default UPDATE_LOCATION_INFO;
