import { makeStyles } from '@material-ui/core';

const Styles = theme => ({
  gridList: {
    width: '100%',
    padding: '0 20px',
  },
  marginBottom: {
    margin: '0 0 1rem 0',
  },
  cancelBtn: {
    marginRight: '0.5rem',
  },
});

const RestaurantStyles = makeStyles(Styles);

export default RestaurantStyles;
