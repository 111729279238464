import { FormLabel, Grid, Typography, Button, Divider } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { formatData, getId } from '../../modules/helpers';
import FormItem from './FormItem';

const FormItems = ({
  title,
  disableAddItem = false,
  addItemOnBottom = false,
  addItemButtonText = 'Add Item',
  addItemComponent: AddItemComponent = null,
  addItemComponentProps = {},
  name = 'item',
  items: propItems = [],
  initialItem = {},
  titleVariant,
  setValue,
  getValues,
  unregister,
  additionalHeaderComponent,
  control,
  itemComponentProps,
  register,
  errors,
  itemComponent,
  enableEmpty = true,
  emptyErrorText = 'At least one item is required',
  onSelect,
  dividerBetweenItems = false,
  dividerAfterItems = true,
  setItems: propsSetItems,
  enableRemove = false,
}) => {
  const [items, setItems] = useState(
    propItems?.map((item, index) => ({
      ...item,
      id: getId(`${name}-${index}`),
    })) || [],
  );
  const [autoFocusId, setAutofocusId] = useState(-1);

  const onAddItemClick = useCallback(
    (itemToAdd = initialItem) => {
      if (onSelect) {
        onSelect(itemToAdd);
      }

      const updatedFormData = formatData({
        formData: getValues(),
        label: `${name}-`,
      });

      const newItems = [
        ...items.map(item => ({
          ...item,
          ...updatedFormData.find(formItem => formItem.id === item.id),
        })),
        { ...itemToAdd, id: getId(name) },
      ];

      if (propsSetItems) {
        propsSetItems(newItems);
      }

      setItems(newItems);
      setAutofocusId(items.length);
    },
    [items, propsSetItems, initialItem],
  );

  const onRemoveItemClick = useCallback(
    idToRemove => {
      const updatedFormData = formatData({
        formData: getValues(),
        label: `${name}-`,
      });

      const filteredItems = items
        .filter(item => item.id !== idToRemove)
        .map(item => ({
          ...item,
          ...updatedFormData.find(prevItem => prevItem.id === item.id),
        }));

      if (propsSetItems) {
        propsSetItems(filteredItems);
      }

      setItems(filteredItems);
      setAutofocusId(-1);
    },
    [items, propsSetItems, getValues],
  );

  return (
    <Grid container spacing={3}>
      <Grid container xs={12} item spacing={3} alignItems="center" justify="space-between">
        {title ? (
          <Grid item direction="column" spacing={3} container>
            <Grid item xs={12}>
              <Typography
                variant={titleVariant || 'h4'}
                style={!titleVariant ? { fontSize: '1.25rem' } : undefined}
              >
                {title}
              </Typography>
            </Grid>
            {additionalHeaderComponent && (
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                {additionalHeaderComponent}
              </Grid>
            )}
          </Grid>
        ) : null}

        {addItemOnBottom || disableAddItem || AddItemComponent ? null : (
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => onAddItemClick()}
              style={{
                marginBottom: '12px',
              }}
            >
              {addItemButtonText}
            </Button>
          </Grid>
        )}
      </Grid>

      {items?.length ? (
        <>
          <Grid item xs={12} style={{ marginBottom: '12px' }} container direction="column">
            {items.filter(i => !i.itemId).map((item, index) => (
              <div key={item.id}>
                {dividerBetweenItems && index !== 0 ? <Divider /> : null}
                <FormItem
                  register={register}
                  item={item}
                  id={item.id}
                  unregister={unregister}
                  errors={errors}
                  getValues={getValues}
                  control={control}
                  onRemoveItem={onRemoveItemClick}
                  setValue={setValue}
                  setAutofocusId={setAutofocusId}
                  autoFocus={index === autoFocusId}
                  itemComponent={itemComponent}
                  itemComponentProps={itemComponentProps}
                  showRemoveItemButton={enableRemove}
                />
              </div>
            ))}
          </Grid>
          {dividerAfterItems ? <Divider style={{ width: '100%' }} /> : null}
        </>
      ) : null}

      {!disableAddItem && AddItemComponent ? (
        <Grid item xs={12}>
          <AddItemComponent
            {...addItemComponentProps}
            onClick={onAddItemClick}
            hasItems={!!items?.length}
          />
        </Grid>
      ) : addItemOnBottom ? (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => onAddItemClick()}
            style={{
              marginBottom: '12px',
            }}
          >
            {addItemButtonText}
          </Button>
        </Grid>
      ) : null}

      {items?.length && items.filter(i => !!i.itemId).length ? (
        <>
          <Divider style={{ width: '100%' }} />
          <Grid item xs={12} style={{ marginBottom: '12px' }} container direction="column">
            <h2>"Item Specific" Choice Ingredients</h2>
            {items.filter(i => !!i.itemId).map((item, index) => (
              <div key={item.id}>
                {dividerBetweenItems && index !== 0 ? <Divider /> : null}
                <FormItem
                  register={register}
                  item={item}
                  id={item.id}
                  unregister={unregister}
                  errors={errors}
                  getValues={getValues}
                  control={control}
                  onRemoveItem={onRemoveItemClick}
                  setValue={setValue}
                  setAutofocusId={setAutofocusId}
                  autoFocus={index === autoFocusId}
                  itemComponent={itemComponent}
                  itemComponentProps={itemComponentProps}
                  showRemoveItemButton={enableRemove}
                />
              </div>
            ))}
          </Grid>
          {dividerAfterItems ? <Divider style={{ width: '100%' }} /> : null}
        </>
      ) : null}

      {!enableEmpty && isEmpty(items) ? (
        <Grid item xs={12}>
          <FormLabel error>{emptyErrorText}</FormLabel>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormItems;
