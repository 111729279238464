import React, { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import RestaurantView from './View';
import EditRestaurant from './EditRestaurant';

import { Modal } from '../../../../blocks';
import { uglifyId } from '../../../../../utils';
import FETCH_RESTAURANT from '../../../../../apollo/queries/restaurants/fetchRestaurant';
import { generateStoreAbbreviation } from '../../../../../utils';

const Restaurant = () => {
  const { restaurantId } = useParams();

  const [showModal, setShowModal] = useState(false);

  const { data: restaurantData, loading: restaurantLoading } = useQuery(FETCH_RESTAURANT, {
    variables: { id: uglifyId('Restaurant', restaurantId) },
  });

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const restaurant = useMemo(() => restaurantData?.viewer?.restaurant, [restaurantData]);

  const primaryLocation = useMemo(() => {
    if (restaurant?.primaryLocation) {
      return {
        id: restaurant?.primaryLocation?.id,
        label: `${generateStoreAbbreviation(restaurant?.primaryLocation).abbreviation} - ${
          restaurant?.primaryLocation?.label
        }`,
        brandId: restaurant?.brandId,
        index: 0,
      };
    }

    return {};
  }, [restaurant]);

  const locations = useMemo(
    () =>
      restaurant?.locationConnection?.edges?.map((location, index) => ({
        id: location?.node?.id,
        label: `${generateStoreAbbreviation(location?.node).abbreviation} - ${
          location?.node?.label
        }`,
        brandId: location?.node?.brand?.brandId,
        index,
      })),
    [restaurantData],
  );

  return restaurantLoading ? (
    <CircularProgress />
  ) : (
    <>
      <RestaurantView
        restaurant={restaurant}
        primaryLocation={primaryLocation}
        locations={locations}
        handleOpenModal={handleOpenModal}
      />
      <Modal open={showModal} handleClose={handleCloseModal} title="Vendor">
        <EditRestaurant
          restaurant={restaurant}
          primaryLocation={primaryLocation}
          locations={locations}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default Restaurant;
