import { gql } from '@apollo/client';

const DELETE_RESTAURANT = gql`
  mutation deleteRestaurant($input: DeleteRestaurantMutationInput!) {
    deleteRestaurant(input: $input) {
      viewer {
        id
      }
      removedRestaurantId
    }
  }
`;

export default DELETE_RESTAURANT;
