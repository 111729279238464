import PropTypes from 'prop-types';
import React, { useState } from 'react';

import EditLocationForm from './EditLocationForm';
import LocationInfoView from './View';

import { Modal } from '../../../../../blocks';
import { MUTATION_NAME } from '../../../../../../constants';

const LocationInfo = ({
  countryList,
  locationInfo,
  stateList,
  permissions,
  timezoneList,
  restaurantList,
  mutationPermissions,
  weekdaysData,
  accessToMutations,
  setOpenWarningMutationName,
}) => {
  const [showModal, setShowModal] = useState(false);

  const timezoneListFormat = timezoneList?.edges?.map(timezone => ({
    id: timezone.node.timezoneId,
    label: timezone.node.label,
    value: timezone.node.id,
  }));

  const countryListFormat = countryList?.edges?.map(country => ({
    id: country.node.countryId,
    label: country.node.label,
    value: country.node.id,
    alpha2: country.node.alpha2,
  }));

  const handleOpenModal = () => {
    setShowModal(true);

    setOpenWarningMutationName(
      locationInfo?.id
        ? MUTATION_NAME.UpdateLocationMutationInput
        : MUTATION_NAME.CreateLocationMutationInput,
    );
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <LocationInfoView
        handleEditLocation={handleOpenModal}
        locationInfoData={locationInfo}
        mutationPermissions={mutationPermissions}
        permissions={permissions}
        weekdaysData={weekdaysData}
        accessToMutations={accessToMutations}
      />
      <Modal open={showModal} handleClose={handleCloseModal} title="Location Info">
        <EditLocationForm
          locationInfoData={locationInfo}
          stateList={stateList}
          permissions={permissions}
          mutationPermissions={mutationPermissions}
          timezoneList={timezoneListFormat}
          countryList={countryListFormat}
          restaurantList={restaurantList}
          weekdaysData={weekdaysData}
          handleCloseModal={handleCloseModal}
          accessToMutations={accessToMutations}
        />
      </Modal>
    </>
  );
};

LocationInfo.propTypes = {
  locationInfo: PropTypes.shape({
    address: PropTypes.shape({
      additionalInfo: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      id: PropTypes.string,
      state: PropTypes.shape({
        abbreviation: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
        stateId: PropTypes.number,
      }),
      zip: PropTypes.string,
    }),
    isEditable: PropTypes.bool,
    automaticTracker: PropTypes.bool,
    cateringInfo: PropTypes.string,
    closed: PropTypes.string,
    email: PropTypes.string,
    hygieneChitReminder: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    locationId: PropTypes.number,
    locationInfo: PropTypes.string,
    locationPicture: PropTypes.string,
    menu: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      menuId: PropTypes.number,
    }),
    opened: PropTypes.string,
    phone: PropTypes.shape({
      id: PropTypes.string,
      phone: PropTypes.string,
    }),
    printerPassword: PropTypes.string,
    recepitFooter: PropTypes.string,
    storeNumber: PropTypes.number,
    stripeAccount: PropTypes.object,
    taxgroup: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      taxgroupId: PropTypes.number,
    }),
    taxgroupConnection: PropTypes.object,
    taxrateConnection: PropTypes.object,
    timezone: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      timezoneId: PropTypes.number,
    }),
  }),
  stateList: PropTypes.object,
  timezoneList: PropTypes.object,
  restaurantList: PropTypes.object,
  menuList: PropTypes.object,
  isEditable: PropTypes.bool,
};

export default LocationInfo;
