import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Checkbox,
  Radio,
  Button,
  Grid,
} from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import CREATE_TIP_PAYMENT from '../../../apollo/mutations/payment/createTipPayment';
import SAVE_CREDIT_CARD from '../../../apollo/mutations/payment/saveCreditCard';
import { convert, CurrencyInputFormat, toFixed10 } from '../../../utils';
import { CircularProgress, Container } from '@material-ui/core';
import STATES from '../../../apollo/queries/states';
import AutoCompleteMUI from '../../blocks/AutoComplete';
import Modal from '../../blocks/ConfirmationModal';
import SelectMUI from '../Select';
import CREATE_PAYMENT from '../../../apollo/mutations/payment/createPayment';
import CREATE_CATERING_PAYMENT from '../../../apollo/mutations/payment/createCateringPayment';
import CREATE_GIFT_CARD_PAYMENT from '../../../apollo/mutations/payment/createGiftCardPayment';
import CATERING_ORDER_INFO from '../../../apollo/queries/caterings/cateringOrderInfo';
import { Permission, PermissionField } from '../index';
import { MUTATION_NAME, SNACKBAR_STATUS } from '../../../constants';
import CREATE_CREDIT_CARD_CATERING_PAYMENT from '../../../apollo/mutations/quotecatering/createCateringPayment';
import REMOVE_CREDIT_CARD from '../../../apollo/mutations/creditCards/removeCreditCard';
import CurrencyInput from '../CurrencyInput';
import { Elements } from '../index';
import { useSnackbar } from '../../../hooks/useSnackbar';
import CREATE_HOUSE_ACCOUNT_PAYMENT from '../../../apollo/mutations/payment/createHouseAccountPayment';
import { convertHouseAccountValueForFrontend } from '../../../utils/houseAccountUtils';
import { gatewayId as gatewayIdfromConst, tenderId } from '../../../constants/payments';

const Payment = ({
  allowHouseAccountPayments = false,
  quote,
  paymentType,
  handleClose,
  isQuoteCatering,
  changedCreditCard,
  accessToMutations,
  addTipPermission,
  removeCreditCardPermission,
  houseAccount,
  payPermission,
}) => {
  const { register, control, handleSubmit, errors, reset } = useForm();
  const { setSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCard, setSelectedCard] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [Cards, setCards] = useState([]);
  const { data, error, loading } = useQuery(STATES);

  const statuses = [
    {
      value: true,
      label: 'New Card',
    },
    {
      value: false,
      label: 'Saved Card',
    },
  ];

  useEffect(() => {
    setCards(
      quote?.person?.stripeAccount?.payment_methods
        ? quote?.person?.stripeAccount?.payment_methods.map(paymentMethod => ({
            label: `...X - ${paymentMethod?.card?.last4}`,
            icon: paymentMethod?.card?.brand,
            value: paymentMethod.id,
          }))
        : [],
    );
  }, []);
  const [createTipPayment, { loading: tipPaymentLoading }] = useMutation(CREATE_TIP_PAYMENT, {
    update: (cache, { data: { createTipPaymentV2 } }) => {
      cache.modify({
        id: cache.identify(quote),
        fields: {
          paymentConnection: existingPaymentConnection => {
            return {
              ...existingPaymentConnection,
              edges: [
                ...existingPaymentConnection.edges,
                {
                  node: {
                    __ref: `Payment:${createTipPaymentV2.payment?.id}`,
                  },
                },
              ],
            };
          },
        },
      });
    },
  });

  const [createPayment, { loading: createPaymentLoading }] = useMutation(CREATE_PAYMENT);
  const [createHouseAccountPayment, { loading: createHouseAccountPaymentLoading }] = useMutation(
    CREATE_HOUSE_ACCOUNT_PAYMENT,
    {
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify(data.createHouseAccountDeposit.viewer),
          fields: {
            ['houseaccountConnection']: existingPaymentConnection => {
              return {
                ...existingPaymentConnection,
                edges: [
                  ...existingPaymentConnection.edges,
                  {
                    node: {
                      __ref: `houseaccountType:${data.createHouseAccountDeposit.id}`,
                    },
                  },
                ],
              };
            },
          },
        });
      },
    },
  );

  const [createGiftCardPayment, { loading: createGiftCardPaymentLoading }] = useMutation(
    CREATE_GIFT_CARD_PAYMENT,
    {
      onCompleted: data => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Payment is successful.',
        });
        reset({ amount: null });
        setPaymentButtonLock(false);
      },
      onError: error => {
        setSnackbar({
          type: SNACKBAR_STATUS.ERROR,
          text: error.message,
          open: true,
        });
        setPaymentButtonLock(false);
      },
    },
  );

  const [removeCreditCard, { loading: removeCreditCardLoading }] = useMutation(REMOVE_CREDIT_CARD, {
    onCompleted: data => {
      setCards(
        Cards.filter(card => card.value !== data?.removeCreditCard?.stripeData?.paymentMethodId),
      );
      setSelectedCard(null);
      setSnackbar({
        type: SNACKBAR_STATUS.SUCCESS,
        text: 'Credit card deleted!',
        open: true,
      });
    },
    onError: error => {
      setSnackbar({
        type: SNACKBAR_STATUS.ERROR,
        text: error.message,
        open: true,
      });
    },
  });
  const [
    createCreditCardCateringPayment,
    { loading: createCreditCardCateringPaymentLoading },
  ] = useMutation(CREATE_CREDIT_CARD_CATERING_PAYMENT);
  const [createQuoteCateringPayment, { loading: createQuoteCateringPaymentLoading }] = useMutation(
    CREATE_CATERING_PAYMENT,
  );

  const [saveCreditCard, { loading: saveCreditCardLoading }] = useMutation(SAVE_CREDIT_CARD);
  const [paymentMethod, setPaymentMethod] = useState(
    'VGVuZGVyOjI6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
  );
  const [newCard, setNewCard] = useState(true);
  const [paymentButtonLock, setPaymentButtonLock] = useState(false);
  const [selectedTipAmount, setSelectedTipAmount] = useState((quote?.subtotalPrice / 100) * 0.15);
  const [hideAddress, setHideAddress] = useState(false);

  const maxAmount =
    paymentType === 'houseAccount' && houseAccount
      ? convertHouseAccountValueForFrontend(houseAccount.balance)
      : quote?.totalPrice - quote?.paid || 0;
  const isFullyPaid = parseFloat(maxAmount) <= 0;

  const disablePay =
    tipPaymentLoading ||
    createPaymentLoading ||
    createQuoteCateringPaymentLoading ||
    saveCreditCardLoading ||
    createCreditCardCateringPaymentLoading ||
    createHouseAccountPaymentLoading;
  const changeNewCard = value => {
    setHideAddress(!hideAddress);
    setNewCard(value);
  };

  const handleRemoveCreditCard = cardId => {
    removeCreditCard({
      variables: {
        input: {
          paymentMethodId: cardId,
        },
      },
    });
  };

  const renderCustomerCards = () => {
    // const Cards = quote?.person?.stripeAccount?.payment_methods?.map(paymentMethod => ({
    //   label: `...X - ${paymentMethod?.card?.last4}`,
    //   icon: paymentMethod?.card?.brand,
    //   value: paymentMethod.id,
    // }));
    return (
      <div>
        <SelectMUI
          items={
            Cards?.length > 0 ? Cards : [{ label: 'No cards', value: 'None', disabledOption: true }]
          }
          disabled={!quote}
          label={'Credit Cards'}
          id={'savedCard'}
          error={errors['savedCard']}
          helperText={errors['savedCard'] && 'Credit card is required.'}
          rules={{ required: true }}
          onValueChange={e => {
            setSelectedCard(e.target.value);
          }}
          control={control}
        />

        {selectedCard && (isQuoteCatering || paymentType === 'houseAccount') && (
          <div style={{ textAlign: 'right' }}>
            <Permission
              access={Math.min(
                accessToMutations?.[MUTATION_NAME.removeCreditCard],
                removeCreditCardPermission?.access,
              )}
            >
              <PermissionField createHelperText>
                <Button
                  onClick={() => {
                    setOpenConfirm(true);
                    setSelectedCard(selectedCard);
                  }}
                  style={{
                    color: 'white',
                    backgroundColor: 'red',
                    marginTop: '1rem',
                  }}
                >
                  Delete Card
                </Button>
              </PermissionField>
            </Permission>
            <Modal
              handleClose={() => {
                setOpenConfirm(false);
              }}
              open={openConfirm}
              confirmAction={() => handleRemoveCreditCard(selectedCard)}
              text="Are you sure you want to delete the card?"
              buttonText="Delete Card"
            />
          </div>
        )}
      </div>
    );
  };

  const renderGiftCardForm = () => {
    return (
      <div>
        {paymentType !== 'tip' && (
          <TextField
            label="Amount"
            name={'amount'}
            variant="outlined"
            inputRef={register({
              required: {
                value: true,
                message: 'Amount is requried',
              },
              min: {
                value: '.1',
                message: 'Minimal amount is 0.1',
              },
              max: {
                value: maxAmount,
                message: `Maximal amount is $${maxAmount}`,
              },
            })}
            placeholder={`$${convert.centsToDollars(quote?.dueAmount ? quote?.dueAmount : 0)}`}
            style={{ margin: '1rem 1rem 1rem 0', width: '10rem' }}
            error={errors?.amount}
            helperText={errors?.amount && errors?.amount.message}
          />
        )}
        <TextField
          label="Gift Card Number"
          name={'giftCardNumber'}
          variant="outlined"
          inputRef={register({
            required: {
              value: true,
              message: 'Gift Card Number is requried',
            },
            maxLength: {
              value: 30,
              message: 'Max 30 characters',
            },
          })}
          style={{ marginTop: '1rem', marginBottom: '1rem', width: '25rem' }}
          error={errors?.giftCardNumber}
          helperText={errors?.giftCardNumber && errors?.giftCardNumber.message}
        />
      </div>
    );
  };

  const renderCardForm = () => {
    return (
      <>
        <div>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <div>
              <Elements.ToggleButton
                options={statuses}
                value={true}
                handleToggleClick={value => {
                  setSelectedCard(null);
                  changeNewCard(value);
                }}
              />
            </div>
            <div style={{ width: '100%' }}>
              {newCard ? (
                <>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '20px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </>
              ) : (
                renderCustomerCards()
              )}
            </div>
          </div>
          <br />
          {quote?.person && newCard && (
            <Permission access={accessToMutations?.[MUTATION_NAME.SaveCreditCardMutationInput]}>
              <div>
                <PermissionField
                  Component={FormControlLabel}
                  createHelperText
                  control={<Checkbox name="saveCard" inputRef={register} />}
                  label="Save credit card information for future payments"
                />
              </div>
            </Permission>
          )}
          <br />
        </div>
      </>
    );
  };

  const handleNotCreditPayment = async (source, quoteData, data) => {
    let gatewayId = '';

    if ([tenderId.CHARGE, tenderId.APPLE_PAY, tenderId.GOOGLE_PAY].includes(data?.creditCard)) {
      gatewayId = gatewayIdfromConst.STRIPE;
    } else if (data?.creditCard === tenderId.PAYPAL) {
      gatewayId = gatewayIdfromConst.PAYPAL;
    }

    if (isQuoteCatering) {
      if (source === tenderId.GIFT_CARD) {
        await createGiftCardPayment({
          variables: {
            quoteId: quoteData?.id,
            cardNumber: data?.giftCardNumber,
            amount: convert.dollarsToCents(CurrencyInputFormat(data?.amount)),
            tenderId: data?.creditCard,
            gatewayId,
          },
          refetchQueries: [
            {
              query: CATERING_ORDER_INFO,
              variables: { id: quoteData?.id },
            },
          ],
        });
        return;
      }

      createQuoteCateringPayment({
        variables: {
          source,
          quoteId: quoteData?.id,
          gatewayId,
          tenderId: data?.creditCard,
          amount: data?.cashAmount
            ? convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount))
            : quote?.totalPrice,
          chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
          referenceNumber: data.referenceNumber,
        },
        refetchQueries: [
          {
            query: CATERING_ORDER_INFO,
            variables: { id: quoteData?.id },
          },
        ],
      })
        .then(async response => {
          if (response.data.createCateringPaymentV2.requiresAction) {
            const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
              response.data.createCateringPaymentV2.requiresAction.paymentIntentClientSecret,
            );

            if (errorAction) {
              setSnackbar({
                type: SNACKBAR_STATUS.ERROR,
                text: errorAction.message,
                open: true,
              });
              setPaymentButtonLock(false);
            } else {
              createQuoteCateringPayment({
                variables: {
                  source,
                  quoteId: quoteData?.id,
                  gatewayId,
                  tenderId: data?.creditCard,
                  amount: data?.cashAmount
                    ? convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount))
                    : quote?.totalPrice,
                  chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
                  referenceNumber: data.referenceNumber,
                  confirmPaymentIntentId: paymentIntent.id,
                },
                refetchQueries: [
                  {
                    query: CATERING_ORDER_INFO,
                    variables: { id: quoteData?.id },
                  },
                ],
              })
                .then(() => {
                  setSnackbar({
                    open: true,
                    type: 'success',
                    text: 'Payment is successful.',
                  });
                  setPaymentButtonLock(false);
                })
                .catch(error => {
                  setSnackbar({
                    type: SNACKBAR_STATUS.ERROR,
                    text: error.message,
                    open: true,
                  });
                  setPaymentButtonLock(false);
                });
            }
          } else {
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Payment is successful.',
            });
            setPaymentButtonLock(false);
          }
        })
        .catch(error => {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          });
          setPaymentButtonLock(false);
        });
    }

    if (data.tip || data.customTip) {
      createTipPayment({
        variables: {
          source,
          quoteId: quoteData?.id,
          gatewayId,
          tenderId: data?.creditCard,
          tip: data?.tip?.length
            ? convert.dollarsToCents(data.tip)
            : convert.dollarsToCents(data.customTip),
          chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
          referenceNumber: data.referenceNumber,
          giftCardNumber: data.giftCardNumber,
        },
      })
        .then(async response => {
          if (response.data.createTipPaymentV2.requiresAction) {
            const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
              response.data.createTipPaymentV2.requiresAction.paymentIntentClientSecret,
            );

            if (errorAction) {
              setSnackbar({
                type: SNACKBAR_STATUS.ERROR,
                text: errorAction.message,
                open: true,
              });
              setPaymentButtonLock(false);
            } else {
              createTipPayment({
                variables: {
                  source,
                  quoteId: quoteData?.id,
                  gatewayId,
                  tenderId: data?.creditCard,
                  tip: data?.tip?.length
                    ? convert.dollarsToCents(data.tip)
                    : convert.dollarsToCents(data.customTip),
                  chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
                  referenceNumber: data.referenceNumber,
                  giftCardNumber: data.giftCardNumber,
                  confirmPaymentIntentId: paymentIntent.id,
                },
              })
                .then(() => {
                  handleClose && handleClose();
                  setSnackbar({
                    open: true,
                    type: 'success',
                    text: 'Tip is added successfully.',
                  });
                  setPaymentButtonLock(false);
                })
                .catch(error => {
                  setSnackbar({
                    type: SNACKBAR_STATUS.ERROR,
                    text: error.message,
                    open: true,
                  });
                  setPaymentButtonLock(false);
                });
            }
          } else {
            handleClose && handleClose();
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Tip is added successfully.',
            });
            setPaymentButtonLock(false);
          }
        })
        .catch(error => {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          });
          setPaymentButtonLock(false);
        });
    }
    if (paymentType === 'houseAccount') {
      const amount =
        source === 'VGVuZGVyOjQ6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA='
          ? convert.dollarsToCents(CurrencyInputFormat(data?.amount))
          : convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount));
      createHouseAccountPayment({
        variables: {
          houseaccountId: houseAccount.id,
          tenderId: data?.creditCard,
          amount,
          gatewayId,
          referenceNumber: data.referenceNumber,
          giftCardNumber: data.giftCardNumber | null,
        },
      })
        .then(r => {
          if (r.data.createHouseAccountDeposit.paidAmount !== amount) {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: `Not enough funds to pay full amount. Only ${convert.centsToDollars(
                r.data.createHouseAccountDeposit.paidAmount,
              )} is paid.`,
              open: true,
            });
          } else {
            setSnackbar({
              open: true,
              type: 'success',
              text: 'Payment is successful.',
            });
          }
          handleClose && handleClose();
          setPaymentButtonLock(false);
        })
        .catch(error => {
          setSnackbar({
            type: SNACKBAR_STATUS.ERROR,
            text: error.message,
            open: true,
          });
          setPaymentButtonLock(false);
        });
    }
  };

  // eslint-disable-next-line no-shadow
  const handleCreditPayment = (source, quoteData, data) => {
    let gatewayId = '';

    if ([tenderId.CHARGE, tenderId.APPLE_PAY, tenderId.GOOGLE_PAY].includes(data?.creditCard)) {
      gatewayId = gatewayIdfromConst.STRIPE;
    } else if (data?.creditCard === tenderId.PAYPAL) {
      gatewayId = gatewayIdfromConst.PAYPAL;
    }

    if (paymentType === 'tip') {
      if (data.tip || data.customTip) {
        createTipPayment({
          variables: {
            source,
            quoteId: quoteData?.id,
            gatewayId,
            tenderId: data?.creditCard,
            tip: data?.tip?.length
              ? convert.dollarsToCents(data.tip)
              : convert.dollarsToCents(data.customTip),
            chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
            referenceNumber: data.referenceNumber,
          },
        })
          .then(async response => {
            if (response.data.createTipPaymentV2.requiresAction) {
              const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
                response.data.createTipPaymentV2.requiresAction.paymentIntentClientSecret,
              );

              if (errorAction) {
                setSnackbar({
                  type: SNACKBAR_STATUS.ERROR,
                  text: errorAction.message,
                  open: true,
                });
                setPaymentButtonLock(false);
              } else {
                createTipPayment({
                  variables: {
                    source,
                    quoteId: quoteData?.id,
                    gatewayId,
                    tenderId: data?.creditCard,
                    tip: data?.tip?.length
                      ? convert.dollarsToCents(data.tip)
                      : convert.dollarsToCents(data.customTip),
                    chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
                    referenceNumber: data.referenceNumber,
                    giftCardNumber: data.giftCardNumber,
                    confirmPaymentIntentId: paymentIntent.id,
                  },
                })
                  .then(() => {
                    handleClose && handleClose();
                    setSnackbar({
                      open: true,
                      type: 'success',
                      text: 'Tip is added successfully.',
                    });
                    setPaymentButtonLock(false);
                  })
                  .catch(error => {
                    setSnackbar({
                      type: SNACKBAR_STATUS.ERROR,
                      text: error.message,
                      open: true,
                    });
                    setPaymentButtonLock(false);
                  });
              }
            } else {
              handleClose && handleClose();
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Tip is added successfully.',
              });
              setPaymentButtonLock(false);
            }
          })
          .catch(error => {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: error.message,
              open: true,
            });
            setPaymentButtonLock(false);
          });
      }
    } else {
      if (isQuoteCatering) {
        createCreditCardCateringPayment({
          variables: {
            source,
            quoteId: quoteData?.id,
            gatewayId,
            tenderId: data?.creditCard,
            amount: data?.cashAmount
              ? convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount))
              : quote?.totalPrice,
            chargeCustomer: !!data.creditCard || !!data.savedCard,
            referenceNumber: data.referenceNumber,
          },
          refetchQueries: [
            {
              query: CATERING_ORDER_INFO,
              variables: { id: quoteData?.id },
            },
          ],
        })
          .then(async response => {
            console.log(response);
            if (response.data.createCateringPaymentV2.requiresAction) {
              const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
                response.data.createCateringPaymentV2.requiresAction.paymentIntentClientSecret,
              );
              if (errorAction) {
                setSnackbar({
                  type: SNACKBAR_STATUS.ERROR,
                  text: errorAction.message,
                  open: true,
                });
                setPaymentButtonLock(false);
              } else {
                createCreditCardCateringPayment({
                  variables: {
                    source,
                    quoteId: quoteData?.id,
                    gatewayId,
                    tenderId: data?.creditCard,
                    amount: data?.cashAmount
                      ? convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount))
                      : quote?.totalPrice,
                    chargeCustomer: !!data.creditCard || !!data.savedCard,
                    referenceNumber: data.referenceNumber,
                    confirmPaymentIntentId: paymentIntent.id,
                  },
                  refetchQueries: [
                    {
                      query: CATERING_ORDER_INFO,
                      variables: { id: quoteData?.id },
                    },
                  ],
                })
                  .then(() => {
                    setSnackbar({
                      open: true,
                      type: 'success',
                      text: 'Payment is successful.',
                    });
                    setPaymentButtonLock(false);
                  })
                  .catch(error => {
                    setSnackbar({
                      type: SNACKBAR_STATUS.ERROR,
                      text: error.message,
                      open: true,
                    });
                    setPaymentButtonLock(false);
                  });
              }
            } else {
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Payment is successful.',
              });
              setPaymentButtonLock(false);
            }
          })
          .catch(error => {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: error.message,
              open: true,
            });
            setPaymentButtonLock(false);
          });
      } else if (paymentType === 'houseAccount') {
        createHouseAccountPayment({
          variables: {
            houseaccountId: houseAccount.id,
            amount: convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount)),
            gatewayId,
            tenderId: data?.creditCard,
            source,
            referenceNumber: data.referenceNumber,
          },
        })
          .then(async r => {
            if (r.data.createHouseAccountDeposit.requiresAction) {
              const { paymentIntent, error } = await stripe.handleNextAction({
                clientSecret:
                  r.data.createHouseAccountDeposit.requiresAction.paymentIntentClientSecret,
              });
              if (paymentIntent?.status === 'requires_confirmation') {
                createHouseAccountPayment({
                  variables: {
                    houseaccountId: houseAccount.id,
                    amount: convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount)),
                    gatewayId,
                    tenderId: data?.creditCard,
                    source,
                    referenceNumber: data.referenceNumber,
                    confirmPaymentIntentId: paymentIntent.id,
                  },
                })
                  .then(() => {
                    handleClose && handleClose();
                    setSnackbar({
                      open: true,
                      type: 'success',
                      text: 'Payment is successful.',
                    });
                    setPaymentButtonLock(false);
                  })
                  .catch(error => {
                    setSnackbar({
                      type: SNACKBAR_STATUS.ERROR,
                      text: error.message,
                      open: true,
                    });
                    setPaymentButtonLock(false);
                    return;
                  });
              }
              if (error) {
                setSnackbar({
                  type: SNACKBAR_STATUS.ERROR,
                  text: error.message,
                  open: true,
                });
                setPaymentButtonLock(false);
                return;
              }
            } else {
              handleClose && handleClose();
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Payment is successful.',
              });
              setPaymentButtonLock(false);
            }
          })
          .catch(error => {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: error.message,
              open: true,
            });
            setPaymentButtonLock(false);
          });
      } else {
        createPayment({
          variables: {
            source,
            quoteId: quoteData?.id,
            gatewayId,
            tenderId: data?.creditCard,
            amount: quote?.totalPrice,
            chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
            referenceNumber: data.referenceNumber,
          },
          refetchQueries: [
            {
              query: CATERING_ORDER_INFO,
              variables: { id: quoteData?.id },
            },
          ],
        })
          .then(async response => {
            if (response.data.createPaymentV2.requiresAction) {
              const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
                response.data.createPaymentV2.requiresAction.paymentIntentClientSecret,
              );
              if (errorAction) {
                setSnackbar({
                  type: SNACKBAR_STATUS.ERROR,
                  text: errorAction.message,
                  open: true,
                });
                setPaymentButtonLock(false);
              } else {
                createPayment({
                  variables: {
                    source,
                    quoteId: quoteData?.id,
                    gatewayId,
                    tenderId: data?.creditCard,
                    amount: quote?.totalPrice,
                    chargeCustomer: !!data.creditCard || !!data.saveCreditCardInfo,
                    referenceNumber: data.referenceNumber,
                    confirmPaymentIntentId: paymentIntent.id,
                  },
                  refetchQueries: [
                    {
                      query: CATERING_ORDER_INFO,
                      variables: { id: quoteData?.id },
                    },
                  ],
                })
                  .then(() => {
                    setSnackbar({
                      open: true,
                      type: 'success',
                      text: 'Payment is successful.',
                    });
                    setPaymentButtonLock(false);
                  })
                  .catch(error => {
                    setSnackbar({
                      type: SNACKBAR_STATUS.ERROR,
                      text: error.message,
                      open: true,
                    });
                    setPaymentButtonLock(false);
                  });
              }
            } else {
              setSnackbar({
                open: true,
                type: 'success',
                text: 'Payment is successful.',
              });
              setPaymentButtonLock(false);
            }
          })
          .catch(error => {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: error.message,
              open: true,
            });
            setPaymentButtonLock(false);
          });
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const onSubmit = async data => {
    if (quote.quotecatering && !quote.quotecatering.cateringExpert) {
      setSnackbar({
        open: true,
        type: 'warning',
        text: 'Please assign catering expert and update quote.',
      });
      return;
    }

    if (changedCreditCard) {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Costumer info is changed update quote to proceed with payment.',
      });
      return;
    }
    if (data.cashAmount) {
      if (
        convert.dollarsToCents(CurrencyInputFormat(data?.cashAmount)) > quote.dueAmount &&
        paymentType !== 'houseAccount'
      ) {
        setSnackbar({
          open: true,
          type: 'error',
          text: "Amount passed can't be bigger than due amount!",
        });
        return;
      }
    }
    const cardElement = elements.getElement(CardElement);

    setPaymentButtonLock(true);

    if (data.creditCard === tenderId.CHARGE) {
      if (newCard) {
        await stripe
          .createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: data.cardholderName,
              address: {
                city: data.city,
                state: data.state.label,
                line1: data.address,
                postal_code: data.zip,
              },
            },
          })
          .then(result => {
            if (result.paymentMethod) {
              if (data.saveCard) {
                saveCreditCard({
                  variables: {
                    personId: quote?.person?.id,
                    paymentMethodId: result.paymentMethod.id,
                  },
                  refetchQueries: [
                    {
                      query: CATERING_ORDER_INFO,
                      variables: { id: quote?.id },
                    },
                  ],
                })
                  .then(() => {
                    setCards([
                      ...Cards,
                      {
                        label: `...X - ${result?.paymentMethod?.card?.last4}`,
                        icon: result?.paymentMethod?.card?.brand,
                        value: result?.paymentMethod.id,
                      },
                    ]);
                    handleCreditPayment(result?.paymentMethod.id, quote, data);
                  })
                  .catch(error => {
                    setSnackbar({
                      type: SNACKBAR_STATUS.ERROR,
                      text: error.message,
                      open: true,
                    });
                    setPaymentButtonLock(false);
                  });
              } else {
                handleCreditPayment(result.paymentMethod.id, quote, data);
              }
            } else {
              setPaymentButtonLock(false);
            }
          })
          .catch(e => {
            setSnackbar({
              type: SNACKBAR_STATUS.ERROR,
              text: error.message,
              open: true,
            });
            setPaymentButtonLock(false);
          });
      } else {
        handleCreditPayment(data?.savedCard, quote, data);
      }
    } else {
      handleNotCreditPayment(data.creditCard, quote, data);
    }
  };

  const handleTipSelection = (event, value) => {
    setSelectedTipAmount(value === 'custom' ? 'custom' : parseFloat(value));
  };
  if (error) return { error };
  if (loading) return <CircularProgress />;

  const stateList = data?.viewer?.stateConnection?.edges?.map(state => ({
    label: state.node.label,
    value: state.node.stateId,
  }));

  const CardList = [
    {
      label: 'Internal Credit Card',
      value: tenderId.CHARGE,
    },
    {
      label: 'Cash',
      value: tenderId.CASH,
    },
    {
      label: 'Check',
      value: tenderId.CHECK,
    },
    {
      label: 'External Credit Card',
      value: tenderId.EXTERNAL_CHARGE,
    },
    {
      label: 'Gift Card',
      value: tenderId.GIFT_CARD,
    },
    {
      label: 'House Account',
      disabledOption: !allowHouseAccountPayments,
      value: tenderId.HOUSE_ACCOUNT,
    },
  ];
  return (
    <Grid container spacing={2}>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <SelectMUI
              //if it is a quote catering remove house account
              items={
                isQuoteCatering
                  ? CardList.filter(card => card.value !== tenderId.HOUSE_ACCOUNT)
                  : CardList.filter(card => card.disabledOption !== true)
              }
              id={'creditCard'}
              label={'Payment method'}
              disabled={!quote}
              defaultValue={CardList[0]?.value}
              variant="outlined"
              setSelected={setPaymentMethod}
              control={control}
              width={'100%'}
            />
          </Grid>
          {paymentMethod === tenderId.GIFT_CARD && renderGiftCardForm()}
          {paymentMethod === tenderId.CHARGE && renderCardForm()}
          {paymentMethod !== tenderId.CHARGE &&
            paymentMethod !== tenderId.CASH &&
            paymentMethod !== tenderId.HOUSE_ACCOUNT &&
            paymentMethod !== tenderId.GIFT_CARD && (
              <div
                style={{
                  marginLeft: 'auto',
                  margin: '1rem 0',
                  textAlign: 'right',
                }}
              >
                {paymentType !== 'tip' && (
                  <CurrencyInput
                    name="cashAmount"
                    control={control}
                    placeholder={`$${convert.centsToDollars(
                      quote?.dueAmount ? quote?.dueAmount : 0,
                    )}`}
                    label="Amount"
                    rules={{
                      required: true,
                      validate: {
                        max: value => {
                          return (
                            CurrencyInputFormat(value) <= maxAmount || `Max amount is $${maxAmount}`
                          );
                        },
                      },
                    }}
                    error={errors?.cashAmount}
                    helperText={errors?.cashAmount?.message}
                    style={{ marginTop: '1rem' }}
                  />
                )}
                <TextField
                  label="Reference number"
                  name={'referenceNumber'}
                  variant="outlined"
                  inputRef={register({ required: true })}
                  style={{ marginTop: '1rem' }}
                />
              </div>
            )}
          {[
            'VGVuZGVyOjI6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
            'VGVuZGVyOjE6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=',
          ].includes(paymentMethod) &&
            (isQuoteCatering || paymentType === 'houseAccount') && (
              <div
                style={{
                  marginLeft: 'auto',
                  margin: '1rem 0',
                  textAlign: 'right',
                }}
              >
                <CurrencyInput
                  name="cashAmount"
                  control={control}
                  placeholder={`$${convert.centsToDollars(
                    quote?.dueAmount ? quote?.dueAmount : 0,
                  )}`}
                  label="Amount"
                  rules={{
                    required: true,
                    validate: {
                      max: value => {
                        return (
                          CurrencyInputFormat(value) <= maxAmount || `Max amount is $${maxAmount}`
                        );
                      },
                    },
                  }}
                  error={errors?.cashAmount}
                  helperText={errors?.cashAmount?.message}
                  style={{ marginTop: '1rem' }}
                />
              </div>
            )}
          <Permission
            access={Math.min(quote?.permissions?.subtotalPrice || 0, quote?.permissions?.tip || 0)}
          >
            {paymentType === 'tip' && (
              <div
                style={{
                  marginBottom: '2rem',
                  marginTop: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormControl>
                  <FormLabel>Tip Amount</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    value={selectedTipAmount}
                    onChange={handleTipSelection}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value={(quote?.subtotalPrice / 100) * 0.1}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`10% (${toFixed10((quote?.subtotalPrice / 100) * 0.1, 2)} $)`}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value={(quote?.subtotalPrice / 100) * 0.15}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`15% (${toFixed10((quote?.subtotalPrice / 100) * 0.15, 2)} $)`}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          value={(quote?.subtotalPrice / 100) * 0.2}
                          name="tip"
                          style={{ marginRight: '5rem' }}
                          inputRef={register}
                          control={<Radio />}
                          label={`20% (${toFixed10((quote?.subtotalPrice / 100) * 0.2, 2)} $)`}
                        />
                      </div>
                      <FormControlLabel
                        value={'custom'}
                        control={<Radio />}
                        label="Custom Amount"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <TextField
                  name="customTip"
                  inputRef={register}
                  disabled={selectedTipAmount !== 'custom'}
                  placeholder="$ 0.00"
                  variant="outlined"
                />
              </div>
            )}
          </Permission>
          {!hideAddress && paymentMethod === 'VGVuZGVyOjI6OTk5OS0xMi0zMSAyMzo1OTo1OS4wMDAwMDA=' && (
            <div>
              <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                <TextField
                  name="cardholderName"
                  label="Cardholder name"
                  disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                  inputRef={register({ required: true })}
                  error={errors?.cardholderName}
                  helperText={errors?.cardholderName && 'Name is required.'}
                  fullWidth
                />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
                <Grid item xs={6}>
                  <TextField
                    name="address"
                    label="Address"
                    disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                    inputRef={register}
                    error={errors?.address}
                    helperText={errors?.address && 'Address is required.'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="apartment"
                    label="Apartment, Suite, Unit, Building, Floor..."
                    inputRef={register}
                    disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                    error={errors?.apartment}
                    helperText={errors?.apartment && 'Apartment is required.'}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
                {isFullyPaid ? (
                  <Grid item xs={4}>
                    <TextField
                      name="state"
                      label="State"
                      disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                      inputRef={register({ required: 'Required' })}
                      error={errors?.state}
                      helperText={errors?.state && 'State is required.'}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <AutoCompleteMUI
                      id={'state'}
                      rules={{ required: true }}
                      style={{ width: '100%' }}
                      disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                      getOptionLabel={option => option.label}
                      control={control}
                      renderInput={params => (
                        <TextField {...params} label={'State'} variant="outlined" />
                      )}
                      options={stateList}
                    />
                    <span style={{ color: 'red' }}>{errors.state && 'State is required.'}</span>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    name="city"
                    label="City"
                    disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                    error={errors?.city}
                    helperText={errors?.city && 'City is required.'}
                    inputRef={register}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="zip"
                    disabled={paymentType !== 'tip' && (isFullyPaid || !quote)}
                    fullWidth
                    label="Zip"
                    error={errors?.zip}
                    helperText={errors?.zip && 'Zip is required.'}
                    inputRef={register({ required: true })}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <div style={{ textAlign: paymentType === 'tip' ? 'right' : 'left' }}>
            <Permission
              access={
                payPermission
                  ? Math.min(
                      payPermission.access,
                      accessToMutations?.[MUTATION_NAME.CreateHouseAccountDepositMutationInput],
                    )
                  : Math.min(
                      accessToMutations?.[MUTATION_NAME.CreatePaymentMutationInput],
                      accessToMutations?.[MUTATION_NAME.CreateCateringPaymentMutationInput],
                      addTipPermission?.access,
                    )
              }
            >
              <div>
                <PermissionField createHelperText>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: '8rem', marginBottom: '1rem' }}
                    disabled={
                      paymentButtonLock ||
                      disablePay ||
                      (isFullyPaid && paymentType !== 'tip') ||
                      !quote
                    }
                  >
                    <CreditCardIcon /> Pay
                  </Button>
                </PermissionField>
              </div>
            </Permission>
          </div>
        </form>
      </Container>
    </Grid>
  );
};

export default Payment;

Payment.propTypes = {
  quote: PropTypes.object,
  allowHouseAccountPayments: PropTypes.bool,
  paymentType: PropTypes.string,
  stateList: PropTypes.array,
  handleClose: PropTypes.func,
};
