import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Grid, GridListTile, GridList, Button, Typography } from '@material-ui/core';

import RestaurantStyles from './styles';
import { paths } from '../../../../../../constants';
import { SelectFactory } from '../../../../../blocks';
import { LOCATIONS } from '../../../../../../apollo/queries';

const RestaurantView = ({ restaurant, primaryLocation, locations, handleOpenModal }) => {
  const classes = RestaurantStyles();

  return (
    <Paper>
      <GridList className={classes.gridList} cellHeight={'auto'} cols={1}>
        <GridListTile>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h1 className={classes.heading}>{restaurant?.label}</h1>
            <div>
              <Link to={paths.LOCATIONS_RESTAURANTS}>
                <Button variant="outlined" className={classes.cancelBtn}>
                  Cancel
                </Button>
              </Link>
              <Button onClick={handleOpenModal}>Update</Button>
            </div>
          </div>
        </GridListTile>

        <Grid container className={classes.marginBottom}>
          <Grid container direction="column" item md={12}>
            <Typography variant="subtitle2">Description</Typography>
            <Typography>{restaurant?.description || '/'}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.marginBottom}>
          <Grid container direction="column" item md={12}>
            <Typography variant="subtitle2">Primary Location</Typography>
            <Typography>{primaryLocation?.label || '/'}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.marginBottom}>
          <Grid container direction="column" item md={12}>
            <SelectFactory
              id="locations"
              name="Locations"
              label={'Locations'}
              placeholder={'Locations'}
              query={LOCATIONS}
              disableCloseOnSelect={true}
              value={locations}
              disabled={true}
              structureData={() => {}}
            />
          </Grid>
        </Grid>
      </GridList>
    </Paper>
  );
};

export default RestaurantView;
