import {
  FormHelperText,
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Box,
  FormLabel,
  Checkbox,
  ListItemText,
  InputAdornment,
} from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FETCH_RECIPE_CATEGORIES } from '../../../../../../../apollo/queries';
import { SelectFactory } from '../../../../../../blocks';
import GenericFileUpload from '../../../../../../blocks/GenericFileUpload';
import NumberInput from '../../../../../../blocks/NumberInput';

const INVENTORY_ITEM_CATEGORY_TYPE_ID = {
  ['Prep']: 1,
  ['Line']: 2,
};

const showOnLocationOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const BasicInfo = ({
  register,
  errors,
  recipeTypeOptions = [],
  control,
  measureOptions = [],
  images,
  setImages,
  brandOptions,
  statusOptions,
  recipe,
  logo,
  setLogo,
  getValues,
  setMainMeasure,
}) => {
  const [categoryTypeId, setCategoryTypeId] = useState(recipe?.recipetypeId);

  const handleOnRecipeTypeChange = useCallback(
    ({ label }) => {
      if (INVENTORY_ITEM_CATEGORY_TYPE_ID[label] !== categoryTypeId) {
        setCategoryTypeId(INVENTORY_ITEM_CATEGORY_TYPE_ID[label]);
      }
    },
    [categoryTypeId],
  );

  const handleChangeYieldQuantity = useCallback(() => {
    setMainMeasure(prev => ({ ...prev, quantity: getValues()?.yieldQuantity }));
  }, [setMainMeasure, getValues]);

  const handleChangeYieldMeasure = useCallback(
    measure => {
      setMainMeasure(prev => ({ ...prev, label: measure.abbreviation || 0 }));
    },
    [setMainMeasure],
  );

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required: 'true' })}
            fullWidth
            label="Name"
            error={!!errors.name}
            helperText={!!errors.name ? 'Name is required' : ''}
            name="name"
            variant="outlined"
            placeholder="Name"
          />
        </Grid>
        <Grid item xs={12} style={{ flexDirection: 'column' }}>
          <TextField
            name="cost"
            inputRef={register}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              min: 0.0,
              step: 0.000000000000001,
            }}
            fullWidth
            label="Average Unit Price"
            variant="outlined"
          />
          <p>If left blank, the system will use the latest invoice price.</p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.status}>Status</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="status"
              name="status"
              label="Status"
              defaultValue=""
              placeholder="Status"
              error={!!errors?.status}
              as={
                <Select label="Status" multiple={false} error={!!errors?.status}>
                  {statusOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.status?.type === 'required' ? (
              <FormHelperText error>Status is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.brands}>Brand</InputLabel>
            <Controller
              rules={{
                validate: {
                  required: values => !isEmpty(values),
                },
              }}
              id="brands"
              name="brands"
              label="Brands"
              defaultValue={[]}
              placeholder="Brands"
              error={!!errors?.brands}
              renderValue={selected => selected.map(({ label }) => label).join(', ')}
              as={props => (
                <Select {...props} label="Brand" multiple error={!!errors?.brands}>
                  {brandOptions?.map(option => (
                    <MenuItem key={option.value} value={option}>
                      <Checkbox
                        checked={!!props.value.find(brand => brand.value === option.value)}
                      />
                      <ListItemText style={{ margin: '0' }} primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
            {errors?.brands?.type === 'required' ? (
              <FormHelperText error>Brands is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Show On Location Menu Settings</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="showOnLocation"
              name="showOnLocation"
              label="Show On Location Menu Settings"
              defaultValue={1}
              placeholder="Show On Location Menu Settings"
              as={
                <Select label="Show On Location Menu Settings" multiple={false}>
                  {showOnLocationOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Allow Automatic Stock Toggle</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="allowAutomaticStockToggle"
              name="allowAutomaticStockToggle"
              label="Allow Automatic Stock Toggle"
              defaultValue={1}
              placeholder="Allow Automatic Stock Toggle"
              as={
                <Select label="Allow Automatic Stock Toggle" multiple={false}>
                  {showOnLocationOptions?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.recipeType}>Recipe Type</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id="recipeType"
              name="recipeType"
              label="Recipe Type"
              defaultValue=""
              placeholder="Recipe Type"
              error={!!errors?.recipeType}
              as={
                <Select label="Recipe Type" multiple={false} error={!!errors?.recipeType}>
                  {recipeTypeOptions?.map(option => (
                    <MenuItem
                      onClick={() => handleOnRecipeTypeChange(option)}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.recipeType?.type === 'required' ? (
              <FormHelperText error>Recipe Type is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box hidden={categoryTypeId !== 1} style={{ width: '100%' }}>
            <Controller
              control={control}
              name="costCategory"
              defaultValue={
                recipe?.recipetypeId === 1
                  ? {
                      id: recipe.inventoryitem?.inventoryitemcategoryId,
                      label: recipe.inventoryitem?.inventoryitemcategory.label,
                    }
                  : ''
              }
              render={props => (
                <SelectFactory
                  {...props}
                  defaultValue={
                    recipe?.recipetypeId === 1
                      ? {
                          id: recipe.inventoryitem?.inventoryitemcategoryId,
                          label: recipe.inventoryitem?.inventoryitemcategory.label,
                        }
                      : ''
                  }
                  customProps={props}
                  label="Category"
                  fullWidth
                  filter={{ inventoryitemcategorytype: { isCost: { eq: 1 } } }}
                  name="costCategory"
                  placeholder="Search Categories"
                  query={FETCH_RECIPE_CATEGORIES}
                  otherVariables={{
                    fieldGroupOnly: 7,
                  }}
                  structureData={data =>
                    data?.viewer?.inventoryitemcategoryConnection?.edges?.map((edge, index) => ({
                      id: edge.node.inventoryitemcategoryId,
                      label: edge.node.label,
                      index,
                    }))
                  }
                  disableCloseOnSelect={false}
                  multiple={false}
                  renderCheckBox={false}
                  error={!!errors?.costCategory}
                  helperText={errors?.costCategory && 'Category is required'}
                />
              )}
            />
          </Box>
          <Box hidden={categoryTypeId !== 2} style={{ width: '100%' }}>
            <Controller
              control={control}
              name="saleCategory"
              defaultValue={
                recipe?.recipetypeId === 2
                  ? {
                      id: recipe.inventoryitem?.inventoryitemcategoryId,
                      label: recipe.inventoryitem?.inventoryitemcategory.label,
                    }
                  : ''
              }
              render={props => (
                <SelectFactory
                  {...props}
                  customProps={props}
                  label="Category"
                  fullWidth
                  defaultValue={
                    recipe?.recipetypeId === 2
                      ? {
                          id: recipe.inventoryitem?.inventoryitemcategoryId,
                          label: recipe.inventoryitem?.inventoryitemcategory.label,
                        }
                      : ''
                  }
                  filter={{ inventoryitemcategorytype: { isCost: { eq: 0 } } }}
                  name="saleCategory"
                  placeholder="Search Categories"
                  query={FETCH_RECIPE_CATEGORIES}
                  otherVariables={{
                    fieldGroupOnly: 7,
                  }}
                  structureData={data =>
                    data?.viewer?.inventoryitemcategoryConnection?.edges?.map((edge, index) => ({
                      id: edge.node.inventoryitemcategoryId,
                      label: edge.node.label,
                      index,
                    }))
                  }
                  disableCloseOnSelect={false}
                  multiple={false}
                  renderCheckBox={false}
                  error={!!errors?.saleCategory}
                  helperText={errors?.saleCategory && 'Category is required'}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            control={control}
            placeholder="1"
            onBlur={handleChangeYieldQuantity}
            defaultValue={recipe?.yieldQuantity || ''}
            id="yieldQuantity"
            required
            validations={{
              positive: value => value > 0,
            }}
            label="Yield Quantity"
            error={!!errors?.yieldQuantity}
            helperText={
              (errors?.yieldQuantity?.type === 'required' && 'Yield Quantity is required') ||
              (errors.yieldQuantity?.type === 'positive' &&
                'Yield Quantity should be greater than 0') ||
              ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel error={!!errors?.yieldMeasureId}>Yield UOM</InputLabel>
            <Controller
              rules={{
                required: true,
              }}
              id={'yieldMeasureId'}
              name={'yieldMeasureId'}
              defaultValue={''}
              label="Yield UOM"
              placeholder="Yield UOM"
              items={measureOptions}
              error={!!errors?.yieldMeasureId}
              as={
                <Select label="UOM" multiple={false} error={!!errors?.yieldMeasureId}>
                  {measureOptions?.map(measure => (
                    <MenuItem
                      onClick={() => handleChangeYieldMeasure(measure)}
                      key={measure.value}
                      value={measure.value}
                    >
                      {measure.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              control={control}
            />
            {errors?.yieldMeasureId?.type === 'required' ? (
              <FormHelperText error>Yield UOM is required</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={register()}
            fullWidth
            label="Prep Time"
            name="prepTime"
            variant="outlined"
            placeholder="Prep Time"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            inputRef={register()}
            fullWidth
            label="Shelf Life"
            name="shelfLife"
            variant="outlined"
            placeholder="Shelf Life"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={3}>
        <Grid item xs={12}>
          <FormLabel>Photos</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <GenericFileUpload
            accept="image/jpeg, image/png"
            file={images}
            setFile={setImages}
            multiple
            isImage
            shouldReadFile
            fullWidthDropBox
            justify="flex-start"
            text="Click here to add photos"
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Logo</FormLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenericFileUpload
            accept="image/jpeg, image/png"
            file={logo}
            setFile={setLogo}
            shouldReadFile
            isImage
            fullWidth
            fullWidthDropBox
            text="Click here to add logo"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicInfo;
