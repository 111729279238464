import { Box, Checkbox, IconButton, ListItemText, Typography } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

const OtcChecklistTask = ({
  initialTasks = [],
  otcTaskListOptions,
  control,
  setValue,
  getValues,
}) => {
  const onDeleteTask = useCallback(task => {
    const prevTasks = getValues('otcChecklistTask');

    setValue(
      'otcChecklistTask',
      prevTasks.filter(prevTask => prevTask.value !== task.value),
    );
  }, []);

  return (
    <Box width={{ marginBottom: '16px', width: '100%' }}>
      <Typography variant="h4" style={{ fontSize: '1.25rem' }}>
        Daily Deep Cleaning
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '8px' }}>
        OTC Task List
      </Typography>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <Controller
          id="otcChecklistTask"
          name="otcChecklistTask"
          defaultValue={initialTasks}
          placeholder="Select OTC Task"
          as={props => (
            <>
              <Select {...props} multiple renderValue={() => null} style={{ marginBottom: '16px' }}>
                {Object.entries(otcTaskListOptions).map(([key, groupedOption]) => [
                  <Box padding="6px 12px">
                    <Typography variant="caption">
                      {moment()
                        .set('day', key)
                        .format('dddd')}
                    </Typography>
                  </Box>,
                  ...groupedOption.map(option => (
                    <MenuItem key={option.value} value={option}>
                      <Checkbox
                        checked={!!props?.value?.find(item => item?.value === option?.value)}
                      />
                      <ListItemText style={{ margin: '0' }} primary={option.label} />
                    </MenuItem>
                  )),
                ]) || []}
              </Select>
              {props.value?.map(task => (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                  }}
                >
                  <div>
                    <Typography variant="body2">{task.label}</Typography>
                  </div>
                  <div>
                    <IconButton onClick={() => onDeleteTask(task)}>
                      <Close />
                    </IconButton>
                  </div>
                </Box>
              ))}
            </>
          )}
          control={control}
        />
      </FormControl>
    </Box>
  );
};

export default OtcChecklistTask;
