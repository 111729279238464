import { Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { convert } from '../../../../../../utils';
import DatePicker from '../../../../../blocks/Elements/DatePicker';
import NumberInput from '../../../../../blocks/NumberInput';

const FifteenPlanQuestionField = ({ errors, id, register, item, autoFocus, control }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={3}>
        <NumberInput
          autoFocus={autoFocus}
          control={control}
          decimalScale={2}
          defaultValue={
            item.value || item.value === 0 ? convert.centsToDollars(item.value * 100) : '' || ''
          }
          name={`${id}.value`}
          id={`${id}.value`}
          label="Amount $"
          placeholder="0.00"
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <DatePicker
          control={control}
          error={errors?.[id]?.date}
          name={`${id}.date`}
          fullWidth
          variant="inline"
          rules={{
            required: true,
            validate: {
              valid: date => !date || moment(date).isValid(),
            },
          }}
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="none"
          label="Date"
          helperText={
            errors?.[id]?.date?.type === 'required'
              ? 'Date is required'
              : errors?.[id]?.date?.type === 'valid'
              ? 'Date is not valid'
              : null
          }
          autoOk={false}
          defaultValue={item.date || ''}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          name={`${id}.vendor`}
          inputRef={register()}
          fullWidth
          label="Vendor"
          defaultValue={item.vendor || ''}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          name={`${id}.invoice`}
          inputRef={register()}
          fullWidth
          label="Invoice #"
          defaultValue={item.invoice || ''}
        />
      </Grid>
    </Grid>
  );
};

export default FifteenPlanQuestionField;
