import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import EmployeeInfo from './EmployeeInfo';
import EmployeeLocations from './EmployeeLocations';
import { Tab, Tabs, CircularProgress } from '@material-ui/core';
import {
  ConfirmationModal,
  SideBarSectionsLayoutButtonVariant,
  TabPanel,
} from '../../../../blocks';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import removeDuplicates from '../../../../../utils/removeDuplicates';
import { ACTIVE_LOCATIONS_FOR_EMPLOYEE, JOBS_BY_LOCATION } from '../../../../../apollo/queries';
import REMOVE_LOCATION_EMPLOYEE from '../../../../../apollo/mutations/labor/removeLocationEmployee';
import { uglifyId } from '../../../../../utils';
import { SNACKBAR_STATUS } from '../../../../../constants';
import { CoreContext } from '../../../../../CoreContext';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

const tabsViewData = [
  {
    label: 'Employee info',
    value: 'info',
    component: EmployeeInfo,
    id: 'UUDI1',
  },
  {
    label: 'Locations',
    value: 'locations',
    component: EmployeeLocations,
    id: 'UUDI2',
  },
];

// TODO: set employeeId to null
const CreateEmployeeForm = ({
  handleCloseModal,
  personId,
  activationEmployeePermission,
  createMutationPermission,
  updateMutationPermission,
  updateLocationPermission,
  tableState,
  accessToMutations,
  setPersonId,
  locationPermissions,
  setLocationPermissions,
}) => {
  const context = useContext(CoreContext);
  const { setSnackbar } = useSnackbar();
  const mutationPermissions = context?.coreUtils?.getAppActions()[60]?.mutations;
  const [
    isLastLocationDeleteConfirmationModalOpen,
    setIsLastLocationDeleteConfirmationModalOpen,
  ] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState();

  const deletePermission = useMemo(
    () => mutationPermissions.find(permission => permission.label === 'removeLocationEmployee'),
    [mutationPermissions],
  );

  const [currentTab, setCurrentTab] = useState(tabsViewData[0].value); // default to first tab
  const [employeeLocationJob, setEmployeeLocationJob] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [sectionList, setSectionList] = useState([
    { value: 'newLocation', name: ' + Add Locations', id: 'newLocation' },
  ]);

  const [removeLocationEmployee, { loading: removeLocationEmployeeLoading }] = useMutation(
    REMOVE_LOCATION_EMPLOYEE,
  );

  const { data, loading, refetch: refetchActiveLocationsForEmployee } = useQuery(
    ACTIVE_LOCATIONS_FOR_EMPLOYEE,
    {
      skip: !personId, // Skip fetching if no personId is provided
      fetchPolicy: 'no-cache',
      variables: {
        personId: uglifyId('Person', personId),
        locationId: selectedLocation?.locationId,
      },
    },
  );

  const tabsToShow = useMemo(
    () =>
      personId ? tabsViewData : tabsViewData.filter(tabPanel => tabPanel.value !== 'locations'),
    [personId],
  );

  useEffect(() => {
    // if there's initial locations or if a new one is chosen
    if (data || selectedLocation) {
      let newLocations = [];
      if (data) {
        newLocations = data?.viewer?.person?.locationPersonConnection?.edges?.map(
          locationPerson => ({
            name: locationPerson.node.location.label,
            locationId: locationPerson.node.locationId,
            id: locationPerson.node.location.id,
          }),
        );
      }
      if (
        selectedLocation &&
        selectedLocation.name !== undefined &&
        selectedLocation.id !== undefined
      ) {
        newLocations = [
          {
            name: selectedLocation.name,
            id: selectedLocation.id,
            locationId: selectedLocation.locationId,
          },
        ];
      }
      if (newLocations) {
        setSectionList(prevState => {
          const newSectionList = [...prevState];
          newSectionList.push(...newLocations);
          return removeDuplicates(newSectionList, 'id');
        });
      }
    }
  }, [selectedLocation, data]);

  const [fetchEmployeeLocationJob, { data: employeeLocationJobData }] = useLazyQuery(
    JOBS_BY_LOCATION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const parsedResult = employeeLocationJobData?.viewer?.jobConnection?.edges.map(
          employeeLocationJob => ({
            id: employeeLocationJob.node.id,
            jobId: employeeLocationJob.node.jobId,
            label: employeeLocationJob.node.label,
            personJob: employeeLocationJob.node.personJobConnection?.edges[0]?.node,
          }),
        );
        setEmployeeLocationJob(parsedResult);
      },
    },
  );

  const handleLocationSelection = location => {
    if (location.id === 'newLocation') {
      setSelectedLocation(false);
      return;
    }
    setSelectedLocation(sectionList.find(section => section.id === location.id));
    fetchEmployeeLocationJob({
      fetchPolicy: 'no-cache',
      variables: {
        locationFilter: {
          locationIds: {
            in: [location.locationId],
          },
        },
        personFilter: {
          personId: {
            eq: personId || null,
          },
          locationId: {
            eq: location.locationId,
          },
        },
      },
    });
  };

  const onDelete = useCallback(
    location => {
      if (location?.id) {
        setSnackbar({
          type: SNACKBAR_STATUS.INFO,
          text: 'Removing location from user...',
          open: true,
        });

        removeLocationEmployee({
          variables: {
            input: {
              locationIds: [location.locationId],
              personId,
            },
          },
        })
          .then(() => {
            setSnackbar({
              type: SNACKBAR_STATUS.SUCCESS,
              text: 'Removing location from user...',
              open: true,
            });

            setSelectedLocation('newLocation');
            setSectionList(prevState =>
              prevState.filter(foundLocation => foundLocation.id !== location.id),
            );
          })
          .catch(error =>
            setSnackbar({
              text: error.message,
              type: SNACKBAR_STATUS.ERROR,
              open: true,
            }),
          );
      }
    },
    [personId, removeLocationEmployee, setSnackbar],
  );

  const onDeleteConfirm = useCallback(
    location => {
      const filteredLocationsList = sectionList.filter(
        section => !['newLocation', location.id].includes(section.id),
      );

      if (!filteredLocationsList.length) {
        setIsLastLocationDeleteConfirmationModalOpen(true);
        setLocationToDelete(location);
      } else {
        onDelete(location);
      }
    },
    [sectionList, onDelete],
  );

  const handleCancelDelete = useCallback(() => {
    setIsLastLocationDeleteConfirmationModalOpen(false);
    setLocationToDelete();
  }, []);

  const handleOnLastLocationDeleteConfirm = useCallback(() => {
    onDelete(locationToDelete);
    setLocationToDelete();
  }, [locationToDelete]);

  if (loading) return <CircularProgress />;

  const handleTabChange = (event, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabsToShow.map(tab => (
          <Tab label={tab.label} value={tab.value} key={tab.id} />
        ))}
      </Tabs>
      {tabsToShow.map(tabPanel => (
        <TabPanel value={currentTab} index={tabPanel.value} key={tabPanel.id}>
          <TabPanel value={currentTab} index="info" key="UUDI1" style={{ marginTop: '1.5rem' }}>
            <EmployeeInfo
              existingEmployee={data?.viewer?.person}
              activationEmployeePermission={activationEmployeePermission}
              handleCloseModal={handleCloseModal}
              locationId={selectedLocation?.id}
              tableState={tableState}
              permissions={data?.viewer?.person?.permissions}
              mutationPermission={
                data?.viewer?.person ? createMutationPermission : updateMutationPermission
              }
              accessToMutations={accessToMutations}
              handleTabChange={handleTabChange}
              setPersonId={setPersonId}
              refetchActiveLocationsForEmployee={refetchActiveLocationsForEmployee}
            />
          </TabPanel>
          <TabPanel value={currentTab} index="locations" key="UUDI2">
            <SideBarSectionsLayoutButtonVariant
              sectionList={sectionList}
              initialSelectedSection={selectedLocation?.id}
              onClick={data => handleLocationSelection(data)}
              onDelete={onDeleteConfirm}
              isDeleting={removeLocationEmployeeLoading}
              deleteText="Are you sure you want to remove this location from user?"
              deletePermission={deletePermission}
            >
              <EmployeeLocations
                setSectionList={setSectionList}
                sectionList={sectionList}
                personInfo={data?.viewer?.person}
                fetchEmployeeLocationJob={fetchEmployeeLocationJob}
                setSelectedLocation={setSelectedLocation}
                selectedLocation={selectedLocation}
                employeeLocationJob={employeeLocationJob}
                refetchActiveLocationsForEmployee={refetchActiveLocationsForEmployee}
                permissions={data?.viewer?.person?.permissions}
                mutationPermission={updateLocationPermission}
                personPermissions={data?.viewer?.person?.permissions}
                jobPermissions={employeeLocationJobData?.viewer?.jobConnection?.permissions}
                locationPermissions={locationPermissions}
                setLocationPermissions={setLocationPermissions}
              />
            </SideBarSectionsLayoutButtonVariant>
          </TabPanel>
        </TabPanel>
      ))}
      <ConfirmationModal
        open={isLastLocationDeleteConfirmationModalOpen}
        handleClose={handleCancelDelete}
        disabled={removeLocationEmployeeLoading}
        warning
        title="Warning!"
        text={
          'Removing the last or only location from an employee will deactivate that employee in the Dashboard. \nIf you are reassigning the employee to a different location, please add that location first.'
        }
        confirmAction={handleOnLastLocationDeleteConfirm}
        buttonText="Continue Anyway"
      />
    </>
  );
};

export default CreateEmployeeForm;
