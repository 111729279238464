import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Permission, PermissionField, SelectFactory } from '../../../../../blocks';
import { useForm } from 'react-hook-form';
import { Elements } from '../../../../../blocks';
import EmployeeJobs from './EmployeeJobs';
import { Controller } from 'react-hook-form';
import { convert, pad } from '../../../../../../utils/index';
import { useFormSubmitPermissions } from '../../../../../../hooks';
import { uniqBy } from 'lodash';
import {
  UPDATE_LOCATION_EMPLOYEE,
  ACTIVATE_LOCATION_EMPLOYEE,
} from '../../../../../../apollo/mutations/employees';
import { LOCATIONS } from '../../../../../../apollo/queries';
import { MUTATION_NAME } from '../../../../../../constants';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';

const activeStatusOptions = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Inactive',
  },
];

const EmployeeLocations = ({
  refetchActiveLocationsForEmployee,
  personInfo,
  setSectionList,
  employeeLocationJob,
  selectedLocation,
  sectionList,
  personPermissions,
  mutationPermission,
  jobPermissions,
  permissions,
  fetchEmployeeLocationJob,
  locationPermissions,
  setLocationPermissions,
}) => {
  const { setSnackbar } = useSnackbar();

  const [updateLocationEmployee, { loading: updateLocationEmployeeLoading }] = useMutation(
    UPDATE_LOCATION_EMPLOYEE,
  );
  const [activateLocationEmployee] = useMutation(ACTIVATE_LOCATION_EMPLOYEE);

  const { accessToMutations, setOpenWarningMutationName } = useFormSubmitPermissions({
    mutationNames: [MUTATION_NAME.UpdateLocationEmployeeMutationInput],
    permissions: {
      ...permissions,
      locationId: permissions?.locationPersonConnection?.edges?.node?.__typename,
    },
  });

  useEffect(() => {
    setOpenWarningMutationName(MUTATION_NAME.UpdateLocationEmployeeMutationInput);
  }, [setOpenWarningMutationName]);

  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [hierarchyLocationIds, setHierarchyLocationIds] = useState(
    sectionList
      .filter(loc => loc.id !== 'newLocation')
      .map(item => ({
        label: item.name,
        id: item.id,
        locationId: item.locationId,
      })),
  );
  const [isScheduleDeactivationSelected, setIsScheduleDeactivationSelected] = useState(false);
  const onAccountStatusChange = value => {
    const mutationData = {
      locationPersonId: personInfo?.locationPersonConnection?.edges.find(
        elem => elem?.node?.locationId === selectedLocation?.locationId,
      )?.node?.id,
      locationId: selectedLocation?.id,
      status: value,
    };
    activateLocationEmployee({
      variables: {
        input: {
          ...mutationData,
        },
      },
    })
      .then(res => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Employee updated',
        });
        refetchActiveLocationsForEmployee();
      })
      .catch(err =>
        setSnackbar({
          open: true,
          type: 'error',
          text: err.message,
        }),
      );
  };

  const isUserActiveOnLocation = useMemo(() => {
    const inactiveDate = personInfo?.locationPersonConnection?.edges?.find(
      elem => elem?.node?.locationId === selectedLocation?.locationId,
    )?.node?.inactive;
    return inactiveDate === null || inactiveDate === undefined ? true : false;
  }, [personInfo]);

  const onSubmit = formData => {
    const locationId = selectedLocation.locationId;
    const jobs = [];
    // input grouping by name includes null values, filter and format them here
    Object.entries(formData.jobs || {}).forEach(([key, value]) => {
      value.id = parseInt(key);

      if (value.enabled) {
        value.payRate =
          value.salaryTypeId === 2 // if salary type is amount, set pay rate to 0
            ? 0
            : value.payRate || value.payRate === 0
            ? convert.dollarsToCents(value.payRate)
            : [null, ''].includes(value.payRate)
            ? null
            : undefined;
      }

      jobs.push(value);
    });

    const mutationData = {
      // fromDate: formData.fromDate, // TODO: enable when we know where it goes
      // toDate: formData.toDate,
      hireDate: formData.hireDate,
      terminationDate: formData.terminationDate,
      activeStatus: formData.activeStatus,
      locationId: parseInt(locationId),
      personId: parseInt(personInfo.personId),
      pincode: formData.pincode,
      jobs,
    };

    if (isScheduleDeactivationSelected) {
      mutationData.statusDate = formData?.scheduledDeactivationDate;
    }

    updateLocationEmployee({
      variables: {
        ...mutationData,
      },
    })
      .then(res => {
        setSnackbar({
          open: true,
          type: 'success',
          text: 'Employee updated',
        });
        setSectionList(prevState =>
          prevState.map(location =>
            location.id === locationId ? { ...(location || {}), isNew: false } : location,
          ),
        );
      })
      .catch(err =>
        setSnackbar({
          open: true,
          type: 'error',
          text: err.message,
        }),
      );
  };

  const handleOnLocationSelect = useCallback(() => {
    const locations = hierarchyLocationIds
      .filter(nodes => nodes.locationId)
      .map(location => ({
        id: location.id,
        locationId: location.locationId,
        name: location.label,
        isNew: true,
      }));

    fetchEmployeeLocationJob({
      fetchPolicy: 'network-only',
      variables: {
        locationFilter: {
          locationIds: {
            in: locations.map(location => location.locationId),
          },
        },
        personFilter: {
          personId: {
            eq: personInfo?.personId || null,
          },
        },
      },
    });

    setSectionList(prevState => uniqBy([...prevState, ...locations], 'id'));
  }, [hierarchyLocationIds, fetchEmployeeLocationJob, personInfo?.personId, setSectionList]);

  // format date string for safari
  let hireDate = personInfo?.hireDate || moment();
  if (typeof hireDate === 'string') {
    hireDate = hireDate.replace(/-/g, '/');
  }

  let terminationDate = personInfo?.terminationDate || null;
  if (typeof terminationDate === 'string') {
    terminationDate = terminationDate.replace(/-/g, '/');
  }

  return !selectedLocation ? (
    <Grid container spacing={4} fullWidth style={{ margin: '1.5rem 1rem' }}>
      <Grid item xs={12}>
        {/* <div> */}
        {/* <HierarchySideBar
              locations
              setLocationIds={setHierarchyLocationIds}
              locationIds={hierarchyLocationIds}
              setIsLoading={setIsLoading}
            /> */}
        <SelectFactory
          label={'Locations'}
          fullWidth
          placeholder={'Select Locations'}
          query={LOCATIONS}
          defaultValue={hierarchyLocationIds}
          disableCloseOnSelect={true}
          multiple
          renderCheckBox
          filter={{
            active: { null: false },
          }}
          structureData={data => {
            if (!locationPermissions) {
              setLocationPermissions({
                locationId: data?.viewer?.locationConnection?.permissions?.locationId,
              });
            }

            return data?.viewer?.locationConnection?.edges.map((location, index) => {
              return {
                label: `${location.node.address?.state?.abbreviation}-${pad(
                  location.node.storeNumber,
                  4,
                )} ${location.node.label}`,
                id: location.node.id,
                locationId: location.node.locationId,
                index,
              };
            });
          }}
          onSelect={values => {
            setHierarchyLocationIds(values);
          }}
        />
        {/* </div> */}
      </Grid>
      <Grid container item xs={12} justify="flex-end" fullWidth spacing={4}>
        <Button onClick={handleOnLocationSelect} disabled={isLoading}>
          Add Locations
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column" style={{ margin: '1.5rem 1rem' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography gutterBottom variant="h4" component="h3">
          Employee status
        </Typography>
        <Grid container spacing={3}>
          {/* Per job or in general? */}
          <Permission access={permissions?.hireDate}>
            <Grid item xs={4}>
              <PermissionField
                Component={Elements.DatePicker}
                disabled={true} // TODO: remove this once we find out where it should go
                name="hireDate"
                label="Hire date"
                control={control}
                defaultValue={hireDate}
              />
            </Grid>
          </Permission>
          {/* Per job or in general? */}
          <Permission access={permissions?.terminationDate}>
            <Grid item xs={4}>
              <PermissionField
                Component={Elements.DatePicker}
                disabled={true} // TODO: remove this once we find out where it should go
                name="terminationDate"
                label="Termination date"
                control={control}
                defaultValue={terminationDate}
              />
            </Grid>
          </Permission>
        </Grid>
        <Divider variant="middle" />
        <Typography gutterBottom variant="h4" component="h3">
          Account status
        </Typography>
        <Grid container spacing={3} alignItems="center">
          <Permission access={permissions?.locationPersonConnection?.edges?.node?.inactive}>
            <Grid item xs={4}>
              <Controller
                name="activeStatus"
                control={control}
                defaultValue={
                  isUserActiveOnLocation
                    ? activeStatusOptions[0].value
                    : activeStatusOptions[1].value
                }
                render={({ onChange, ref }) => (
                  <Permission access={permissions?.locationPersonConnection?.edges?.node?.inactive}>
                    <div>
                      <PermissionField
                        Component={Elements.ToggleButton}
                        ref={ref}
                        createHelperText
                        options={activeStatusOptions}
                        value={
                          isUserActiveOnLocation
                            ? activeStatusOptions[0].value
                            : activeStatusOptions[1].value
                        }
                        handleToggleClick={isChecked => {
                          onAccountStatusChange(isChecked);
                          onChange(isChecked);
                        }}
                      />
                    </div>
                  </Permission>
                )}
              />
            </Grid>
          </Permission>
          <Permission
            access={
              permissions?.locationPersonConnection?.edges?.node
                ?.scheduleDeactivationEmployeeConnection?.edges?.node?.__typename
            }
          >
            <Grid item xs={4}>
              <div>
                <PermissionField
                  Component={FormControlLabel}
                  createHelperText
                  control={
                    <Checkbox
                      checked={isScheduleDeactivationSelected}
                      onChange={e => {
                        setIsScheduleDeactivationSelected(e.target.checked);
                      }}
                      name="scheduleDeactivation"
                    />
                  }
                  label="Schedule deactivation date"
                />
              </div>
            </Grid>
          </Permission>
          <Permission
            access={
              permissions?.locationPersonConnection?.edges?.node
                ?.scheduleDeactivationEmployeeConnection?.edges?.node?.deactivationDate
            }
          >
            <Grid item xs={4}>
              <PermissionField
                Component={Elements.DatePicker}
                name="scheduledDeactivationDate"
                label="From"
                defaultValue={
                  personInfo?.locationPersonConnection?.edges.find(
                    elem => elem?.node?.locationId === selectedLocation?.locationId,
                  )?.node?.scheduleDeactivationEmployeeConnection?.edges[0]?.node?.deactivationDate
                }
                control={control}
                disabled={!isScheduleDeactivationSelected}
                disablePast={true}
              />
            </Grid>
          </Permission>
        </Grid>
        <br />
        {isUserActiveOnLocation && (
          <>
            <Permission access={personPermissions?.pincode}>
              <Typography gutterBottom variant="h5">
                POS settings
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <PermissionField
                    Component={TextField}
                    id="pincode"
                    name="pincode"
                    error={!!errors.pincode?.message}
                    label="POS PIN code"
                    helperText={errors.pincode?.message}
                    type={selectedLocation?.isNew ? 'text' : 'password'}
                    inputRef={register({
                      required: 'Pincode is required',
                      minLength: {
                        value: 6,
                        message: 'Pincode must be exactly 6 characters',
                      },
                      maxLength: {
                        value: 6,
                        message: 'Pincode must be exactly 6 characters',
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: 'Only numbers please',
                      },
                    })}
                    defaultValue={personInfo?.pincode}
                  />
                </Grid>
              </Grid>
            </Permission>
            <Divider variant="middle" />
            {employeeLocationJob &&
              employeeLocationJob.map((jobInfo, index) => (
                <EmployeeJobs
                  control={control}
                  errors={errors}
                  hiddenTitle={index > 0}
                  register={register}
                  jobInfo={jobInfo}
                  setValue={setValue}
                  jobPermissions={jobPermissions}
                />
              ))}
            <Permission
              access={Math.min(
                mutationPermission?.access,
                accessToMutations?.[MUTATION_NAME.UpdateLocationEmployeeMutationInput],
              )}
            >
              <Button
                type="submit"
                disabled={updateLocationEmployeeLoading}
                color="primary"
                style={{ marginBottom: '1rem' }}
              >
                Submit
              </Button>
            </Permission>
          </>
        )}
      </form>
    </Grid>
  );
};

export default EmployeeLocations;
