import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import ImageUpload from '../../../../../../blocks/ImageUpload';
import GeneralInfoInput from '../GeneralInfoInput';
import { UPDATE_CATEGORY } from '../../../../../../../apollo/mutations';
import { FETCH_CATEGORY, FETCH_CATEGORY_TYPES } from '../../../../../../../apollo/queries';
import SubcategoryList from './SubcategoryList/index';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';

const IMAGE = {
  sizes: ['615x615', '375x268'],
};

const Category = ({ ids, basePath, classes, refetchMenuList }) => {
  const { setSnackbar } = useSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [selectedCategoryTypeId, setSelectedCategoryTypeId] = useState(null);

  useEffect(() => {
    setImages(
      IMAGE.sizes.map(size => {
        return {
          size,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (images.length !== 0) {
      const image = images.find(image => image.url);
      if (image) {
        setPreviewImage(image.url);
      }
    }
  }, [images]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, control, register, errors, reset, getValues } = useForm({
    defaultValues: {
      label: '',
      description: '',
      availableOnline: false,
      showCustomer: false,
      availableThirdParty: false,
      categorytypeId: null,
    },
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onCompleted: data => {
      setSnackbar({
        open: true,
        type: 'info',
        text: 'Category updated!',
      });

      setButtonDisabled(false);
    },
    onError: error => {
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Category not updated!',
      });

      setButtonDisabled(false);
    },
  });

  const {
    data: categoryTypes,
    loading: categoryTypesLoading,
    error: categoryTypesError,
  } = useQuery(FETCH_CATEGORY_TYPES);

  const { menuId, categoryId, gqlCategoryId } = ids;

  const { data, error, loading } = useQuery(FETCH_CATEGORY, {
    fetchPolicy: 'cache-first',
    variables: {
      id: gqlCategoryId,
      filter: {
        menuId: { eq: menuId },
        showUnavailableOnline: {
          eq: true,
        },
      },
      sort: {
        orderKey: 'ASC',
      },
    },
    onCompleted(data) {
      if (data.viewer.category) {
        setSelectedCategoryTypeId(data?.viewer.category.categorytype?.categorytypeId);

        data.viewer.category.categoryimageConnection.edges.forEach(({ node }) => {
          const index = images.findIndex(image => Object.values(image).includes(node.label));
          if (index !== -1) {
            setImages(images => {
              const image = {
                name: '',
                description: node.description || '',
                encoded: '',
                size: node.label,
                url: node.url || '',
              };
              images[index] = image;
              return [...images];
            });
          }
        });
        reset(data.viewer.category);
      }
    },
  });

  if (loading || categoryTypesLoading) return <CircularProgress className={classes.spinner} />;
  if (error || categoryTypesError) return <div>Error</div>;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const onSubmit = async data => {
    setButtonDisabled(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Updating category...',
    });

    const imagesToUpload = images.map(image => {
      return {
        name: image.name,
        description: image.description,
        encoded: image.encoded,
        size: image.size,
      };
    });

    await updateCategory({
      variables: {
        input: {
          id: gqlCategoryId,
          showCustomer: data?.showCustomer,
          label: data.label,
          categorytypeId: window.btoa(
            `Categorytype:${
              data.categorytypeId ? data.categorytypeId : selectedCategoryTypeId
            }:9999-12-31 23:59:59.000000`,
          ),
          description: data.description,
          images: imagesToUpload,
        },
      },
    });

    await refetchMenuList();
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button disabled={buttonDisabled} type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
        <GeneralInfoInput
          imageUrl={previewImage}
          setOpen={setOpen}
          control={control}
          register={register}
          errors={errors}
        />
        <Grid container spacing={3} direction="column">
          <Grid item xs={4}>
            <FormControl component="fieldset">
              <Typography gutterBottom variant="h6" component="h2">
                Display On
              </Typography>
              <FormControlLabel
                label="Is Available on OLO"
                control={
                  <Controller
                    name="showCustomer"
                    control={control}
                    render={props => (
                      <Switch
                        onChange={e => props.onChange(e.target.checked)}
                        checked={props.value}
                        className={classes.switch}
                      />
                    )}
                  />
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl component="fieldset">
              <Typography gutterBottom variant="h6" component="h2">
                Slug
              </Typography>
              <Controller
                name="categorytypeId"
                control={control}
                inputRef={register({ required: true })}
                render={props => (
                  <>
                    <Select
                      fullWidth
                      defaultValue={selectedCategoryTypeId}
                      onChange={event => props.onChange(event.target.value)}
                      value={props.value || data?.viewer?.category?.categorytype?.categorytypeId}
                      label="Slug"
                      error={!!errors?.categorytypeId?.message}
                      helperText={errors?.categorytypeId?.message}
                    >
                      {categoryTypes?.viewer?.categorytypeConnection?.edges?.map(categoryType => (
                        <MenuItem value={categoryType?.node?.categorytypeId}>
                          <em>{categoryType?.node?.label}</em>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors?.categorytypeId?.message && (
                      <FormHelperText>{errors?.categorytypeId?.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.subcategoryHeader}>
            <Typography gutterBottom variant="h6" component="h2">
              Subcategories
            </Typography>
            <Button
              className={classes.buttonAdd}
              component={Link}
              variant="outlined"
              size="small"
              to={`${basePath}/menus/${menuId}/categories/${categoryId}/subcategories`}
            >
              Add Subcategory
            </Button>
          </Grid>
          <Grid item xs={8}>
            <SubcategoryList
              data={data?.viewer?.category?.subcategoryConnection?.edges}
              categoryId={categoryId}
              menuId={menuId}
              basePath={basePath}
              classes={classes}
            />
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth="true"
        maxWidth="md"
        ref={descriptionElementRef}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Category Images</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                Web
              </Grid>
              <Grid item xs={6}>
                Mobile
              </Grid>
            </Grid>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[0]}
                maxSize={200 * 1000}
                helperText="Required image size is 615 x 615 px in JPG format. File weight in max. 200KB."
              />
            </Grid>
            <Grid item xs={6}>
              <ImageUpload
                images={images}
                setImages={setImages}
                size={IMAGE.sizes[1]}
                maxSize={50 * 1000}
                helperText="Required image size is 375 x 268 px in JPG format. File weight in max. 50KB."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

Category.propTypes = {
  ids: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Category;
