import { Box, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { convert, CurrencyInputFormat } from '../../../../../../utils';
import NumberInput from '../../../../../blocks/NumberInput';

const ProfitAndLossField = ({ item, fieldId, control, register, handleChange, setValue }) => {
  useEffect(() => {
    register(`${fieldId}.fieldId`);
    register(`${fieldId}.actual`);
    register(`${fieldId}.editable`);
    register(`${fieldId}.breakEven`);
    setValue(`${fieldId}.fieldId`, item.id);
    setValue(`${fieldId}.actual`, item.actual);
    setValue(`${fieldId}.editable`, item.editable);
    setValue(`${fieldId}.breakEven`, convert.centsToDollars(item.breakEven * 100));
  }, [item, fieldId]);

  const handleOnBreakEvenChange = useCallback(
    value => {
      handleChange(`${fieldId}.breakEven`, CurrencyInputFormat(value));
    },
    [fieldId],
  );

  const actual = useMemo(() => convert.formattedCurrency(item.actual * 100), [item.actual]);
  const breakEven = useMemo(() => convert.formattedCurrency(item.breakEven * 100), [
    item.breakEven,
  ]);
  const percentageOfRevenue = useMemo(
    () => `${convert.centsToDollars(item.percentageOfRevenue * 100)}%`,
    [item.percentageOfRevenue],
  );
  const variance = useMemo(() => convert.formattedCurrency(item.variance * 100), [item.variance]);

  return (
    <Box style={{ border: '1px solid #D3D3DB', padding: '12px', width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">{item.label}</Typography>
        </Grid>
        {item.editable ? (
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInput
              control={control}
              decimalScale={2}
              defaultValue={
                item.breakEven || item.breakEven === 0
                  ? convert.centsToDollars(item.breakEven * 100)
                  : '' || ''
              }
              allowNegative={true}
              name={`${fieldId}.breakEven`}
              id={`${fieldId}.breakEven`}
              label="Break-Even $"
              placeholder="0.00"
              onBlur={({ target: { value } }) => handleOnBreakEvenChange(value)}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0 }}>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Typography variant="body2" style={{ fontSize: '12px' }}>
                  Break-Even
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'Bold' }}>
                  {breakEven}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0 }}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '12px' }}>
                Actual
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'Bold' }}>
                {actual}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0 }}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '12px' }}>
                Percentage Of Revenue
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'Bold' }}>
                {percentageOfRevenue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} style={{ paddingTop: 0 }}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '12px' }}>
                Variance
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'Bold' }}>
                {variance}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfitAndLossField;
