import { Box, Grid, Typography, Divider } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { formatItems } from '../helpers';
import ProfitAndLossField from './ProfitAndLossField';

const ProfitAndLossStep = ({
  label,
  id,
  items = {},
  control,
  register,
  setValue,
  handleFieldChange,
  hasDivider = true,
}) => {
  return (
    <Box>
      {hasDivider ? <Divider style={{ marginBottom: '48px' }} /> : null}
      <Typography variant="h4" style={{ marginBottom: '32px' }}>
        {label}
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: '24px' }}>
        {Object.values(items).map(item => (
          <Grid item xs={12} key={`${id}.${item.fieldId}`}>
            <ProfitAndLossField
              item={item}
              fieldId={`${id}.${item.fieldId}`}
              control={control}
              register={register}
              setValue={setValue}
              handleChange={handleFieldChange}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfitAndLossStep;
