import { camelCase, compact, set } from 'lodash-es';
import { get } from 'react-hook-form';
import { initialTotal, PROFIT_LOSS_FIELD_LABEL, initialPaths } from './constants';

// 1. Variance = Actual - Break Even
// 2. Total Net Sales = sum of Break Even values for that step
// 3. Total Cost Of Goods = sum of Break Even values for that step
// 4. Total Labor = sum of Break Even values for that step
// 5. Gross Margin = Total Net Sales - Owner Profit (Value of Break Even field with Profit Loss ID 11) - Total Cost Of Goods - Total Labor
// 6. Total Fixed Expenses = sum of Break Even values for that step
// 7. Total General Expenses = sum of Break Even values for that step
// 8. Gross Profit = Gross Margin - Total Fixed Expenses - Total General Expenses
// 9. Excess Cash Flow = Gross Profit - (sum of Break Even values for that step)

function calculateItem(item) {
  return {
    actual: item.actual || 0,
    breakEven: item.breakEven || 0,
    percentageOfRevenue: item.percentageOfRevenue || 0,
    variance: (item.actual || 0) - (item.breakEven || 0),
  };
}

function calculateGrossMargin({
  totalNetSales,
  ownerProfit,
  totalCostOfGoods,
  totalLabor,
  initialData,
}) {
  const result = {
    actual: initialData.actual || 0,
    breakEven:
      totalNetSales.breakEven -
      ownerProfit.breakEven -
      totalCostOfGoods.breakEven -
      totalLabor.breakEven,
    percentageOfRevenue: initialData.percentageOfRevenue || 0,
  };
  result.variance = result.actual - result.breakEven;

  return result;
}

function calculateGrossProfit({
  grossMargin,
  totalFixedExpenses,
  totalGeneralExpenses,
  initialData,
}) {
  const result = {
    actual: initialData.actual || 0,
    breakEven:
      grossMargin.breakEven - totalFixedExpenses.breakEven - totalGeneralExpenses.breakEven,
    percentageOfRevenue: initialData.percentageOfRevenue || 0,
  };

  result.variance = result.actual - result.breakEven;

  return result;
}

function calculateExcessCashFlow({ grossProfit, data, excessCahsFlowPath, initialData }) {
  const excessCashFlowTotal = initialTotal;

  Object.entries(data[excessCahsFlowPath.split('.')[0]]).forEach(([key, item]) => {
    if (key !== excessCahsFlowPath.split('.')[1]) {
      excessCashFlowTotal.breakEven += item.breakEven || 0;
    }
  });

  const result = {
    actual: initialData.actual || 0,
    breakEven: grossProfit.breakEven - excessCashFlowTotal.breakEven,
    percentageOfRevenue: initialData.percentageOfRevenue || 0,
  };

  result.variance = result.actual - result.breakEven;

  return result;
}

export function formatItems({ profitLossFields = [], key }) {
  const items = [];

  const paths = {
    ...initialPaths,
  };

  profitLossFields.forEach(item => {
    const itemFieldId = `field-${item.id}`;

    if (
      [
        PROFIT_LOSS_FIELD_LABEL.TOTAL_COST_OF_GOODS,
        PROFIT_LOSS_FIELD_LABEL.TOTAL_FIXED_EXPENSES,
        PROFIT_LOSS_FIELD_LABEL.TOTAL_GENERAL_EXPENSES,
        PROFIT_LOSS_FIELD_LABEL.TOTAL_LABOR,
        PROFIT_LOSS_FIELD_LABEL.TOTAL_NET_SALES,
        PROFIT_LOSS_FIELD_LABEL.EXCESS_CASH_FLOW,
        PROFIT_LOSS_FIELD_LABEL.OWNER_PROFIT,
      ].includes(item.label)
    ) {
      paths[camelCase(item.label)] = `${key}.${itemFieldId}`;
    } else if (item.label.startsWith(PROFIT_LOSS_FIELD_LABEL.GROSS_MARGIN)) {
      paths.grossMargin = `${key}.${itemFieldId}`;
    } else if (item.label.startsWith(PROFIT_LOSS_FIELD_LABEL.GROSS_PROFIT)) {
      paths.grossProfit = `${key}.${itemFieldId}`;
    }

    items.push({
      ...item,
      ...calculateItem(item),
      fieldId: itemFieldId,
    });
  });

  return { items, paths };
}

export function formatProfitLoss(profitLossSteps) {
  const result = {};
  const resultPaths = {
    ...initialPaths,
  };

  profitLossSteps.forEach(step => {
    const key = `fifteenPlanProfitAndLossStep-${step.id}`;

    const { items, paths } = formatItems({
      profitLossFields: step.profitLossFields,
      key,
    });

    result[key] = { label: step.label };

    items.forEach(item => {
      result[key][item.fieldId] = item;
    });

    Object.entries(paths).map(([key, value]) => {
      if (value) {
        resultPaths[key] = value;
      }
    });
  });

  return { formatedProfitLoss: result, paths: resultPaths };
}

export function calculateSteps(prevSteps, paths) {
  const result = { ...prevSteps };

  Object.entries(prevSteps).map(([key, value]) => {
    Object.values(value).map(item => {
      if (item.fieldId) {
        set(prevSteps, `${key}.${item.fieldId}`, {
          ...item,
          ...calculateItem(item),
        });
      }
    });
  });

  compact([
    paths.totalCostOfGoods,
    paths.totalFixedExpenses,
    paths.totalGeneralExpenses,
    paths.totalLabor,
    paths.totalNetSales,
  ]).forEach(path => {
    const [stageKey, fieldKey] = path.split('.');
    const total = { ...initialTotal };
    const stepValues = get(result, path);

    Object.values(result[stageKey])
      .filter(step => step.fieldId && step.fieldId !== fieldKey)
      .forEach(step => {
        total.breakEven += step.breakEven;
      });

    total.variance = (stepValues.actual || 0) - total.breakEven;

    set(result, path, { ...stepValues, ...total });
  });

  const totalNetSales = get(result, paths.totalNetSales, initialTotal);
  const ownerProfit = get(result, paths.ownerProfit, initialTotal);
  const totalCostOfGoods = get(result, paths.totalCostOfGoods, initialTotal);
  const totalLabor = get(result, paths.totalLabor, initialTotal);
  const totalFixedExpenses = get(result, paths.totalFixedExpenses, initialTotal);
  const totalGeneralExpenses = get(result, paths.totalGeneralExpenses, initialTotal);

  const grossMargin = calculateGrossMargin({
    totalNetSales,
    ownerProfit,
    totalCostOfGoods,
    totalLabor,
    initialData: get(result, paths.grossMargin, initialTotal),
  });

  const grossProfit = calculateGrossProfit({
    grossMargin,
    totalFixedExpenses,
    totalGeneralExpenses,
    initialData: get(result, paths.grossProfit, initialTotal),
  });

  const excessCashFlow = calculateExcessCashFlow({
    grossProfit,
    data: prevSteps,
    excessCahsFlowPath: paths.excessCashFlow,
    initialData: get(result, paths.excessCashFlow, initialTotal),
  });

  set(result, paths.grossMargin, { ...get(result, paths.grossMargin), ...grossMargin });
  set(result, paths.grossProfit, { ...get(result, paths.grossProfit), ...grossProfit });
  set(result, paths.excessCashFlow, { ...get(result, paths.excessCashFlow), ...excessCashFlow });

  return result;
}
