import { gql } from '@apollo/client';

const CREATE_RESTAURANT = gql`
  mutation createRestaurant($input: CreateRestaurantMutationInput!) {
    createRestaurant(input: $input) {
      viewer {
        id
      }
      restaurant {
        id
        restaurantId
      }
    }
  }
`;

export default CREATE_RESTAURANT;
