import { useMutation } from '@apollo/client';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UPDATE_DEVICE_PRINTERSTATION } from '../../../../../../../apollo/mutations/locations/POSSettings/updatePrinterstationView';
import { FETCH_LOCATION_POS_SETTINGS } from '../../../../../../../apollo/queries';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { uglifyId } from '../../../../../../../utils';
import { Permission, PermissionField } from '../../../../../../blocks';

function PrinterstationSettings({ printerstationId, currentSetting, permissions }) {
  const { setSnackbar } = useSnackbar();
  const { locationId } = useParams();
  const uglifiedId = uglifyId('Location', locationId);

  const [printerstationSetting, setPrinterstationSetting] = useState(currentSetting);
  const [disabledRadio, setDisabledRadio] = useState(false);
  const [updateDevicePrinterstation] = useMutation(UPDATE_DEVICE_PRINTERSTATION, {
    onCompleted: data => {
      setPrinterstationSetting(
        data.updateDevicePrinterstation.devicePrinterstation.printerstation_id,
      );
      setSnackbar({
        open: true,
        type: 'success',
        text: 'Device printerstation updated',
      });
      setDisabledRadio(false);
    },
    onError: error => {
      console.log(error);
      setSnackbar({
        open: true,
        type: 'error',
        text: 'Oops, something went wrong...',
      });
      setDisabledRadio(false);
    },
    refetchQueries: [{ query: FETCH_LOCATION_POS_SETTINGS, variables: { locationId: uglifiedId } }],
  });

  const handleChange = e => {
    setDisabledRadio(true);
    setSnackbar({
      open: true,
      type: 'info',
      text: 'Updating device printerstation',
    });
    updateDevicePrinterstation({
      variables: {
        input: {
          id: printerstationId,
          printerstationId: +e.target.value,
        },
      },
    });
  };

  return (
    <div>
      <Permission
        access={
          permissions?.deviceConnection?.edges?.node?.locationPrinterstation?.edges?.node
            ?.__typename
        }
      >
        <FormControl component="div" style={{ display: 'flex', flexDirection: 'row', gap: '3rem' }}>
          <FormLabel component="p">Printer Type</FormLabel>
          <div>
            <PermissionField
              Component={RadioGroup}
              createHelperText
              value={printerstationSetting}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                disabled={disabledRadio}
                value={0}
                control={<Radio />}
                label="None"
              />
              <FormControlLabel
                disabled={disabledRadio}
                value={1}
                control={<Radio />}
                label="Main"
              />
              <FormControlLabel
                disabled={disabledRadio}
                value={2}
                control={<Radio />}
                label="Kitchen"
              />
              <FormControlLabel
                disabled={disabledRadio}
                value={3}
                control={<Radio />}
                label="Bar"
              />
            </PermissionField>
          </div>
        </FormControl>
      </Permission>
    </div>
  );
}

export default PrinterstationSettings;
