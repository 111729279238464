import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import { reduce } from 'lodash-es';
import logo from '../../assets/images/logo2.png';

function getCheckbox(checked) {
  return {
    table: {
      widths: [12],
      heights: [12],
      body: [
        [
          {
            border: [true, true, true, true],
            fillColor: checked ? '#000000' : '#ffffff',
            text: '',
          },
        ],
      ],
    },
  };
}

function createTableCheckInput({ otcChecklistFieldTypeId, checkedYes, checkedNo }) {
  return otcChecklistFieldTypeId === 1
    ? [
        getCheckbox(checkedYes),
        {
          text: 'YES',
          style: 'option',
        },
        getCheckbox(checkedNo),
        {
          text: 'NO',
          style: 'option',
        },
      ]
    : ['', '', '', ''];
}

function createTableBody({ note, otcChecklistField = [] }) {
  return {
    layout: { defaultBorder: false },
    style: 'contentTable',
    table: {
      widths: ['*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        ...reduce(
          otcChecklistField,
          (result, otcChecklistField) => {
            result.push([
              {
                style: otcChecklistField.otcChecklistFieldTypeId === 1 ? 'task' : 'note',
                stack: [
                  otcChecklistField.label,
                  otcChecklistField.otcChecklistFieldTypeId === 4 && otcChecklistField.value
                    ? {
                        style: 'input',
                        text: ` ${otcChecklistField.value}`,
                      }
                    : '',
                ],
              },
              ...createTableCheckInput({
                otcChecklistFieldTypeId: otcChecklistField.otcChecklistFieldTypeId,
                checkedYes: ['1', 1, true].includes(otcChecklistField.value),
                checkedNo: ['0', 0, false].includes(otcChecklistField.value),
              }),
            ]);

            return result;
          },
          [],
        ),
        [
          {
            style: 'note',
            stack: [
              'Notes: ',
              note
                ? {
                    style: 'input',
                    text: note,
                  }
                : '',
            ],
          },
          {},
          {},
          {},
          {},
        ],
      ],
    },
  };
}

function createContent({ date, otcChecklistCategory = [], otcChecklistTask = [] }) {
  return [
    {
      layout: 'customLayout',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'General Info',
              style: 'paragraphTitle',
            },
          ],
          [
            {
              text: [
                { text: 'Date:  ', style: 'task' },
                { style: 'checkbox', text: date },
              ],
            },
          ],
        ],
      },
    },
    ...otcChecklistCategory.map(category => ({
      layout: 'customLayout',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: category.label,
              style: 'paragraphTitle',
            },
          ],
          [
            createTableBody({
              note: category.note,
              otcChecklistField: category.otcChecklistField,
            }),
          ],
        ],
      },
    })),
    {
      layout: 'customLayout',
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'Tasks',
              style: 'paragraphTitle',
            },
          ],
          ...otcChecklistTask?.map(task => [
            {
              text: task.label,
              style: 'task',
            },
          ]),
        ],
      },
    },
  ];
}

function getDocDefition({ title, date, otcChecklistTask, otcChecklistCategory }) {
  const content = createContent({ date, otcChecklistTask, otcChecklistCategory });

  return {
    header: {
      margin: 12,
      layout: 'noBorders',
      style: 'headerTable',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            { image: 'logo', width: 100, height: 55 },
            {
              stack: [
                { style: 'header', text: title },
                { style: 'header', text: 'OTC CHECKLIST' },
              ],
            },
          ],
        ],
      },
    },
    footer: function(currentPage, pageCount) {
      return {
        style: 'footer',
        columns: [
          {
            alignment: 'center',
            text: `${currentPage.toString()} of ${pageCount}`,
          },
        ],
        margin: [12, 0, 12, 12],
      };
    },

    content: content,

    styles: {
      paragraphTitle: {
        bold: true,
        fontSize: 14,
      },
      headerTable: {
        marginBottom: 20,
      },
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      contentTable: {
        marginBottom: 10,
      },
      footer: {
        fontSize: 10,
        color: '#666666',
      },
      task: {
        fontSize: 10,
        color: '#666666',
      },
      note: {
        fontSize: 10,
        color: '#666666',
        marginBottom: 14,
      },
      input: {
        color: '#3B3A43',
      },
      option: {
        fontSize: 12,
        bold: true,
      },
    },
    defaultStyle: {
      color: '#3B3A43',
    },
    pageMargins: [12, 90, 12, 24],
    images: {
      logo,
    },
  };
}

const generateOtcChecklistPdf = ({
  title = '',
  otcChecklistTask = [],
  otcChecklistCategory = [],
  date,
  open = true,
}) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const docDefinition = getDocDefition({
    title,
    date,
    otcChecklistTask,
    otcChecklistCategory,
  });

  pdfMake.tableLayouts = {
    customLayout: {
      hLineWidth: function(i) {
        return i === 1 ? 1 : 0;
      },
      vLineWidth: function() {
        return 0;
      },
      hLineColor: function() {
        return '#ffcb02';
      },
      paddingBottom: function() {
        return 6;
      },
      paddingTop: function(i) {
        return i === 0 ? 0 : 15;
      },
    },
  };

  if (open) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    return pdfMake.createPdf(docDefinition);
  }
};

export default generateOtcChecklistPdf;
