export const PROFIT_LOSS_FIELD_LABEL = {
  TOTAL_COST_OF_GOODS: 'Total Cost of Goods',
  TOTAL_FIXED_EXPENSES: 'Total Fixed Expenses',
  TOTAL_GENERAL_EXPENSES: 'Total General Expenses',
  TOTAL_LABOR: 'Total Labor',
  TOTAL_NET_SALES: 'Total Net Sales',
  EXCESS_CASH_FLOW: 'Excess Cash Flow',
  OWNER_PROFIT: 'Owner Profit',
  GROSS_MARGIN: 'Gross Margin',
  GROSS_PROFIT: 'Gross Profit',
};

export const initialPaths = {
  totalNetSales: '',
  totalCostOfGoods: '',
  totalLabor: '',
  totalFixedExpenses: '',
  totalGeneralExpenses: '',
  grossMargin: '',
  grossProfit: '',
  excessCashFlow: '',
  ownerProfit: '',
};

export const initialTotal = { breakEven: 0, variance: 0 };
