import { compact } from 'lodash-es';
import { convert } from '../../../../../utils';
import { additionalQuestions } from '../constants';

export function formatFifteenPlanForPdf({ locationLabel, fifteenPlan, open }) {
  return {
    title: locationLabel,
    open: open,
    fifteenPlanQuestionGroup: fifteenPlan.fifteenPlanQuestionGroup?.map(group => ({
      ...group,
      fifteenPlanQuestions: group.fifteenPlanQuestions?.map(question => ({
        ...question,
        answers: compact(
          question.singleAnswer
            ? [
                {
                  value:
                    question.singleAnswer.value || question.singleAnswer.value === 0
                      ? `${convert.formattedCurrency(question.singleAnswer.value * 100)}`
                      : '',
                },
              ]
            : question.multiAnswer
            ? question.multiAnswer.map(answer => ({
                value: compact([
                  answer.value || answer.value === 0
                    ? `Amount: ${convert.formattedCurrency(answer.value * 100)}`
                    : null,
                  answer.date ? `Date: ${answer.date}` : null,
                  answer.vendor ? `Vendor: ${answer.vendor}` : null,
                  answer.invoice ? `Invoice #: ${answer.invoice}` : null,
                ]).join('  -  '),
              }))
            : null,
        ),
      })),
    })),
    additionalQuestions: additionalQuestions.map(question => ({
      ...question,
      value: fifteenPlan[question.name],
    })),
    date: fifteenPlan.date,
  };
}
