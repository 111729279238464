import { Divider, FormHelperText, InputLabel } from '@material-ui/core';
import { Grid, Typography, FormControl } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { COLOR } from '../../../../../constants';
import { convert, toFixed10 } from '../../../../../utils';
import FormItems from '../../../../blocks/FormItems';
import NumberInput from '../../../../blocks/NumberInput';
import FifteenPlanQuestionField from './FifteenPlanQuestionField';

const FifteenPlanQuestionGroup = ({
  label,
  fifteenPlanQuestions,
  register,
  setValue,
  getValues,
  unregister,
  control,
  errors,
  id,
  additionalQuestions,
}) => {
  useEffect(() => {
    fifteenPlanQuestions?.forEach(question => {
      const itemId = `${id}-fifteenPlanQuestionItem-group-${question.id}`;

      register(`${itemId}.questionId`);
      setValue(`${itemId}.questionId`, question.id);

      register(`${itemId}.fifteenPlanQuestionTypeId`);
      setValue(`${itemId}.fifteenPlanQuestionTypeId`, question.singleAnswer ? 1 : 2);
    });
  }, [fifteenPlanQuestions, id]);

  const handleOnCheckboxClick = useCallback((value, prevValue, name) => {
    setValue(name, value !== prevValue ? value : '');
  }, []);

  const questions = useMemo(
    () =>
      fifteenPlanQuestions?.map(question => {
        const itemIdSingleAnswer = `${id}-fifteenPlanQuestionItem-group-${question.id}`;
        const itemIdMultiAnswer = `${id}-fifteenPlanQuestionItem-item-${question.id}`;

        return (
          <Grid item xs={12} key={itemIdSingleAnswer}>
            {question.singleAnswer ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">{question.label}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <NumberInput
                    control={control}
                    decimalScale={2}
                    defaultValue={
                      question.singleAnswer.value || question.singleAnswer.value === 0
                        ? convert.centsToDollars(question.singleAnswer.value * 100)
                        : ''
                    }
                    name={`${itemIdSingleAnswer}.value`}
                    id={`${itemIdSingleAnswer}.value`}
                    label="Amount $"
                    placeholder="0.00"
                  />
                </Grid>
              </Grid>
            ) : null}

            {question.multiAnswer ? (
              <Grid item xs={12}>
                <FormItems
                  titleVariant="body1"
                  title={question.label}
                  addItemButtonText="Add Row"
                  addItemOnBottom={true}
                  dividerBetweenItems={true}
                  dividerAfterItems={false}
                  name={`${itemIdMultiAnswer}`}
                  items={question.multiAnswer || []}
                  initialItem={{
                    value: '',
                    date: '',
                    vendor: '',
                    invoice: '',
                  }}
                  setValue={setValue}
                  getValues={getValues}
                  unregister={unregister}
                  control={control}
                  register={register}
                  errors={errors}
                  itemComponent={FifteenPlanQuestionField}
                  enableRemove={true}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      }) || [],
    [fifteenPlanQuestions, errors],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" fontSize="0.75rem">
            {label}
          </Typography>
        </Grid>
        {questions}
        {!isEmpty(additionalQuestions)
          ? additionalQuestions.map(({ label, name, defaultValue }) => (
              <Grid item xs={12} key={`additionalquestion-${name}`}>
                <Grid
                  container
                  spacing={3}
                  key={`checkinput-${name}`}
                  style={{ marginBottom: '14px' }}
                >
                  <Grid item xs={12} direction="column">
                    <InputLabel error={errors?.[name]}>{label} *</InputLabel>
                    {errors?.[name] ? (
                      <FormHelperText style={{ paddingLeft: 0 }} error={true}>
                        This answer is required
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} justify="center">
                    <FormControl variant="outlined">
                      <Controller
                        rules={{ required: true }}
                        name={name}
                        id={name}
                        defaultValue={defaultValue}
                        render={({ value }) => (
                          <ToggleButtonGroup value={value} id={name} name={name} exclusive>
                            <ToggleButton
                              color="primary"
                              style={
                                value === 1
                                  ? {
                                      color: COLOR.SUCCESS,
                                      border: `1px solid ${COLOR.SUCCESS}`,
                                      background: '#38c68a33',
                                      fontWeight: 'bold',
                                    }
                                  : undefined
                              }
                              onClick={() => handleOnCheckboxClick(1, value, name)}
                              value={1}
                            >
                              Yes
                            </ToggleButton>
                            <ToggleButton
                              color="error"
                              style={
                                value === 0
                                  ? {
                                      color: COLOR.ERROR,
                                      border: `1px solid ${COLOR.ERROR}`,
                                      fontWeight: 'bold',
                                      background: '#d40e3733',
                                    }
                                  : undefined
                              }
                              value={'0'}
                              onClick={() => handleOnCheckboxClick(0, value, name)}
                            >
                              No
                            </ToggleButton>
                          </ToggleButtonGroup>
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ))
          : null}
      </Grid>
      <Divider style={{ marginBottom: '32px' }} />
    </>
  );
};

export default FifteenPlanQuestionGroup;
