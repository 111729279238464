import { gql } from '@apollo/client';

const SEND_EMAIL_ATTACHMENT = gql`
  mutation SEND_EMAIL_ATTACHMENT($input: SendEmailAttachmentInput!) {
    sendEmailAttachment(input: $input) {
      clientMutationId
    }
  }
`;

export default SEND_EMAIL_ATTACHMENT;
