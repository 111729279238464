import { gql } from '@apollo/client';

const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant($input: UpdateRestaurantMutationInput!) {
    updateRestaurant(input: $input) {
      viewer {
        id
      }
      restaurant {
        permission
        id
        restaurantId
        label
        description
        primaryLocation {
          id
          label
          storeNumber
          address {
            id
            state {
              id
              abbreviation
            }
          }
          brand {
            id
            label
          }
        }
        locationConnection {
          edges {
            node {
              id
              label
              storeNumber
              address {
                id
                state {
                  id
                  abbreviation
                  label
                }
              }
              brand {
                id
                brandId
                label
              }
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_RESTAURANT;
